import { Component, OnDestroy, OnInit, QueryList, TemplateRef, ViewChild, ViewChildren } from "@angular/core"
import { Observable, of, Subject } from "rxjs"
import { catchError, distinctUntilChanged, filter, switchMap, takeUntil } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { CanComponentDeactivate } from "../../../services/can-deactivate-guard.service"
import { ImageService, InscObjectService, QueryResult } from "../../../services/data.service"
import { ImageDownloadService } from "../../../services/image-download.service"
import { LinkingRelation, LinkingService } from "../../../services/linking.service"
import { LocationDisplayHelperService } from "../../../services/location-display-helper.service"
import { ImageViewerService } from "../../../shared/components/image-viewer/image-viewer.service"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"

import { InscImage, InscObject, Inscription } from "../../../shared/models"
import { ImageParent } from "../../../shared/models/image.model"
import { ImageDropEvent, ImageManagementGroupComponent } from "../../images/image-management/image-management-group.component"
import { ImageManagementDirective, ImageSelection } from "../../images/image-management/image-management.directive"
import { ImageSidebarService } from "../../images/image-sidebar/image-sidebar.service"
import { ImageUploadDialogService } from "../../images/image-uploader/image-upload-dialog.service"
import { RecordPageStateService } from "../record-page-state.service"
import { LinkChooserDialogService } from "../shared/dialogs/link-chooser-dialog/link-chooser-dialog.component"

@Component({
    selector: 'insc-object-detail',
    templateUrl: './object-detail.component.html',
    styleUrls: ['../record-detail-styles.scss', './object-detail.component.scss'],
    standalone: false
})
export class ObjectDetailComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  hasEditPermission = this.auth.permission('update_records')

  private unsubscribe$ = new Subject<void>()

  imageSelectionStatus: "none" | "some" | "all" = "none"

  @ViewChildren(ImageManagementGroupComponent) imageGroups: QueryList<ImageManagementGroupComponent>
  @ViewChild(ImageManagementDirective) imageMgmt: ImageManagementDirective

  @ViewChild("imageChooserResultSubtitleTpl") imageChooserResultSubtitleTemplate: TemplateRef<{ $implicit: QueryResult<InscImage>, query?: string }>

  get object(): InscObject { return this.recordPageState.record }

  constructor(
    private recordPageState: RecordPageStateService<InscObject>,
    private auth: AuthService,
    private inscObjectService: InscObjectService,
    private imageViewer: ImageViewerService,
    public locationDisplayHelper: LocationDisplayHelperService,
    private imageSidebarService: ImageSidebarService,
    private reusableDialogs: ReusableDialogsService,
    private newImageUploadDialogService: ImageUploadDialogService,
    private imageDownloadService: ImageDownloadService,
    private linkChooserDialogService: LinkChooserDialogService,
    private linkingService: LinkingService
  ) { }

  canDeactivate(): Observable<boolean> | true {
    return this.imageSidebarService.hasUnsavedChanges
      ? this.reusableDialogs.openUnsavedChangesConfirmationDialog().afterClosed()
      : true
  }

  onImageDrop(dropEvent: ImageDropEvent): void {
    const sourceGroupDescriptor: {type: string; id?: string} = dropEvent.sourceGroupDescriptor
    const targetGroupDescriptor: {type: string; id?: string} = dropEvent.target.imageGroupDescriptor
    const targetIndex = dropEvent.index + 1

    const request: Observable<unknown> = (() => {
      switch (sourceGroupDescriptor.type) {
        case "images":
          switch (targetGroupDescriptor.type) {
            case "images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "object", this.object.id, "images", "object", this.object.id, "images", {position: targetIndex})
            case "context_images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "object", this.object.id, "images", "object", this.object.id, "context_images", {position: targetIndex})
            case "inscription_images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "object", this.object.id, "images", "inscription", targetGroupDescriptor.id, "images", {position: targetIndex})
          }
          break

        case "context_images":
          switch (targetGroupDescriptor.type) {
            case "images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "object", this.object.id, "context_images", "object", this.object.id, "images", {position: targetIndex})
            case "context_images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "object", this.object.id, "context_images", "object", this.object.id, "context_images", {position: targetIndex})

            case "inscription_images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "object", this.object.id, "context_images", "inscription", targetGroupDescriptor.id, "images", {position: targetIndex})

          }
          break

        case "inscription_images":
          switch (targetGroupDescriptor.type) {
            case "images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "inscription", sourceGroupDescriptor.id, "images", "object", this.object.id, "images", {position: targetIndex})
            case "context_images":
              return this.linkingService
                .moveFrom("image", dropEvent.image.id, "inscription", sourceGroupDescriptor.id, "images", "object", this.object.id, "context_images", {position: targetIndex})
            case "inscription_images":
                return this.linkingService
                  .moveFrom("image", dropEvent.image.id, "inscription", sourceGroupDescriptor.id, "images", "inscription", targetGroupDescriptor.id, "images", {position: targetIndex})
          }
      }
    })()

    request.pipe(
      catchError(() => {
        return this.inscObjectService.get(this.object.id)
      }),
      switchMap(() => this.inscObjectService.get(this.object.id))
    ).subscribe(object => this.recordPageState.record = object)
  }

  ngOnInit(): void {
    this.imageSidebarService.afterSave$.pipe(
      switchMap(_ => this.inscObjectService.get(this.object.id)),
      takeUntil(this.unsubscribe$)
    ).subscribe(object => {
      this.recordPageState.record = object
    })

    this.recordPageState.recordChanges.pipe(
      distinctUntilChanged((a, b) => {
        if (a && b && a.id === b.id) {
          return true
        }
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(_ => this.imageMgmt?.clear())
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  openImageUploader(record_type: ImageParent["type"], id: string, recordName: string, {context = false}: {context?: boolean} = {}): void {
    this.newImageUploadDialogService.open({
      parent: { type: record_type, id: id, isContext: context},
      linkedRecordName: recordName
    }).afterClosed().pipe(
      filter(Boolean),
      switchMap(() => this.inscObjectService.get(this.object.id))
    ).subscribe(object => this.recordPageState.record = object)
  }

  linkObjectImages(targetRelation: LinkingRelation<"image", "object">): void {
    const currentImages = targetRelation === "images" ? this.object.images : this.object.context_images
    const currentImageIds = currentImages
      .filter((image, index, images) => index === images.indexOf(image))
      .map(image => image.id)

    this.linkChooserDialogService.open({
      dataService: ImageService,
      select: currentImageIds,
      config: {
        title: `${targetRelation === "images" ? "Aufnahmen" : "Kontextaufnahmen"} mit Inschriftenträger verknüpfen`,
        queryParams: { sort_by: "updated_at" },
        subtitleTemplate: this.imageChooserResultSubtitleTemplate,
        quickFilter: "name_filename_quick_filter"
      }
    }).afterClosed().pipe(
      filter(result => result != null),
      switchMap(result => result.selection.length > 0
                          ? this.linkingService.move("image", result.selection, "object", this.object.id, targetRelation)
                          : this.linkingService.unlink("image", currentImageIds, "object", this.object.id, targetRelation)),
      switchMap(() => this.inscObjectService.get(this.object.id))
    ).subscribe(object => this.recordPageState.record = object)
  }

  unlinkObjectImages(targetRelation: LinkingRelation<"image", "object">, imageIds: string[], group: ImageManagementGroupComponent): void {
    this.linkingService.unlink("image", imageIds, "object", this.object.id, targetRelation)
      .pipe(switchMap(() => this.inscObjectService.get(this.object.id)))
      .subscribe(object => {
        imageIds.forEach(id => group.deselect(id))
        return this.recordPageState.record = object
      })
  }

  linkInscriptionImages(inscription: Partial<Inscription>): void {
    const currentImageIds = inscription.images
      .filter((image, index, images) => index === images.indexOf(image))
      .map(image => image.id)

    this.linkChooserDialogService.open({
      dataService: ImageService,
      select: currentImageIds,
      config: {
        title: `Aufnahmen mit Inschrift "${inscription.name}" verknüpfen`,
        queryParams: { sort_by: "updated_at" },
        subtitleTemplate: this.imageChooserResultSubtitleTemplate,
        quickFilter: "name_filename_quick_filter"
      }
    }).afterClosed().pipe(
      filter(result => result != null),
      switchMap(result =>  result.selection.length > 0
                           ? this.linkingService.move("image", result.selection, "inscription", inscription.id, "images")
                           : this.linkingService.unlink("image", currentImageIds, "inscription", inscription.id, "images")),
      switchMap(() => this.inscObjectService.get(this.object.id))
    ).subscribe(object => this.recordPageState.record = object)
  }

  unlinkInscriptionImages(inscriptionId: string, imageIds: string[], group: ImageManagementGroupComponent): void {
    this.linkingService.unlink("image", imageIds, "inscription", inscriptionId, "images")
      .pipe(switchMap(() => this.inscObjectService.get(this.object.id)))
      .subscribe(object => {
        imageIds.forEach(id => group.deselect(id))
        return this.recordPageState.record = object
      })
  }

  onImageSelectionChange(selection: ImageSelection[]): void {
    this.updateSelectedImagesInSidebar(selection)

    if (selection.length === 0) {
      this.imageSelectionStatus = "none"
    } else if (this.allImages().length === selection.length) {
      this.imageSelectionStatus = "all"
    } else {
      this.imageSelectionStatus = "some"
    }
  }

  selectedImages(selection: ImageSelection[]): InscImage[] {
    return this.allImages().filter(image => selection.includes(image.id))
  }

  updateSelectedImagesInSidebar(selection: ImageSelection[]): void {
    const selectedImages = this.selectedImages(selection)
    this.imageSidebarService.setImages(selectedImages, {enableCloseButton: false, enableDelete: false}, {type: "insc_object", id: this.object.id})
  }

  selectAllImages(): void {
    this.imageGroups.forEach(group => group.selectAll())

  }

  deselectAllImages(): void {
    this.imageGroups.forEach(group => group.deselectAll())
  }


  canChangeImageSelection: () => (Observable<boolean>) = () => {
    if (this.imageSidebarService.hasUnsavedChanges) {
      return this.reusableDialogs.openUnsavedChangesConfirmationDialog().afterClosed()
    }

    return of(true)
  }

  getImageDownloadLink(): string {
    const allImages = this.allImages()
    const ids = allImages.map(image => image.id)
    return this.imageDownloadService.getDownloadLink(ids)
  }

  openGallery(): void {
    this.imageViewer.open(this.object.name, this.allImages())
  }

  allImages(): InscImage[] {
    const inscriptionImages = this.object.inscriptions.map(insc => insc.images)
    return (<InscImage[]>[]).concat(this.object.images, this.object.context_images, ...inscriptionImages)
  }

  // track inscription by ID so imageManagementGroup can update selections
  // in its onChange hook
  inscriptionById: (index: number, inscription: Inscription) => string = (index: number, inscription: Inscription) => inscription.id
}
