import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { MatInput } from "@angular/material/input"

import { EMPTY, of } from "rxjs"
import { switchMap } from "rxjs/operators"
import { VocabularyService } from "../../../../services/vocabulary.service"
import { ConfirmationDialogComponent } from "../../../dialogs/confirmation-dialog.component"

import { Vocabulary, VocabularyEntry } from "../../../models"
import { SnackbarService } from "../../../snackbars/snackbar.service"

@Component({
    selector: 'insc-vocabulary-editor',
    templateUrl: './vocabulary-editor.component.html',
    styleUrls: ['./vocabulary-editor.component.scss'],
    standalone: false
})
export class VocabularyEditorComponent implements OnChanges {
  @Input() vocabulary: Vocabulary
  @Input() editable = true
  @Input() selectedEntryId: VocabularyEntry["id"] | null = null
  @Output() entrySelected = new EventEmitter<VocabularyEntry["id"]>()

  @ViewChild("newNameInput", {read: MatInput}) newNameInput: MatInput
  @ViewChild("commentInput", {read: MatInput}) commentInput: MatInput
  @ViewChild("newEntryInput", {read: MatInput}) newEntryInput: MatInput

  private _currentlyEditing: VocabularyEntry | null = null

  public currentlyEditing(entry?: VocabularyEntry): boolean {
    return entry === undefined
           ? this._currentlyEditing != null
           : entry === this._currentlyEditing
  }
  public setCurrentlyEditing(entry: VocabularyEntry): void { this._currentlyEditing = entry }

  constructor(
    private vocabularyService: VocabularyService,
    private dialog: MatDialog,
    private snackbarService: SnackbarService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if ('vocabulary' in changes) {
      this._currentlyEditing = null
      if (this.newEntryInput) {
        this.newEntryInput.value = null
      }
    }
  }

  addEntry(vocabulary: Vocabulary, name: string): void {
    if (name === "") { return }
    this.vocabularyService.addEntry(vocabulary.name, name).subscribe(
      () => this.snackbarService.showSuccessWithMessage(`Eintrag "${name}" zu "${vocabulary.display}" hinzugefügt.`)
    )
  }

  removeEntry(vocabulary: Vocabulary, entry: VocabularyEntry): void {
    const shoudDelete$ = entry.assigned_record_count === 0
      ? of(true)
      : this.dialog.open(ConfirmationDialogComponent, {data: {
        message: `Der Eintrag "${entry.name}" ist mit ${entry.assigned_record_count} Datensätzen verknüpft. Beim Löschen dieses Eintrags werden diese Verknüpfungen aufgehoben.`,
        yesAction: "EINTRAG TROTZDEM LÖSCHEN",
        noAction: "EINTRAG BEHALTEN",
        icon: "delete"}
      }).afterClosed()

    shoudDelete$.pipe(
      switchMap(shouldDelete => shouldDelete
        ? this.vocabularyService.removeEntry(vocabulary.name, entry)
        : EMPTY
      )
    ).subscribe(
      () => this.snackbarService.showSuccessWithMessage(`Eintrag "${entry.name}" aus "${vocabulary.display}" entfernt.`)
    )
  }

  updateEntry(vocabulary: Vocabulary, entry: VocabularyEntry, name: string, comment: string): void {
    const shouldRename$ = !(entry.name != name && entry.assigned_record_count > 0)
      ? of(true)
      : this.dialog.open(ConfirmationDialogComponent, {data: {
        message: `Der Eintrag "${entry.name}" ist mit ${entry.assigned_record_count} Datensätzen verknüpft. Wird dieser Eintrag in "${name}" umbenannt, wirkt sich dies auf alle verknüpften Datensätze aus.`,
        yesAction: "EINTRAG TROTZDEM UMBENENNEN",
        noAction: "EINTRAG NICHT UMBENENNEN",
        icon: "edit"}
      }).afterClosed()

    shouldRename$.pipe(
      switchMap(shouldRename => shouldRename
        ? this.vocabularyService.updateEntry(vocabulary.name, {
          id: entry.id,
          name: name,
          comment: comment?.trim() || null
        })
        : EMPTY
      )
    ).subscribe(
      () => this.snackbarService.showSuccessWithMessage(`Eintrag ${name} gespeichert.`)
    )
  }

  startEdit(entry: VocabularyEntry) {
    this.setCurrentlyEditing(entry)
    this.cdr.detectChanges()
    setTimeout(() => this.newNameInput.focus())
  }

  cancelEdit(entry: VocabularyEntry) {
    if (entry.comment === "") {
      entry.comment = null
    }
    this.setCurrentlyEditing(null)
  }

  addComment(entry: VocabularyEntry) {
    entry.comment = ""
    this.setCurrentlyEditing(entry)
    this.cdr.detectChanges()
    setTimeout(() => this.commentInput.focus())
  }

  removeComment(entry: VocabularyEntry) {
    entry.comment = null
  }

  buttonsDisabled(entry: VocabularyEntry) {
    return this._currentlyEditing && this._currentlyEditing !== entry
  }

  selectEntry(entry: VocabularyEntry) {
    this.selectedEntryId = entry.id
    this.entrySelected.emit(entry.id)
  }
}
