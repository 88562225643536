@if ({
  hasEditPermission: hasEditPermission$ | async,
  currentType: currentType$ | async,
  currentId: currentId$ | async
  }; as obs) {
  <mat-card appearance="outlined" class="content-wrapper">
    <mat-card-content class="content-container">
      <nav mat-tab-nav-bar [tabPanel]="tabPanel" style="display: flex; justify-content: space-between;">
        <div class="tab-grouping object">
          <div class="tab-grouping-header">Objekt</div>
          <div style="display: flex">
            @for (tab of objectTabs; track tab) {
              <a
                mat-tab-link
                routerLink="../../{{tab.type}}/"
                routerLinkActive #rla="routerLinkActive"
                [active]="rla.isActive">
                {{ tab.label }}
              </a>
            }
          </div>
        </div>
        <div class="tab-grouping record">
          <div class="tab-grouping-header">Datensatz</div>
          <div style="display: flex;">
            @for (tab of recordTabs; track tab) {
              <a
                mat-tab-link
                routerLink="../../{{tab.type}}/"
                routerLinkActive #rla="routerLinkActive"
                [active]="rla.isActive">
                {{ tab.label }}
              </a>
            }
          </div>
        </div>
      </nav>
      <mat-tab-nav-panel #tabPanel class="tab-panel">
        @switch (obs.currentType) {
          @case ('locations') {
            <insc-location-register-editor #editor class="editor"
              [showSidebar]="true"
              [selectionHandler]="onEntrySelected"
              [selectedId]="obs.currentId"
              [allowEdit]="obs.hasEditPermission"
            ></insc-location-register-editor>
          }
          @default {
            <insc-basic-register-editor class="editor"
              [showSidebar]="true"
              [type]="obs.currentType"
              [selectionHandler]="onEntrySelected"
              [selectedId]="obs.currentId"
              [allowEdit]="obs.hasEditPermission"
            ></insc-basic-register-editor>
          }
        }
      </mat-tab-nav-panel>
    </mat-card-content>
  </mat-card>
}
