import { Component, Input } from "@angular/core"
import { IconographyDesc } from "../../../../shared/models"


@Component({
    selector: 'insc-iconography-desc-view',
    templateUrl: './iconography-desc-view.component.html',
    styleUrls: ['./iconography-desc-view.component.css'],
    standalone: false
})
export class IconographyDescViewComponent {
  @Input() iconographyDesc: IconographyDesc
}
