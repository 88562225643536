import { Component, Input, OnInit } from "@angular/core"

import { Comment } from "../../../../shared/models"

@Component({
    selector: 'insc-comment-view',
    templateUrl: './comment-view.component.html',
    styleUrls: ['./comment-view.component.css'],
    standalone: false
})
export class CommentViewComponent implements OnInit {
  @Input() comment: Comment

  constructor() { }

  ngOnInit() {
  }

}
