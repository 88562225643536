import { Component, Inject } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog"
import { MatIconModule } from "@angular/material/icon"

export interface ConfirmationDialogData {
  icon?: string
  message: string
  noAction: string
  yesAction: string
}

export type ConfirmationDialogRef = MatDialogRef<ConfirmationDialogComponent, boolean>
@Component({
    selector: "insc-confirmation-dialog",
    template: `
                  <h1 mat-dialog-title>Bestätigen</h1>
                  <mat-dialog-content style="display: flex; align-items: center;">
                    <mat-icon style="color: red; margin-right: 1em;">{{ data.icon }}</mat-icon>
                    <div style="vertical-align: super;"> {{ data.message }}</div>
                  </mat-dialog-content>
                  <mat-dialog-actions>
                    <button mat-button (click)="dialogRef.close(false)">{{ data.noAction }}</button>
                    <button mat-button color="warn" (click)="dialogRef.close(true)">{{ data.yesAction }}</button>
                  </mat-dialog-actions>
                `,
    imports: [
        MatDialogModule,
        MatIconModule,
        MatButtonModule
    ]
})
export class ConfirmationDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData, @Inject(MatDialogRef) public dialogRef: ConfirmationDialogRef) { }
}
