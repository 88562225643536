import { Component, EventEmitter, HostBinding, Input, Output } from "@angular/core"
import { QueryParams } from "../../../services/data.service"

type SortOption = QueryParams["sort_by"]

export class SortChangeEvent {
  constructor(readonly sortBy: SortOption) {}
}


@Component({
    selector: 'insc-sort-control',
    templateUrl: './sort-control.component.html',
    styleUrls: ['./sort-control.component.scss'],
    standalone: false
})
export class SortControlComponent {
  @Input() disabled = false
  @Input() @HostBinding("class.compact") compact = false

  @Input() sortBy: SortOption
  @Output() sortByChanges = new EventEmitter<SortChangeEvent>()

  emitSortChangeEvent(sortBy: string): void {
    const sortChangeEvent = new SortChangeEvent(sortBy as SortOption)
    this.sortByChanges.emit(sortChangeEvent)
  }

  constructor() { }
}
