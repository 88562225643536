import { Component } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"

import { SubformComponent } from "../../../../shared/subform.component"

@Component({
    selector: 'insc-comment-form',
    templateUrl: 'comment-form.component.html',
    styles: [`
           :host {
             display: flex;
             flex-direction: column;
           }
           `],
    standalone: false
})
export class CommentFormComponent extends SubformComponent {

  static buildFormGroup(comment, destroyable = true) {
    return new UntypedFormGroup({
      id:      new UntypedFormControl(comment?.id),
      subject: new UntypedFormControl(comment?.subject),
      content: new UntypedFormControl(comment?.content),
      ...this.destroyableControl(comment?.id, destroyable)
    })
  }
}
