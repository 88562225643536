import { Component, forwardRef, Input, OnChanges, SimpleChanges } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { QueryDatingParams, QueryDatingValue, QueryResults } from "../../../../services/data.service"
import { InscEntity } from "../../../models/entity.model"
import { CriteriumDescription, INSC_SEARCH_CONTROL, SearchControlDirective } from "../search-control.directive"
import { SearchControl } from "../search-control.interface"
import { DatingSearchDialogComponent, DatingSearchDialogRef } from "./dating-search-dialog/dating-search-dialog.component"

type DatingParamsValue = QueryDatingParams["dating"]
type DatingResultsValue = QueryResults<InscEntity>["dating"]

@Component({
    selector: 'insc-dating-search-control',
    templateUrl: './dating-search-control.component.html',
    styleUrls: ['./dating-search-control.component.css'],
    providers: [{
            provide: INSC_SEARCH_CONTROL,
            useExisting: forwardRef(() => DatingSearchControlComponent)
        }],
    standalone: false
})
export class DatingSearchControlComponent implements OnChanges, SearchControl<DatingParamsValue, DatingParamsValue, DatingResultsValue> {
  @Input() disabled = false

  @Input() resultParams: DatingResultsValue
  searchControlDirective: SearchControlDirective<DatingParamsValue, DatingParamsValue>

  datingDescription: string
  private _params: DatingParamsValue
  get params(): QueryDatingValue { return this._params }
  set params(value: QueryDatingValue) {
    this._params = value

    this.searchControlDirective.emitCriteriaChange(this.getCriteria())
    this.searchControlDirective.emitParamsChange(value)
  }


  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('resultParams' in changes) {
      this._params = this.resultParams
      this.datingDescription = this.resultParams?.description
      this.searchControlDirective.emitCriteriaChange(this.getCriteria())
    }
  }

  openDatingChooser(): void {
    const dialogRef: DatingSearchDialogRef = this.dialog.open(DatingSearchDialogComponent, {
      width: "700px",
      data: this._params
    })

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return
      }

      this.datingDescription = result.description
      console.log(result)
      this.params = result.datingParams
    })
  }

  remove(): void {
    this.params = null
    this.datingDescription = null
  }

  getCriteria(): CriteriumDescription<DatingParamsValue>[] {
    if (!this._params) {
      return []
    }

    return [{
      name: this.searchControlDirective.inscSearchControl,
      displayName: this.searchControlDirective.searchControlDisplayName,
      displayValue: this.datingDescription,
      value: this._params
    }]
  }
}
