import { Component, Input } from "@angular/core"
import { environment } from "../../../../../environments/environment"
import { LiteratureReference } from "../../../../shared/models"


@Component({
    selector: 'insc-literaturereference-view',
    templateUrl: './literaturereference-view.component.html',
    styleUrls: ['./literaturereference-view.component.css'],
    standalone: false
})
export class LiteraturereferenceViewComponent  {
  @Input() literatureReference: LiteratureReference

  constructor() { }

  urnResolver = environment.urnResolver
}
