<ng-container [formGroup]="formGroup">

  <mat-form-field>
    <input matInput  type="text" formControlName="name">
    <mat-label>Name</mat-label>
    <mat-error inscError errorControlName="name"></mat-error>
  </mat-form-field>

  @for (alt_name of formGroup.controls.alternative_names['controls']; track alt_name; let i = $index) {
    <div class="alternative-names">
      <mat-form-field class="alternative-name">
        <input matInput  type="text" [formControl]="alt_name">
        <mat-label>Alternative Schreibweise</mat-label>
        <mat-error inscError [errorControl]="alt_name"></mat-error>
      </mat-form-field>
      <button type="button" mat-button (click)="removeAltName(i)">entfernen</button>
    </div>
  }
  <button class="add-alternative-name-button" mat-button type="button" (click)="addAltName()">+ alternative Schreibweise</button>

  <mat-form-field>
    <mat-select formControlName="gender">
      <mat-option value="male">männlich</mat-option>
      <mat-option value="female">weiblich</mat-option>
      <mat-option value="">keine Angabe</mat-option>
    </mat-select>
    <mat-label>Geschlecht</mat-label>
    <mat-error inscError errorControlName="gender"></mat-error>
  </mat-form-field>

  <insc-external-norm-data-id-input
    providerId="gnd"
    label="GND-ID"
    formControlName="gnd_id"
    [lookupOptions]="{type: 'person'}"
    (lookupClick)="externalActionTriggered.emit($event)"
  ></insc-external-norm-data-id-input>

  <insc-external-norm-data-id-input
    providerId="wikidata"
    label="Wikidata-ID"
    formControlName="wikidata_id"
    [lookupOptions]="{type: 'person'}"
    (lookupClick)="externalActionTriggered.emit($event)"
  ></insc-external-norm-data-id-input>

  <insc-external-norm-data-id-input
    providerId="germania_sacra"
    label="Germania Sacra-ID (GSN)"
    formControlName="germania_sacra_id"
    (lookupClick)="externalActionTriggered.emit($event)"
  ></insc-external-norm-data-id-input>

  <mat-form-field>
    <input matInput  type="text" formControlName="timeframe">
    <mat-label>Zeitliche Einordnung</mat-label>
    <mat-error inscError errorControlName="timeframe"></mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label>Kommentar</mat-label>
    <textarea matInput  type="text" formControlName="comment"></textarea>
    <mat-error inscError errorControlName="comment"></mat-error>
  </mat-form-field>

</ng-container>
