import { Component, HostBinding, Inject, OnInit } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"

import { Observable } from "rxjs"
import { ObjectGroupService } from "../../../../services/data.service"

import { ObjectGroup } from "../../../../shared/models/object-group.model"

interface ChooseObjectGroupDialogData  {
  selected_id: string
}

interface ChooseObjectGroupDialogResult  {
  object_group_id: string
}

export type ChooseObjectGroupDialogRef = MatDialogRef<ChooseObjectGroupDialogComponent, ChooseObjectGroupDialogResult>

@Component({
    selector: 'insc-choose-object-group-dialog',
    templateUrl: './choose-object-group-dialog.component.html',
    styleUrls: ['./choose-object-group-dialog.component.scss'],
    standalone: false
})
export class ChooseObjectGroupDialogComponent implements OnInit {

  filterValue = ""
  selectObjectGroupForm: UntypedFormGroup

  results$: Observable<ObjectGroup[]>

  @HostBinding('style.height') height = '80vh'

  constructor(
    @Inject(MatDialogRef) public dialogRef: ChooseObjectGroupDialogRef,
    @Inject(MAT_DIALOG_DATA) public data: ChooseObjectGroupDialogData,
    public objectGroupService: ObjectGroupService
  ) { }

  ngOnInit(): void {
    this.selectObjectGroupForm = new UntypedFormGroup({object_group_id: new UntypedFormControl(this.data.selected_id || null)})
    this.results$ = this.objectGroupService.list()
  }

  confirmSelection(): void {
    this.dialogRef.close({object_group_id: this.selectObjectGroupForm.get("object_group_id").value as string})
  }

}
