import { Component } from "@angular/core"
import { AbstractControl, ValidationErrors } from "@angular/forms"
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete"
import { Subscription } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { VocabularyEntry } from "../../../models/vocabulary-entry.model"

import { AbstractVocabularySelectComponent } from "./abstract-vocabulary-select.component"

@Component({
    selector: 'insc-single-vocabulary-select',
    templateUrl: 'single-vocabulary-select.component.html',
    styleUrls: ['./vocabulary-select.scss'],
    standalone: false
})
export class SingleVocabularySelectComponent extends AbstractVocabularySelectComponent<string> {

  private _valueChangesSubscription: Subscription = null

  // formControlValue defined in AbstractVocabularySelect, defined here as string
  vocabularySelectAfterInit(): void {
    this._valueChangesSubscription?.unsubscribe()
    this.inputCtrl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      this.ngControl.control.updateValueAndValidity()
      if (value === "") {
        this.formControlValue = null
      } else {
        this.formControlValue = value as string
      }

      this.propagateChange(this.formControlValue)
    })

  }

  reset(): void {
    this.formControlValue = null
    this.propagateChange(this.formControlValue)
    // why use native element??
    const inputElem = this.entryInputRef.nativeElement as HTMLInputElement
    inputElem.value = ''
  }

  vocabularySelectAfterWriteValue(value: string): void {
    this.inputCtrl.setValue(value, {emitEvent: false})
    this.inputCtrl.markAsPristine()
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.formControlValue = event.option.value
    this.propagateChange(this.formControlValue)
    this.inputCtrl.setValue(event.option.value)
  }

  entryValidatorFactory = (entries: VocabularyEntry[]) =>
    (control: AbstractControl): ValidationErrors => {
      if (control.value !== "" && control.value != null && entries && !entries.some(entry => entry.name === control.value)) {
        return {
          valueNotAllowedError: "Der eingegebene Wert muss einem Eintrag aus dem Vokabular entsprechen."
        }
      }

    return null
  }
}
