import { animate, state, style, transition, trigger } from "@angular/animations"
import { Component, ContentChild, Directive, HostBinding, Input, OnDestroy, Output } from "@angular/core"
import { FormControl } from "@angular/forms"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"

@Directive({
    selector: "[inscRegisterEditorMenu]",
    standalone: false
})
export class RegisterEditorMenuDirective {
  @HostBinding("style.width") styleWidth = "100%"
}

@Directive({
    selector: "[inscRegisterEditorForm]",
    standalone: false
})
export class RegisterEditorFormDirective {}

@Directive({
    selector: "[inscRegisterEditorActions]",
    standalone: false
})
export class RegisterEditorActionsDirective {
  @HostBinding("style.display") private display = "flex"
  @HostBinding("style.align-items") private alignItems = "center"
}

@Directive({
    selector: "[inscRegisterEditorSidebar]",
    standalone: false
})
export class RegisterEditorSidebarDirective {
  @HostBinding('style.height') height = '100%'
  @HostBinding('style.display') display = 'flex'
  @HostBinding('style.flex-direction') flexDirection = 'column'
}

@Component({
    selector: 'insc-register-editor-layout',
    templateUrl: './register-editor-layout.component.html',
    styleUrls: ['./register-editor-layout.component.scss'],
    animations: [
        trigger("searchSpinner", [
            state("fade-in", style({ opacity: 1 })),
            state("fade-out", style({ opacity: 0 })),
            transition("fade-in => fade-out", animate("200ms")),
            transition("fade-out => fade-in", animate("200ms")),
        ])
    ],
    standalone: false
})
export class RegisterEditorLayoutComponent implements OnDestroy {

  private unsubscribe$ = new Subject<void>()

  @Input() loading = false

  @ContentChild(RegisterEditorSidebarDirective) sidebarDirective: RegisterEditorSidebarDirective
  @ContentChild(RegisterEditorFormDirective) formDirective: RegisterEditorSidebarDirective

  protected searchControl = new FormControl<string>("")
  @Output() searchChanges = this.searchControl.valueChanges.pipe(
    takeUntil(this.unsubscribe$)
  )


  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  resetSearch() {
    this.searchControl.reset()
  }

}
