import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { FacetValueFlatNode } from "../facet-value-tree-builder.service"

@Component({
    selector: 'insc-facet',
    templateUrl: './facet.component.html',
    styleUrls: ["./facet.component.scss"],
    standalone: false
})
export class FacetComponent implements OnInit {

  @Input() title: string
  @Input() info: string
  @Input() initiallyExpanded = false
  @Input() field: string

  @Input() valueTreeNodes: FacetValueFlatNode[]

  @Output() selectionChanges = new EventEmitter<void>()


  /**
   * Keep a reference to the current expansion state.
   * For UX reasons, the panel will collapse when there are no values. When there are values
   * again, a previously expanded panel will be restored.
   */
  private wantsToBeExpanded: boolean
  get expanded(): boolean {
    return this.valueTreeNodes?.length > 0 && this.wantsToBeExpanded
  }

  ngOnInit(): void {
    this.wantsToBeExpanded = this.initiallyExpanded
  }

  get selectedKeyPaths(): string[] {
    return this.valueTreeNodes
      .filter(node => node.selected)
      .map(selectedNode => selectedNode.keyPath)
  }

  deselectKeyPath(keyPath: string): void {
    const nodeToRemove = this.valueTreeNodes.find(node => node.keyPath === keyPath)
    if (nodeToRemove) {
      nodeToRemove.selected = false
      this.selectionChanges.emit()
    }
  }

  /**
   * Toggle selection of a node.
   * Only one level in a hierarchy should be selectable. So when a leaf node is selected,
   * its parent and children will be deselected. When a leaf node is deselected, fall back
   * to parent selection.
   * @param {FacetValueFlatNode} toggledNode
   */
  toggleSelected(toggledNode: FacetValueFlatNode): void {
    toggledNode.selected = !toggledNode.selected
    const parent = this.getNodeParent(toggledNode)

    if (toggledNode.selected) {
      // node has been selected:
      // deselect children
      if (!this.nodeIsLeaf(toggledNode)) {
        this.getNodeChildren(toggledNode).forEach(child => child.selected = false)
      }
    }

    if (parent) {
      parent.selected = !toggledNode.selected
    }

    this.selectionChanges.emit()
  }


  afterPanelExpand(): void {
    this.wantsToBeExpanded = true
  }

  afterPanelCollapse(): void {
    // when the panel has been collapsed and there are values present, this must have happened by user interaction
    if (this.valueTreeNodes.length > 0) {
      this.wantsToBeExpanded = false
    }
  }

  getNodePadding = (node: FacetValueFlatNode): string => `${node.level * 0.5}em`


  // flat tree helpers

  private nodeIsLeaf(node: FacetValueFlatNode): boolean {
    const nodeIndex = this.valueTreeNodes.indexOf(node)
    const isLastNode = nodeIndex === this.valueTreeNodes.length - 1
    if (isLastNode) {
      return true
    }

    const nextNode = this.valueTreeNodes[nodeIndex + 1]
    return nextNode.level <= node.level
  }

  private getNodeChildren(node: FacetValueFlatNode): FacetValueFlatNode[] {
    const nodeIndex = this.valueTreeNodes.indexOf(node)
    const isLastNode = nodeIndex === this.valueTreeNodes.length - 1
    if (isLastNode) {
      return []
    }

    const followingNodes = this.valueTreeNodes.slice(nodeIndex + 1)
    const nextNonChildIndex = followingNodes.findIndex(followingNode => followingNode.level <= node.level )
    return nextNonChildIndex > 0
      ? followingNodes.slice(0, nextNonChildIndex)
      : followingNodes
  }

  private getNodeParent(node: FacetValueFlatNode): FacetValueFlatNode | null {
    if (node.level === 1) {
      return null
    }

    const nodeIndex = this.valueTreeNodes.indexOf(node)
    const preceedingNodesReverse = this.valueTreeNodes.slice(0, nodeIndex).reverse()
    return preceedingNodesReverse.find(preceedingNode => preceedingNode.level < node.level)
  }
}
