@if ({
  sidebarAutoHideEnabled: sidebarAutoHide | async
  }; as obs) {
  <mat-sidenav-container [hasBackdrop]="false" autosize>
    <!-- SIDEBAR -->
    <mat-sidenav position="start" [opened]="true" [mode]="obs.sidebarAutoHideEnabled ? 'over' : 'side'"
      [ngClass]="{collapsed: sidebarCollapsed}"
      (mouseenter)="onSidebarMouseEnter(obs.sidebarAutoHideEnabled)"
      (mouseleave)="onSidebarMouseLeave(obs.sidebarAutoHideEnabled)"
      (transitionend)="onSidebarTransitionEnd()"
      >
      <div class="sidebar-content">
        <!-- Enable sidebar autohide -->
        @if (!obs.sidebarAutoHideEnabled) {
          <button
            mat-icon-button
            class="toggle-button"
            matTooltip="Leiste einklappen"
            (click)="enableSidebarAutoHide()"
            (mouseenter)="sidebarButtonHover = true"
            (mouseleave)="sidebarButtonHover = false"
            ><mat-icon>keyboard_double_arrow_left</mat-icon></button>
          }
          <!-- Disable sidebar autohide -->
          @if (obs.sidebarAutoHideEnabled) {
            <button
              mat-icon-button
              class="toggle-button"
              matTooltip="Leiste fixieren"
              (click)="disableSidebarAutoHide()"
              ><mat-icon>push_pin</mat-icon></button>
            }
            <!-- Identity card -->
            <mat-card class="identity">
              <!-- Image -->
              @if (previewImageUrl) {
                <img matCardImage class="preview-image" src="{{previewImageUrl}}">
              } @else {
                <div matCardImage class="preview-image no-image"></div>
              }
              <!-- Header -->
              <mat-card-content class="section">
                <mat-card-title class="record-title">
                  @if (record?.id) {
                    <ng-content select="[inscRecordTitle]"></ng-content>
                  } @else {
                    <em>Neuer Datensatz</em>
                  }
                  @if (!isEditModeActive) {
                    <button [matMenuTriggerFor]="recordActionMenu" mat-icon-button class="more-button"><mat-icon>more_vert</mat-icon></button>
                  }
                  <mat-menu #recordActionMenu="matMenu">
                    <div mat-menu-item disabled>Datensatzaktionen</div>
                    <button mat-menu-item [routerLink]="['.', 'clone']"><mat-icon>file_copy</mat-icon><span>Duplizieren</span></button>
                    <button mat-menu-item (click)="delete.emit()"><mat-icon color="warn">delete</mat-icon><span>Löschen</span></button>
                  </mat-menu>
                </mat-card-title>
                <mat-card-subtitle>
                  <ng-content select="[inscRecordSubtitle]"></ng-content>
                </mat-card-subtitle>
              </mat-card-content>
              <!-- Metadata -->
              <mat-card-content class="section">
                @if (record) {
                  <div class="metadata">
                    <div>
                      <div class="label">Interne ID</div>
                      <div class="value">{{recordType}}/{{ record.id }}</div>
                    </div>
                    <div>
                      <div class="label">Angelegt</div>
                      <div class="value">{{ record.created_at | date: "medium"}} von <a routerLink="/admin/users/{{record.creator.name}}">{{record.creator.name}}</a></div>
                    </div>
                    <div>
                      <div class="label">Bearbeitet</div>
                      <div class="value"> {{ record.updated_at | date: "medium" }} von <a routerLink="/admin/users/{{record.last_editor.name}}">{{record.last_editor.name}}</a></div>
                    </div>
                    <div *inscIfUserPermission="'update_records'">
                      <div class="label">Status</div>
                      <div class="value" class="editorial-state">
                        <mat-form-field subscriptSizing="dynamic">
                          <mat-select class="editorial-state-select" [formControl]="editingStatusCtrl">
                            <mat-option value="editing">In Bearbeitung</mat-option>
                            <mat-option value="needs_review">Durchsicht benötigt</mat-option>
                            <mat-option value="approved" *inscIfUserPermission="'approval'; else noApprovalPermission">Freigegeben</mat-option>
                            <ng-template #noApprovalPermission>
                              <mat-option value="approved" disabled="true">Freigegeben</mat-option>
                            </ng-template>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                    <div>
                      <div></div>
                      <div>
                        <insc-activity-link [type]="activityLinkType" [id]="record.id"></insc-activity-link>
                      </div>
                    </div>
                  </div>
                }
              </mat-card-content>
              <!-- Buttons -->
              <mat-card-actions *inscIfUserPermission="'update_records'" align="end">
                @if (!isEditModeActive) {
                  <button mat-button color="primary" [routerLink]="['edit']">
                    <mat-icon>edit</mat-icon> <span>METADATEN BEARBEITEN</span>
                  </button>
                }
                @if (isEditModeActive) {
                  <button color="primary" mat-button (click)="save.emit()"><mat-icon>save</mat-icon><span>SPEICHERN</span></button>
                }
              </mat-card-actions>
            </mat-card>
            @if (sidebarSections.length > 0) {
              <mat-divider></mat-divider>
            }
            <!-- Sidebar Sections -->
            @if (record) {
              @for (sectionTemplate of sidebarSections; track sectionTemplate; let isLast = $last) {
                <ng-container *ngTemplateOutlet="sectionTemplate"></ng-container>
                @if (!isLast) {
                  <mat-divider></mat-divider>
                }
              }
            } @else {
              <mat-divider></mat-divider>
              <mat-card appearance="outlined" class="new-record-hint">
                <mat-card-content class="new-record-hint">Verknüpfungen zu anderen Datentsätzen sowie Aufnahmen können nach dem ersten Speichern hergestellt werden.</mat-card-content>
              </mat-card>
            }
          </div>
          <div class="sidebar-collapsed-content" role="presentation">
            @if (previewImageUrl) {
              <img class="preview-image" src="{{previewImageUrl}}">
            } @else {
              <div class="preview-image no-image"></div>
            }
            <mat-icon class="collapsed-sidebar-icon">keyboard_double_arrow_right</mat-icon>
          </div>
        </mat-sidenav>
        <!-- RECORD CONTENT (view or form)-->
        <mat-sidenav-content [ngClass]="{'sidebar-auto-hide': obs.sidebarAutoHideEnabled}">
          <div class="content">
            <router-outlet></router-outlet>
          </div>
        </mat-sidenav-content>
      </mat-sidenav-container>
    }
