import { Component, Input } from "@angular/core"
import { LocationDisplayHelperService } from "../../../../services/location-display-helper.service"
import { ObjectLocation } from "../../../../shared/models/object-location.model"

@Component({
    selector: 'insc-location-header-view',
    templateUrl: './location-header-view.component.html',
    styleUrls: ['./location-header-view.component.scss'],
    standalone: false
})
export class LocationHeaderViewComponent {

  @Input() location: ObjectLocation

  constructor(public locationDisplayHelper: LocationDisplayHelperService) { }
}
