import { Component, Inject } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"

import { DatingSpecification } from "../../../../../shared/models"


export type DatingChooserDialogRef = MatDialogRef<DatingChooserDialogComponent, DatingSpecification>



@Component({
    selector: 'insc-dating-chooser-dialog',
    templateUrl: 'dating-chooser-dialog.component.html',
    styleUrls: ['dating-chooser-dialog.component.scss'],
    standalone: false
})
export class DatingChooserDialogComponent {


  constructor(
    @Inject(MatDialogRef) private dialogRef: DatingChooserDialogRef,
    @Inject(MAT_DIALOG_DATA) public data: DatingSpecification | null) {

  }

  closeWithResult(spec: DatingSpecification) {

    this.dialogRef.close({
      description: spec.description,
      primary: spec.primary,
      secondary: spec.secondary,
      range_modifier: spec.range_modifier
    })
  }
}
