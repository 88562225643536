import { Component } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { LiteratureEntry } from "../../../../shared/models/literature-entry.model"
import { SubformComponent } from "../../../../shared/subform.component"

@Component({
    selector: "insc-literature-entry-form",
    templateUrl: "./literature-entry-form.component.html",
    styleUrls: ["shared-styles.scss", "./literature-entry-form.component.scss"],
    standalone: false
})
export class LiteratureEntryFormComponent extends SubformComponent {
  static buildFormGroup(literatureEntry: Partial<LiteratureEntry>): FormGroup {
    return new FormGroup({
      id:                    new FormControl(literatureEntry?.id),
      name:                  new FormControl(literatureEntry?.name),
      literature_entry_type: new FormControl(literatureEntry?.literature_entry_type || "di_publication"),
      year:                  new FormControl(literatureEntry?.year),
      unpublished:           new FormControl(literatureEntry?.unpublished),
      comment:               new FormControl(literatureEntry?.comment),
      citation_key:          new FormControl(literatureEntry?.citation_key),
      url:                   new FormControl(literatureEntry?.url),
      urn:                   new FormControl(literatureEntry?.urn),
      volume_no:             new FormControl(literatureEntry?.volume_no)
    })
  }
}
