import { Component, Input, OnInit } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { PersonOrganization } from "../../../../shared/models/personorganization.model"
import { SubformComponent } from "../../../../shared/subform.component"

@Component({
    selector: 'insc-person-organization-form',
    templateUrl: './person-organization-form.component.html',
    styles: [`
           :host {
             display: flex;
             flex-direction: column;
           }

           .extra-fields {
             display: flex;
           }

           .authenticity {
             flex: 1;
             margin-left: 1rem;
           }
           `],
    standalone: false
})
export class PersonOrganizationFormComponent extends SubformComponent implements OnInit {

  @Input() showExtraFields = true

  static buildFormGroup(personOrg: Partial<PersonOrganization>, destroyable = true) {
    return new UntypedFormGroup({
      id:           new UntypedFormControl(personOrg?.id),
      entry:        new UntypedFormControl(personOrg?.entry),
      entry_type:   new UntypedFormControl(personOrg?.entry_type ?? "HistoricalPerson"),
      role:         new UntypedFormControl(personOrg?.role),
      authenticity: new UntypedFormControl(personOrg?.authenticity),
      ...this.destroyableControl(personOrg.id, destroyable)
    })
  }

  ngOnInit(): void {
    this.formGroup.get('entry').valueChanges.subscribe(entry => {
      if (entry) {
        this.formGroup.get('entry_type').setValue(entry['type'])
      }
    })
  }


}
