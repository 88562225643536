import { Component, Optional, Self } from "@angular/core"
import { ControlValueAccessor, NgControl } from "@angular/forms"
import { NormDataService } from "../../../../../services/norm-data.service"
import { LiteratureEntry } from "../../../../../shared/models/literature-entry.model"
import { NormDataEntry } from "../../../../../shared/models/norm-data-entry.model"
import { RegisterEntryDialogService } from "../../../../registers/register-entry-dialog/register-entry-dialog.service"

@Component({
    selector: "insc-literature-entry-input",
    templateUrl: "./literature-entry-input.component.html",
    styleUrls: ["./literature-entry-input.component.scss"],
    standalone: false
})
export class LiteratureEntryInputComponent implements ControlValueAccessor {

  disabled = false
  literatureEntry: NormDataEntry

  constructor(
    private normDataService: NormDataService,
    private registerEntryDialogService: RegisterEntryDialogService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  openEditor() {
    this.registerEntryDialogService.open<LiteratureEntry>({types: ["literature_entries"], selectEntry: this.literatureEntry})
      .subscribe(selectedEntry => {
        if (selectedEntry) {
          this.propagateChange(selectedEntry)
          this.literatureEntry = selectedEntry
        }
      })
  }

  propagateChange = (_: any) => {}

  writeValue(literatureEntry: NormDataEntry) {
    this.literatureEntry = literatureEntry
  }

  registerOnChange(fn) {
    this.propagateChange = fn
  }

  registerOnTouched() {}

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled
  }
}
