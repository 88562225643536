import { SelectionModel } from "@angular/cdk/collections"
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop"
import { CdkConnectedOverlay, CdkOverlayOrigin } from "@angular/cdk/overlay"
import { AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, TrackByFunction, ViewChild } from "@angular/core"
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from "@angular/forms"
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field"
import { MatPaginator } from "@angular/material/paginator"
import { MatSort } from "@angular/material/sort"
import { MatTable } from "@angular/material/table"
import { Subject, Subscription } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { InscImage, IptcData } from "../../../../shared/models/image.model"
import { IptcArrayItem, IPTCDataset, IptcDatasetService, IptcValue, } from "../multi-iptc-editor/iptc-dataset.service"
import {
  IptcFormFieldValueState,
  IptcFormFieldWrapperComponent
} from "../multi-iptc-editor/iptc-form-field-wrapper-component/iptc-form-field-wrapper.component"
import { IptcDatasetFormGroupType, TableIptcEditorDataSource, TableIptcEditorImageRow } from "./table-iptc-editor-data-source"

@Component({
    selector: 'insc-table-iptc-editor',
    templateUrl: './table-iptc-editor.component.html',
    styleUrls: ['./table-iptc-editor.component.scss'],
    providers: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "fill" } }],
    standalone: false
})
export class TableIptcEditorComponent implements AfterViewInit, OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<TableIptcEditorImageRow>;

  @ViewChild("addKeywordOverlay") addKeywordOverlay!: CdkConnectedOverlay
  @ViewChild("addKeywordInput") keywordInput: ElementRef

  @Input() iptcDatasets: IPTCDataset[]

  dataSource: TableIptcEditorDataSource;

  iptcFields = ["title",  "headline",  "description", "creator", "description_writer", "source",  "copyright_notice", "marked", "credit_line", "rights_usage_terms", "city", "sublocation",  "province_or_state", "country",  "country_code", "keywords"]
  cellTitles = {
    title: "Titel",
    headline: "Überschrift",
    description: "Beschreibung",
    creator: "Ersteller",
    description_writer: "Verfasser der Beschreibung",
    source: "Quelle",
    copyright_notice: "Copyright-Vermerk",
    marked: "Copyright-Status",
    credit_line: "Credit (Anbieter)",
    rights_usage_terms: "Nutzungsbedingungen",
    city: "Ort",
    sublocation: "Ortsdetail",
    province_or_state: "Bundesland/Kanton",
    country: "Land",
    country_code: "ISO-Landescode",
    keywords: "Schlagwörter"
  }


  addKeywordOverlayCurrentOrigin: CdkOverlayOrigin
  addKeywordFormArray: FormArray<FormControl<IptcArrayItem<string>>>
  addKeywordOverlayOpen: boolean = false


  private rowSelections = new SelectionModel<InscImage["id"]>(true)

  selectColumnsOverlayOpen: boolean = false
  columnDisplaySelection = new SelectionModel<string>(true, this.iptcFields)

  /** Columns displayed in the table. Columns IDs can be added, removed, or reordered. */
  get displayedColumns(): string[] {
    return [ "select", "image", "name", ...this.iptcFields.filter(field => this.columnDisplaySelection.isSelected(field))]
  }

  private iptcDatasetFormArray: FormArray<FormGroup<IptcDatasetFormGroupType>>
  get iptcDatasetFormGroups(): FormGroup<IptcDatasetFormGroupType>[] {
    return this.iptcDatasetFormArray.controls
  }
  private get selectedIptcDatasetFormGroups(): FormGroup<IptcDatasetFormGroupType>[] {
    return this.iptcDatasetFormGroups.filter(formGroup => this.rowSelections.isSelected(formGroup.get("id").value))
  }

  private unsubscribe$ = new Subject<void>()
  private keywordSubscriptions: Subscription = null

  get selectedIds(): InscImage["id"][] {
    return this.rowSelections.selected
  }

  get selectionCount(): number {
    return this.selectedIds.length
  }

  get selectionString(): string {
    return this.selectionCount > 0
           ? this.selectionCount === 1
             ? "den ausgewählten Datensatz"
             : `${this.selectionCount} ausgewählte Datensätze`
           : `alle Datensätze`
  }

  constructor(
    readonly iptcDatasetService: IptcDatasetService,
    private formBuilder: FormBuilder
  ) {
    this.dataSource = new TableIptcEditorDataSource();
  }

  // ngDoCheck() {
  //  // console.log("check", Zone.currentTask.source)
  // }

  ngOnChanges(changes: SimpleChanges) {
    if ('iptcDatasets' in changes) {
      this.setData()
    }
  }

  ngAfterViewInit(): void {
    this.table.dataSource = this.dataSource;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  setData() {
   this.iptcDatasetFormArray = new FormArray<FormGroup<IptcDatasetFormGroupType>>([])

    if (this.keywordSubscriptions) {
     this.keywordSubscriptions.unsubscribe()
   }
   this.keywordSubscriptions = new Subscription()

    this.rowSelections.clear()

    if (!this.iptcDatasets) {
      this.dataSource.setData([])
      return
    }

    const rowData: TableIptcEditorImageRow[] = this.iptcDatasets
      // .filter((iptcDataset, index, array) => array.findIndex(_image => _image.id === iptcDataset.id) === index)
      .map((iptcDataset) => {

      const keywordsFormArray = this.formBuilder.array(iptcDataset.keywords)
      const iptcDatasetFormGroup = this.formBuilder.group({
        ...iptcDataset,
        keywords: keywordsFormArray
      })

      this.iptcDatasetFormArray.push(iptcDatasetFormGroup)

      const keywordSubscription = keywordsFormArray.valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => iptcDatasetFormGroup.get("_keywords_override").setValue(true))

      this.keywordSubscriptions.add(keywordSubscription)

      return {
        imageId:         iptcDataset.id,
        imageName:       iptcDataset.image.name,
        imagePreviewUrl: iptcDataset.image.preview_url,
        iptcDatasetFormGroup
      }
    })

    this.dataSource.setData(rowData)
    setTimeout(() => this.table.updateStickyColumnStyles())
  }

  moveColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.iptcFields, event.previousIndex, event.currentIndex)
  }

  select(imageId: InscImage["id"]): void {
    this.rowSelections.select(imageId)
  }

  deselect(imageId: InscImage["id"]): void {
    this.rowSelections.deselect(imageId)
  }

  toggleSelection(imageId: InscImage["id"]): void {
   this.rowSelections.toggle(imageId)
  }

  isSelected(imageId: InscImage["id"]): boolean {
    return this.rowSelections.isSelected(imageId)
  }

  clearSelection() {
    this.rowSelections.clear()
  }

  showAddKeywordOverlay(origin: CdkOverlayOrigin, formArray: FormArray) {
    this.addKeywordOverlayCurrentOrigin = origin
    this.addKeywordFormArray = formArray
    this.addKeywordOverlayOpen = true
  }

  removeAddKeywordOverlay() {
    this.addKeywordOverlayOpen = false
  }

  onOverlayDetach() {
    this.removeAddKeywordOverlay()
    setTimeout(() => {
      this.addKeywordFormArray = null
      this.addKeywordOverlayCurrentOrigin = null
    })
  }

  addKeyword(keyword: string) {
    const formControl = new FormControl<IptcArrayItem<string>>(IptcArrayItem.newlyAddedItem(keyword))
    this.addKeywordFormArray.push(formControl)
    this.addKeywordFormArray.markAsDirty()
    this.removeAddKeywordOverlay()
  }

  focusAddKeywordInput() {
    setTimeout(() => this.keywordInput.nativeElement.focus())
  }

  getCellClass(valueState: IptcFormFieldValueState) {
    switch(valueState) {
      case "manualValue": return 'manual'
      case "generatedValue": return 'generated'
      default: return {}
    }
  }

  // for type inference in cell definitions
  toRowData(rowData: unknown) {
    return rowData as TableIptcEditorImageRow
  }

  getIptcFormControl<K extends keyof IptcDatasetFormGroupType>(rowData: unknown, controlName: K) {
    // FormGroup.get's return type is always AbstractControl<T> but we want FormArray/FormControl to get
    // type inference in the template, so casting is necessary
    return (rowData as TableIptcEditorImageRow).iptcDatasetFormGroup.get(controlName) as unknown as IptcDatasetFormGroupType[K]
  }

  hasKeywordsOverride(rowData: unknown): boolean {
    return this.getIptcFormControl(rowData, "_keywords_override").value as boolean
  }

  getIptcEffectiveValue<T>(iptcValue: IptcValue<T>) {
    return iptcValue?.value !== undefined ? iptcValue.value : iptcValue?.generated
  }

  copyValue<K extends keyof IptcData>(formControlName: K, iptcValue: IptcValue<IptcData[K]>) {
    const targetFormGroups = this.selectionCount > 0 ? this.selectedIptcDatasetFormGroups : this.iptcDatasetFormGroups
    this.copyValueToRecords(targetFormGroups, formControlName, iptcValue)
  }

  copyValueToRecords<K extends keyof IptcData>(recordFormGroups: FormGroup<IptcDatasetFormGroupType>[], formControlName: K, iptcValue: IptcValue<IptcData[K]>) {
    const valueToCopy = this.getIptcEffectiveValue(iptcValue)

    recordFormGroups.forEach(recordFormGroup => {
      const valueControl = recordFormGroup.get(formControlName) as AbstractControl<IptcValue<IptcData[K]>>
      if (!valueControl) {
        return
      }

      const recordIptcValue = valueControl.value
      if (recordIptcValue !== null && !(recordIptcValue instanceof IptcValue)) {
        throw new Error("copyValue only works for IptcValues, not arrays.")
      }

      if (this.getIptcEffectiveValue(recordIptcValue) !== valueToCopy) {
        const newIptcValue = new IptcValue<IptcData[K]>({generated: recordIptcValue?.generated, value: valueToCopy})
        valueControl.setValue(newIptcValue)
        valueControl.markAsDirty()
      }
    })
  }

  getControlsForKeyword(keywordsFormArray: FormArray<FormControl<IptcArrayItem<string>>>, keyword: string) {
    return keywordsFormArray.controls.filter(control => control.value.value === keyword || control.value.generated === keyword)
  }

  cloneKeywordIptcValue(keyword: string, targetFormArray: FormArray<FormControl<IptcArrayItem<string>>>) {
    const existingControlsForKeyword = this.getControlsForKeyword(targetFormArray, keyword)

    if (existingControlsForKeyword.length > 0) {
      // control for keyword already exists, restore if deleted
      existingControlsForKeyword.forEach(keywordControl => {
        const existingItem = keywordControl.value
        if (keywordControl.value.markedForRemoval) {
          keywordControl.setValue(new IptcArrayItem(existingItem))
          keywordControl.markAsDirty()
        } else if (keywordControl.value.isManuallyRemoved) {
          this.restoreRemovedGeneratedIptcArrayItem(keywordControl)
        }
      })
    } else {
      const newIptcKeywordValue = IptcArrayItem.newlyAddedItem(keyword)
      const newIptcKeywordFormControl = new FormControl<IptcArrayItem<string>>(newIptcKeywordValue)
      targetFormArray.push(newIptcKeywordFormControl)
      targetFormArray.markAsDirty()
    }
  }

  copyKeyword(iptcValue: IptcValue<string>) {
    const targetFormGroups = this.selectionCount > 0 ? this.selectedIptcDatasetFormGroups : this.iptcDatasetFormGroups
    this.copyKeywordToRecords(targetFormGroups, iptcValue)
  }

  copyKeywordToRecords(recordFormGroups: FormGroup<IptcDatasetFormGroupType>[], iptcValue: IptcValue<string>) {
    const keyword = this.getIptcEffectiveValue(iptcValue)

    recordFormGroups.forEach(formGroup => {
      const keywordsFormArray = formGroup.get('keywords') as FormArray<FormControl<IptcArrayItem<string>>>
      this.cloneKeywordIptcValue(keyword, keywordsFormArray)
    })
  }

  removeKeywordControl(keywordControl: FormControl<IptcArrayItem<string>>) {
    const value = keywordControl.value
    if (value.isUntouched) {
      this.removeGeneratedIptcArrayItem(keywordControl)
    } else if (value.isManuallyAdded) {
      this.removeManuallyAddedIptcArrayItem(keywordControl)
    }
  }

  removeKeyword(iptcValue: IptcValue<string>) {
    const targetFormGroups = this.selectionCount > 0 ? this.selectedIptcDatasetFormGroups : this.iptcDatasetFormGroups
    this.removeKeywordFromRecords(targetFormGroups, iptcValue)
  }

  removeKeywordFromRecords(recordFormGroups: FormGroup<IptcDatasetFormGroupType>[], iptcValue: IptcValue<string>) {
    const keyword = this.getIptcEffectiveValue(iptcValue)

    recordFormGroups.forEach(formGroup => {
      const keywordsFormArray = formGroup.get('keywords') as FormArray<FormControl<IptcArrayItem<string>>>
      const existingControlsForKeyword = this.getControlsForKeyword(keywordsFormArray, keyword)

      existingControlsForKeyword.forEach(keywordControl =>
        this.removeKeywordControl(keywordControl)
      )
    })
  }

  cloneKeywords(sourceKeywordFormArray: FormArray<FormControl<IptcArrayItem<string>>>) {
    const targetFormGroups = this.selectionCount > 0 ? this.selectedIptcDatasetFormGroups : this.iptcDatasetFormGroups
    this.cloneKeywordsToRecords(targetFormGroups, sourceKeywordFormArray)
  }

  cloneKeywordsToRecords(recordFormGroups: FormGroup<IptcDatasetFormGroupType>[], sourceKeywordFormArray: FormArray<FormControl<IptcArrayItem<string>>>) {
    recordFormGroups.forEach(recordFormGroup => {
      const targetKeywordsFormArray = recordFormGroup.get('keywords') as FormArray<FormControl<IptcArrayItem<string>>>

      // add (or restore) controls controls that are in source but not in target
      // delete controls that are in removed source
      sourceKeywordFormArray.controls.forEach(sourceKeywordControl => {
        const sourceKeywordIptcValue = sourceKeywordControl.value as IptcArrayItem<string>
        const sourceKeyword = this.getIptcEffectiveValue(sourceKeywordIptcValue) ?? sourceKeywordIptcValue.generated

        if (sourceKeywordIptcValue.isManuallyRemoved || sourceKeywordIptcValue.markedForRemoval) {
          const targetControlsForKeyword = this.getControlsForKeyword(targetKeywordsFormArray, sourceKeyword)
          targetControlsForKeyword.forEach(keywordControl => this.removeKeywordControl(keywordControl)
          )
        } else {
          this.cloneKeywordIptcValue(sourceKeyword, targetKeywordsFormArray)
        }
      })

      // delete target controls that are not in source
      targetKeywordsFormArray.controls.forEach(targetKeywordControl => {
        const targetKeyword = this.getIptcEffectiveValue(targetKeywordControl.value)

        const sourceControlsForTargetKeyword = this.getControlsForKeyword(sourceKeywordFormArray, targetKeyword)
        if (sourceControlsForTargetKeyword.length === 0) {
          this.removeKeywordControl(targetKeywordControl)
        }
      })
    })
  }

  removeManuallyAddedIptcArrayItem(control: AbstractControl<IptcArrayItem<string>>) {
    if (control.value.newlyAdded) {
      const parent = control.parent
      if (parent instanceof FormArray) {
        parent.removeAt(parent.controls.indexOf(control))
      } else {
        throw new Error("Can only remove controls inside a FormArray")
      }
    } else {
      control.setValue(IptcArrayItem.itemMarkedForRemoval(control.value))
      control.markAsDirty()
    }
  }

  removeGeneratedIptcArrayItem(control: AbstractControl<IptcArrayItem<string>>) {
    control.setValue(new IptcArrayItem({generated: control.value.generated, value: null}))
    control.markAsDirty()
  }

  restoreRemovedGeneratedIptcArrayItem(control: AbstractControl<IptcArrayItem<string>>) {
    control.setValue(new IptcArrayItem({generated: control.value.generated, value: undefined}))
    control.markAsDirty()
  }

  restoreRemovedManualIptcArrayItem(control: AbstractControl<IptcArrayItem<string>>) {
    control.setValue(IptcArrayItem.itemMarkedForRestauration(control.value))
    control.markAsDirty()
  }

  keywordsSetManually(rowData: TableIptcEditorImageRow): boolean {
    return rowData.iptcDatasetFormGroup.get("_keywords_override").value
  }

  getUpdates(): Partial<IPTCDataset>[] {
    return this.iptcDatasetFormGroups.map(formGroup => Object.keys(formGroup.controls).reduce(
      (recordUpdates, key) =>
        formGroup.controls[key].dirty ? {
          ...recordUpdates,
          [key]: formGroup.controls[key].value
        } : recordUpdates, {id: formGroup.value.id} as Partial<IPTCDataset>
    ))
  }

  trackBy: TrackByFunction<TableIptcEditorImageRow> = ((index, item) => item.imageId)

  // template type inference helper
  toIptcControl(item: unknown) { return item as IptcFormFieldWrapperComponent }
  toKeywordFormControl(item: unknown) { return item as FormControl<IptcArrayItem<string>> }

}
