@if (showTitle) {
  <div class="section-title" matRipple [matRippleDisabled]="true">
    {{sectionTitle}}
    @if (hasRepeatableForm) {
      <button
        class="add-button"
        mat-icon-button
        (click)="add()"
        matTooltip="Eintrag hinzufügen"
        ><mat-icon>add</mat-icon></button>
      }
    </div>
  }

  @if (!hasRepeatableForm || numberOfItems > 0) {
    <mat-card appearance="outlined">
      <mat-card-content class="form-content">
        <ng-content></ng-content>
      </mat-card-content>
    </mat-card>
  } @else {
    <div class="no-entries">Keine Einträge</div>
  }

