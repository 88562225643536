import { COMMA, ENTER } from "@angular/cdk/keycodes"
import {
  AfterViewInit,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from "@angular/core"
import { AbstractControl, ControlValueAccessor, NgControl, UntypedFormControl, ValidatorFn } from "@angular/forms"
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete"
import { ErrorStateMatcher } from "@angular/material/core"
import { MatInput } from "@angular/material/input"
import { Observable, Subject } from "rxjs"
import { distinctUntilChanged, startWith, switchMap, takeUntil } from "rxjs/operators"
import { AuthService } from "../../../../services/auth.service"
import { VocabularyService } from "../../../../services/vocabulary.service"
import { Vocabulary, VocabularyEntry } from "../../../models"

import { VocabularyEditorDialogService } from "./vocabulary-editor-dialog.component"

@Directive({
    selector: "[inscVocabularySelectExtraSuffix]",
    standalone: false
})
export class VocabularySelectExtraSuffixDirective {}

@Directive()
export abstract class AbstractVocabularySelectComponent<T> implements ControlValueAccessor, ErrorStateMatcher, OnInit, OnChanges, AfterViewInit, OnDestroy {

  protected unsubscribe$ = new Subject<void>()

  @Input() vocabularyName: string
  @Input() label: string
  @Input() placeholder: string

  @Input() hasEditorialState = false

  @Input() @HostBinding("class.compact") compact = false

  @ViewChild(MatInput, { static: true }) entryInput: MatInput
  @ViewChild('entryInput', { static: true }) entryInputRef: ElementRef
  @ViewChild(MatAutocompleteTrigger, { static: true }) trigger: MatAutocompleteTrigger

  @ContentChild(VocabularySelectExtraSuffixDirective, {read: TemplateRef}) extraSuffixTpl: TemplateRef<unknown>

  separatorKeysCodes = [ENTER, COMMA]

  formControlValue: T

  inputCtrl = new UntypedFormControl()

  showEditButton: boolean

  protected entryValidatorFactory: (entries: VocabularyEntry[]) => ValidatorFn = null

  private vocabularyNameSubject = new Subject<string>()
  protected vocabulary$: Observable<Vocabulary>

  get editorialStateFormControl(): UntypedFormControl {
    if (!this.ngControl || !this.ngControl.control) {
      return null
    }

    const editorialStateControlName = `${this.ngControl.name}_editorial_state`
    const editorialStateControl = this.ngControl.control.parent.controls[editorialStateControlName] as AbstractControl

    if (!editorialStateControl) {
      throw new Error(`hasEditorialState is set but no control named ${editorialStateControlName} found in parent FormGroup`)
    }

    return editorialStateControl as UntypedFormControl
  }

  constructor(
    private viewContainerRef: ViewContainerRef,
    private vocabularyService: VocabularyService,
    public vocabularySelectDialog: VocabularyEditorDialogService,
    @Optional() @Self() public ngControl: NgControl,
    public auth: AuthService
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  ngOnInit(): void {
    this.auth.permission('update_vocabularies')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(hasEditPermission => this.showEditButton = hasEditPermission)

    this.vocabulary$ = this.vocabularyNameSubject.pipe(
      startWith(this.vocabularyName),
      distinctUntilChanged(),
      switchMap(vocabularyName => this.vocabularyService.getVocabulary(vocabularyName)),
      takeUntil(this.unsubscribe$),
    )

    this.vocabulary$.subscribe(vocabulary => {
      this.vocabularySelectAfterInit()
      if (this.entryValidatorFactory) {
        const entryValidator = this.entryValidatorFactory(vocabulary.entries)
        const validators = this.ngControl.validator ? [this.ngControl.validator, entryValidator] : [entryValidator]
        this.ngControl.control.setValidators(validators)
      }
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('vocabularyName' in changes) {
      this.vocabularyNameSubject.next(this.vocabularyName)
    }
  }

  vocabularySelectAfterInit(): void {}
  // noinspection JSUnusedLocalSymbols
  vocabularySelectAfterWriteValue(_value: T): void {}

  abstract selected(event: MatAutocompleteSelectedEvent): void

  ngAfterViewInit(): void {
    setTimeout(() => this.entryInput.errorStateMatcher = this)
  }


  openEditor(event: Event): void {
    event.stopPropagation()
    this.trigger.closePanel()
    this.vocabularySelectDialog.open(this.vocabulary$, this.viewContainerRef)
  }

  // noinspection JSUnusedLocalSymbols
  propagateChange: (_: T) => void = (_: T) => {}

  writeValue(value: T): void {
    this.formControlValue = value
    this.vocabularySelectAfterWriteValue(value)
  }

  registerOnChange(fn: (_: T) => void): void {
    this.propagateChange = fn
  }

  registerOnTouched(): void {}

  isErrorState(): boolean {
    return !this.ngControl.valid && !this.ngControl.disabled
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.inputCtrl.disable() : this.inputCtrl.enable()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
