import { Component } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { LiteratureReference } from "../../../../../shared/models/literature-reference.model"

import { SubformComponent } from "../../../../../shared/subform.component"

@Component({
    selector: 'insc-literaturereference-form',
    templateUrl: 'literaturereference-form.component.html',
    styles: [`
        .mat-mdc-form-field {
            min-width: 15em;
            width: 15em;
        }
    `],
    standalone: false
})
export class LiteratureReferenceFormComponent extends SubformComponent {

  static buildFormGroup(reference: Partial<LiteratureReference>, destroyable = true) {
    return new UntypedFormGroup({
      id:                  new UntypedFormControl(reference?.id || null),
      ref_type:            new UntypedFormControl(reference?.ref_type || ""),
      reference:           new UntypedFormControl(reference?.reference || ""),
      literature_entry:    new UntypedFormControl(reference?.literature_entry),
      urn:                 new UntypedFormControl(reference?.url),
      url:                 new UntypedFormControl(reference?.urn),
      ...this.destroyableControl(reference?.id, destroyable)
    })
  }

}
