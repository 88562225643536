<a class="link-overlay"  routerLink="/objects/{{result.id}}" (click)="launchRipple($event)"></a>
<mat-card matRipple [appearance]="['variant6'].includes(layoutVariant) ? 'outlined' : 'raised'" class="content-container">
<!--  @if (["variant10"].includes(layoutVariant)) {-->
<!--    <h2 class="title" style="text-align: start;">-->
<!--      <span [innerHtml]="highlight.name || result.name"></span>-->
<!--      @if (result['object_group']) {-->
<!--        <span class="object-group" [innerHtml]="', ' + (highlight['object_group.name'] || result['object_group'].name)"></span>-->
<!--      }-->
<!--    </h2>-->

<!--    &lt;!&ndash;      <div class="text-overlay">&ndash;&gt;-->
<!--      &lt;!&ndash;        &ndash;&gt;-->
<!--      &lt;!&ndash;        <div style="display: flex; align-items: start;" class="subtitle">&ndash;&gt;-->
<!--      &lt;!&ndash;          @if (result['current_locationing']?.location?.city) {&ndash;&gt;-->
<!--      &lt;!&ndash;            <div><span class="city" [innerHtml]="getAnnotatedLocationName(highlight['current_locationing']?.location?.city) || getAnnotatedLocationName(result['current_locationing']?.location?.city)"></span></div>&ndash;&gt;-->
<!--      &lt;!&ndash;          }&ndash;&gt;-->
<!--      &lt;!&ndash;          @if (!result['current_locationing']?.location?.city) {&ndash;&gt;-->
<!--      &lt;!&ndash;            <div style="font-style: italic;">z. Zt. kein Standort zugeordnet</div>&ndash;&gt;-->
<!--      &lt;!&ndash;          }&ndash;&gt;-->
<!--      &lt;!&ndash;          @if (result['datings'].length > 0) {&ndash;&gt;-->
<!--      &lt;!&ndash;            @if (result['current_locationing']?.location?.city) {&ndash;&gt;-->
<!--      &lt;!&ndash;              <span>, </span>&ndash;&gt;-->
<!--      &lt;!&ndash;            }&ndash;&gt;-->
<!--      &lt;!&ndash;            <div><span class="city">{{result['datings'][0].description}}</span></div>&ndash;&gt;-->
<!--      &lt;!&ndash;          }&ndash;&gt;-->
<!--      &lt;!&ndash;        </div>&ndash;&gt;-->
<!--      &lt;!&ndash;      </div>&ndash;&gt;-->
<!--  }-->


  <div class="content">

    <div class="image-wrapper">
      @if (result.images[0]) {
        <insc-image-tile class="image" [image]="result.images[0]">
         @if (["variant1", "variant2", "variant3"].includes(layoutVariant)) {
           <div class="text-overlay">
             <h2 class="title">
               <span [innerHtml]="highlight.name || result.name"></span>
               @if (result['object_group']) {
                 <span class="object-group" [innerHtml]="', ' + (highlight['object_group.name'] || result['object_group'].name)"></span>
               }
             </h2>

             <div style="display: flex; align-items: start;" class="subtitle">
               @if (result['current_locationing']?.location?.city) {
                 <div><span class="city" [innerHtml]="getAnnotatedLocationName(highlight['current_locationing']?.location?.city) || getAnnotatedLocationName(result['current_locationing']?.location?.city)"></span></div>
               }
               @if (!result['current_locationing']?.location?.city) {
                 <div style="font-style: italic;">z. Zt. kein Standort zugeordnet</div>
               }
               @if (result['datings'].length > 0) {
                 @if (result['current_locationing']?.location?.city) {
                   <span>, </span>
                 }
                 <div><span class="city">{{result['datings'][0].description}}</span></div>
               }
             </div>
           </div>
         }
        </insc-image-tile>
<!--        <img class="image"  [src]="result.images[0]['preview_url']">-->
      } @else {
        <div class="image-missing"></div>
      }
    </div>
    <div class="content-wrapper">
     @if (["variant4", "variant5", "variant6", "variant7", "variant8", "variant9", "variant10", "variant11"].includes(layoutVariant)) {
       <div class="text-wrapper">
         <h2 class="title">
           <span [innerHtml]="highlight.name || result.name"></span>
           @if (result['object_group']) {
             <span class="object-group" [innerHtml]="', ' + (highlight['object_group.name'] || result['object_group'].name)"></span>
           }
         </h2>

         @if (result['current_locationing']?.location?.city) {
           <div><span class="city" [innerHtml]="getAnnotatedLocationName(highlight['current_locationing']?.location?.city) || getAnnotatedLocationName(result['current_locationing']?.location?.city)"></span></div>
         }
         @if (!result['current_locationing']?.location?.city) {
           <div style="font-style: italic;">z. Zt. kein Standort zugeordnet</div>
         }
         @if (result['datings'].length > 0) {
           <div><span class="city">{{result['datings'][0].description}}</span></div>
         }
       </div>
     }
      @if (result['inscriptions']?.length > 0) {
        <div class="inscriptions-wrapper" >
          <insc-result-card-link-list title="Inschriften">
            @for (inscription of result['inscriptions']; track inscription; let i = $index) {
              <insc-result-card-link-list-entry [link]="['/objects', result.id, 'inscriptions', inscription.id]">
                {{ inscription.name }}
              </insc-result-card-link-list-entry>
            }
          </insc-result-card-link-list>
        </div>
      }
    </div>
  </div>
</mat-card>
