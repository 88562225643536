import { Component, ViewChild } from "@angular/core"
import { from } from "rxjs"
import { concatMap, map, toArray } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { ImageService } from "../../../services/data.service"
import { ImageDownloadService } from "../../../services/image-download.service"
import { ImageViewerService } from "../../../shared/components/image-viewer/image-viewer.service"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"
import { InscImage } from "../../../shared/models/image.model"
import { SnackbarService } from "../../../shared/snackbars/snackbar.service"
import { MultiImageEditorComponent } from "../image-metadata-editors/multi-image-editor/multi-image-editor.component"
import { IPTCDataset } from "../image-metadata-editors/multi-iptc-editor/iptc-dataset.service"
import { MultiIptcEditorComponent } from "../image-metadata-editors/multi-iptc-editor/multi-iptc-editor.component"
import { TableIptcEditorDialogService } from "../image-metadata-editors/table-iptc-editor/table-iptc-editor-dialog.service"
import { ImageMetadataPickerDialogService } from "../image-metadata-picker-dialog/image-metadata-picker-dialog.service"
import { ImageFileMetadataViewDialogService } from "../shared/components/image-file-metadata-view/image-file-metadata-view-dialog.component"
import { ImageSidebarService } from "./image-sidebar.service"

@Component({
    selector: 'insc-image-sidebar',
    templateUrl: './image-sidebar.component.html',
    styleUrls: ['./image-sidebar.component.scss'],
    standalone: false
})
export class ImageSidebarComponent {

  @ViewChild(MultiImageEditorComponent) multiImageView: MultiImageEditorComponent
  @ViewChild(MultiIptcEditorComponent) multiIPTCEditor: MultiIptcEditorComponent

  imagesPluralMapping = {"=0": "Aufnahme", "=1": "Aufnahme", "other": "Aufnahmen"}
  images$ = this.imageSidebar.images$
  imageParent$ = this.imageSidebar.parent$

  editMode$ = this.imageSidebar.editMode$
  configuration$ = this.imageSidebar.configuration$

  canEdit$ = this.auth.permission('update_records')

  constructor(
    readonly imageSidebar: ImageSidebarService,
    private imageService: ImageService,
    private reusableDialogs: ReusableDialogsService,
    private snackbarService: SnackbarService,
    private tableIptcEditorDialogService: TableIptcEditorDialogService,
    private imageFileMetadataViewDialogService: ImageFileMetadataViewDialogService,
    readonly auth: AuthService,
    readonly imageViewer: ImageViewerService,
    readonly imageDownloadService: ImageDownloadService,
    readonly imageMetadataPickerDialogService: ImageMetadataPickerDialogService,
  ) { }

  save(updates: Partial<InscImage>[]): void {
    this.imageService.batchUpdate(updates).subscribe(
      updatedImages => this.imageSidebar.emitSaveAndUpdateView(updatedImages)
    )
  }

  onHasChangesChanged(hasChanges: boolean): void {
    this.imageSidebar.hasUnsavedChanges = hasChanges
  }

  setEditMode(editMode: boolean): void {
    this.imageSidebar.setEditMode(editMode)
  }

  cancel(): void {
    this.reusableDialogs.openUnsavedChangesDialogConfigWhen(
      () => this.imageSidebar.hasUnsavedChanges,
      () => {
        this.multiImageView.resetAll()
        this.multiIPTCEditor.resetAll()
        this.imageSidebar.setEditMode(false)
      }
    )
  }


  getTotalSize(images: Partial<InscImage>[]): number {
    return this.imageDownloadService.getTotalSize(images)
  }

  delete(images: Partial<InscImage>[]): void {
    this.reusableDialogs.openConfirmationDialog(
      () => {
        from(images).pipe(
          concatMap(image => this.imageService.delete(image.id).pipe(
            map(_ => image)
          )),
          toArray()
        ).subscribe(deletedImages => {
          const updatedImages = images.filter(image => !deletedImages.some(deletedImage => deletedImage.id === image.id))
          this.imageSidebar.emitDeleteAndUpdateView(updatedImages, deletedImages)
          this.snackbarService.showSuccessWithMessage("Gelöscht")
        })
      },
      {
        message: images.length === 1 ?
          `Aufnahme '${images[0].name || images[0].id}' wirklich löschen?` :
          `Sollen die ${images.length} ausgewählten Aufnahmen wirklich gelöscht werden?`,
        yesAction: "LÖSCHEN",
        noAction: "BEHALTEN",
        icon: "delete"}
    )
  }

  openMetadataPicker(): void {
    this.imageMetadataPickerDialogService
      .open(this.multiImageView.multiRecordFormManager.getUpdatedRecords())
      .afterClosed().subscribe(result => result && this.multiImageView.multiRecordFormManager.updateWithValuesFrom(result.updatedValues))
  }

  getActivityItemIds(images: Pick<InscImage, 'id'>[]): string {
    return images.map(image => image.id).join(",")
  }

  openTableIptcEditor() {
    this.tableIptcEditorDialogService
      .open({iptcDatasets: this.multiIPTCEditor.multiRecordFormManager.getUpdatedRecords() as IPTCDataset[]})
      .afterClosed().subscribe(result => result && this.multiIPTCEditor.multiRecordFormManager.updateRecordsFrom(result))
  }

  openFileMetadata(image: InscImage) {
    this.imageFileMetadataViewDialogService.open({
      imageTitle: image.name,
      filename: image.image_file.filename,
      metadata$: this.imageService.metadata(image.id)
    })
  }
}
