import { Component } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { SubformComponent } from "../../../../shared/subform.component"


@Component({
    selector: 'insc-origin-form',
    templateUrl: 'origin-form.component.html',
    styles: [`
           :host {
             display: flex;
             flex-direction: column;
           }
           `],
    standalone: false
})
export class OriginFormComponent extends SubformComponent {

  static buildFormGroup(origin, destroyable = true) {
    return new UntypedFormGroup({
      id:               new UntypedFormControl(origin.id || null),
      // address:          LocationFormComponent.buildFormGroup(origin.address || {}),
      specification:    new UntypedFormControl(origin.specification || null),
      based_on:         new UntypedFormControl(origin.based_on || ''),
      comment:          new UntypedFormControl(origin.comment || ''),
      ...this.destroyableControl(origin.id, destroyable)
    })
  }


}
