import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"

import { Observable } from "rxjs"
import { map } from "rxjs/operators"

import { environment } from "../../environments/environment"


import { DateRangeModifier, DatingSpecification } from "../shared/models"


export type RangeDescriptionType = "day" | "century" | "year" | "around"

export interface RangeDescription {
  type: RangeDescriptionType
  desc: string
  from: string
  to: string
}

export function isRangeDescription(arg: unknown): arg is RangeDescription {
  return arg !== null && typeof arg === "object" &&
    ("type" in arg &&
    (arg as any).type === "day" || (arg as any).type === "century" ||
    (arg as any).type === "year" || (arg as any).type === "around" &&
    "desc" in arg &&
    "from" in arg &&
    "to" in arg)
}

@Injectable({providedIn: 'root'})
export class DateRangeService {

  readonly baseUrl = environment.apiUrl

  constructor(private http: HttpClient) {
  }

  getMatchingRanges(rangeQuery: string, rangeModifier: DateRangeModifier = "closed"): Observable<RangeDescription[]> {
    return this.http.get<{ ranges: RangeDescription[] }>(`${this.baseUrl}/date_ranges?q=${rangeQuery}&range_modifier=${rangeModifier}`).pipe(
      map(result => result.ranges)
    )
  }

  getSpecification(primary: string, secondary: string, rangeModifier: DateRangeModifier = "closed"): Observable<DatingSpecification | null> {
    return this.http.get<{dating_spec: DatingSpecification}>(`${this.baseUrl}/dating_spec?primary=${primary}&secondary=${secondary ? secondary : ""}&range_modifier=${rangeModifier}`)
      .pipe(map(result => result.dating_spec))
  }
}
