import { Component, forwardRef, OnDestroy, OnInit } from "@angular/core"
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from "@angular/forms"
import { MatError } from "@angular/material/form-field"
import { MatRadioModule } from "@angular/material/radio"
import equal from "fast-deep-equal"
import { Subject } from "rxjs"
import { debounceTime, takeUntil } from "rxjs/operators"
import { RecordingDate, RecordingDateSpec } from "../../../models/image.model"
import { DateRangePickerModule } from "../date-range-picker/date-range-picker.module"

type RecordingDateFormValue = {
  type: string
  primary: RecordingDateSpec | null
  secondary: RecordingDateSpec | null
}

@Component({
    selector: "insc-recording-date-picker",
    templateUrl: "./recording-date-picker.component.html",
    styleUrls: ["./recording-date-picker.component.scss"],
    imports: [
        ReactiveFormsModule,
        MatRadioModule,
        DateRangePickerModule,
        MatError
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => RecordingDatePickerComponent),
            multi: true
        }
    ]
})
export class RecordingDatePickerComponent implements OnInit, OnDestroy, ControlValueAccessor {

  private unsubscribe$ = new Subject<void>()


  INITIAL_VALUE: RecordingDateFormValue = {
    type: "single",
    primary: null,
    secondary: null
  }

  recordingDateForm = new FormGroup({
    type: new FormControl("single"),
    primary: new FormControl<RecordingDateSpec | null>(null, { validators: Validators.required }),
    secondary: new FormControl<RecordingDateSpec | null>(null)
  }, {
    validators: [
      (formGroup: FormGroup) => {
        if (formGroup.value.type === "range" && !formGroup.value.secondary) {
          return {
            secondaryRequired: true
          }
        }
      }
    ]
  })

  constructor() { }

  propagateChange = (_recordingDate: RecordingDate) => {}

  ngOnInit(): void {
    this.recordingDateForm.valueChanges.pipe(
      debounceTime(0),
      takeUntil(this.unsubscribe$)
    ).subscribe(value => {
      const { primary, secondary } = value
      if (this.recordingDateForm.invalid || !primary ||  (value.type === "range" && !secondary)) {
        this.propagateChange(null)
      } else {
        const recordingDate = value["type"] === "single"
          ? {earliest: primary, latest: primary }
          : { earliest: primary, latest: secondary}

        this.propagateChange(recordingDate)
      }
    })

  }

  registerOnChange(fn: (recordingDate: RecordingDate) => void): void {
    this.propagateChange = fn
  }

  registerOnTouched(fn: any): void { }

  writeValue(recordingDate: RecordingDate): void {
    if (!recordingDate) {
      this.recordingDateForm.setValue(this.INITIAL_VALUE, {emitEvent: false, onlySelf: true})
    } else {
      if (equal(recordingDate.earliest, recordingDate.latest)) {
        this.recordingDateForm.setValue({type: "single", primary: recordingDate.earliest, secondary: null}, {emitEvent: false, onlySelf: true})
      } else {
        this.recordingDateForm.setValue({type: "range", primary: recordingDate.earliest, secondary: recordingDate.latest}, {emitEvent: false, onlySelf: true})
      }
    }

    this.recordingDateForm.updateValueAndValidity()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
