import { Component, ContentChild, Directive, forwardRef, HostBinding, Input, TemplateRef } from "@angular/core"
import { NG_VALUE_ACCESSOR } from "@angular/forms"
import { InscEntity } from "../../../../../shared/models/entity.model"
import { MultiRecordFormControl } from "../multi-record-form-manager"

@Directive({
    selector: "[inscMultiFieldReadonlyView]",
    standalone: false
})
export class MultiFieldReadonlyViewDirective {
  constructor() {}
}

@Component({
    selector: 'insc-multi-field',
    templateUrl: './multi-field.component.html',
    styleUrls: ['./multi-field.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiFieldComponent),
            multi: true
        }],
    standalone: false
})
export class MultiFieldComponent<T extends Partial<InscEntity> = Partial<InscEntity>, K extends keyof T = keyof T> {

  @ContentChild(MultiFieldReadonlyViewDirective, {read: TemplateRef}) readonlyViewTpl: TemplateRef<{placeholder: string}>

  @Input() multiRecordFormControl: MultiRecordFormControl
  @Input() editable = false

  @Input() getDisplayValue: (value: any) => string = (value) => value
  get displayValue() {
    return this.getDisplayValue(this.multiRecordFormControl.abstractControl.value) || null
  }

  @Input() fieldAccessoryTpl: TemplateRef<any>
  get fieldAccessoryContext() {
    return null
  }

  // TODO: ??
  @HostBinding('class.insc-multi-field') inscMultiField = true


  constructor() {}


}
