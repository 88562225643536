import { Component, OnDestroy, OnInit } from "@angular/core"
import { FormControl, FormGroup, Validators } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { combineLatest, Observable, Subject } from "rxjs"
import { takeUntil, tap } from "rxjs/operators"
import { AuthService } from "../../../../services/auth.service"
import { APIError, ValidationError } from "../../../../services/errors"
import { FormComponent } from "../../../../services/form-leave-guard.service"
import { FormService } from "../../../../services/form.service"
import { UserService } from "../../../../services/user.service"
import { User } from "../../../../shared/models/user.model"
import { SnackbarService } from "../../../../shared/snackbars/snackbar.service"


export interface UserEditorRouteData {
  user?: User
  accountPage?: boolean
}

@Component({
    selector: 'insc-user-editor',
    templateUrl: './user-editor.component.html',
    styleUrls: ['./user-editor.component.scss'],
    standalone: false
})
export class UserEditorComponent implements OnInit, FormComponent, OnDestroy {

  private unsubscribe$ = new Subject<void>()
  private selfChangeableFields = ["email", "password", "password_confirmation", "first_name", "last_name"]

  userCanSave = false

  permissionControls = {
    manage_users: new FormControl(false, {nonNullable: true}),
    update_records: new FormControl(false, {nonNullable: true}),
    update_vocabularies: new FormControl(false, {nonNullable: true}),
    update_knowledge: new FormControl(true, {nonNullable: true}),
    approval: new FormControl(false, {nonNullable: true})
  }

  form = new FormGroup({
    id: new FormControl('', {nonNullable: true}),
    name: new FormControl('',  {nonNullable: true, validators: Validators.required}),
    first_name: new FormControl('', {nonNullable: true}),
    last_name: new FormControl('', {nonNullable: true}),
    email: new FormControl('',  {nonNullable: true, validators: [Validators.required, Validators.email]}),
    password: new FormControl(''),
    password_confirmation: new FormControl(''),
    permissions: new FormGroup({ ...this.permissionControls }),
    disabled: new FormControl(false, {nonNullable: true})
  })

  ownAccountPage = false

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbarService: SnackbarService,
    private formService: FormService,
    private auth: AuthService
  ) { }

  ngOnInit(): void {
    const routeData = this.route.data as Observable<UserEditorRouteData>

    routeData.subscribe(data => {
      if (data && data.user) {
        this.form.patchValue(data.user)
        this.ownAccountPage = data.accountPage
      } else {
        this.form.reset()
      }
      this.form.markAsPristine()
    })

    combineLatest([routeData, this.auth.currentUser])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([data, currentUser]) => {

        const hasManageUserPermission = this.auth.hasPermission("manage_users")
        const isOwnAccount = (data.user && data.user.id === currentUser.id)

        if (!data || !data.user) {
          if (hasManageUserPermission) {
            this.form.enable()
            this.userCanSave = true
          } else {
            this.form.disable()
            this.userCanSave = false
          }
          return
        }

        if (hasManageUserPermission) {
          this.form.enable()
          this.userCanSave = true
        } else {
          this.form.disable()
          if (isOwnAccount) {
            this.userCanSave = true
            this.selfChangeableFields.forEach(fieldName => this.form.controls[fieldName].enable())
          } else {
            this.userCanSave = false
          }
        }

        if (data.user.disabled) {
          this.form.disable()
        }
      })
  }

  private handleUserUpdate(userResult: User, snackbarMessage: string) {
    this.form?.reset(userResult)
    this.snackbarService.showSuccessWithMessage(snackbarMessage)
    if (!this.ownAccountPage) {
      void this.router.navigate(["/admin/users"])
    }
  }


  save(): void {
    this.userService.save(this.form.getRawValue() as User).pipe(
      tap(() => this.form.markAsPristine()),
    ).subscribe({
      next: (result) => this.handleUserUpdate(result, `Benutzer ${result.name} gespeichert.`),
      error: (error: unknown) => {
        console.log(error)
        if (error instanceof APIError) {
          this.snackbarService.showApiError(error)
        }
        if (error instanceof ValidationError) {
          this.formService.applyValidationErrors(this.form, error.field_errors)
        }

      }
    })
  }

  disable(): void {
    this.userService.disable(this.form.value["id"]).subscribe(
      (user) => this.handleUserUpdate(user, `Benutzer ${user.name} deaktiviert.`)
    )
  }

  enable(): void {
    this.userService.enable(this.form.value["id"]).subscribe(
      (user) => this.handleUserUpdate(user, `Benutzer ${user.name} aktiviert.`)
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
