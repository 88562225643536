import { Component, Input, OnInit } from "@angular/core"
import { Origin } from "../../../../shared/models"


@Component({
    selector: 'insc-origin-view',
    templateUrl: './origin-view.component.html',
    styleUrls: ['./origin-view.component.css'],
    standalone: false
})
export class OriginViewComponent implements OnInit {
  @Input() origin: Origin

  constructor() { }

  ngOnInit() {
  }

}
