import { ListRange } from "@angular/cdk/collections"
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling"
import { Component, Directive, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core"
import { MatTab, MatTabGroup } from "@angular/material/tabs"
import { ActivatedRoute, NavigationStart, Router } from "@angular/router"
import { combineLatest, Observable, of, Subscription } from "rxjs"
import { distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from "rxjs/operators"
import { AuthService } from "../../services/auth.service"
import { QueryParams, QueryResult } from "../../services/data.service"
import { VocabularyEntryAssociatedRecordType, VocabularyService } from "../../services/vocabulary.service"
import { InscEntity } from "../../shared/models/entity.model"
import { InscImage } from "../../shared/models/image.model"
import { Inscription } from "../../shared/models/inscription.model"
import { InscObject } from "../../shared/models/object.model"
import { VocabularyEntry } from "../../shared/models/vocabulary-entry.model"
import { Vocabulary } from "../../shared/models/vocabulary.model"
import { VirtualScrollSearchDataSource } from "../../shared/search/virtual-scroll-search.data-source"
import { ImageManagementDirective, ImageSelection } from "../images/image-management/image-management.directive"
import { ImageSidebarService } from "../images/image-sidebar/image-sidebar.service"


type AssociatedRecordScrollViewportId = "objects" | "inscriptions" | "images"

@Directive({
    selector: 'cdk-virtual-scroll-viewport[inscAssociatedRecordScrollViewport]',
    standalone: false
})
export class AssociatedRecordScrollViewportDirective {
  @Input() inscAssociatedRecordScrollViewport: AssociatedRecordScrollViewportId

  constructor(readonly viewport: CdkVirtualScrollViewport) {}
}

@Component({
    selector: "insc-vocabulary-editor-host",
    templateUrl: "vocabulary-editor-host.component.html",
    styleUrls: ["vocabulary-editor-host.component.scss"],
    standalone: false
})
export class VocabularyEditorHostComponent implements OnInit, OnDestroy {

  protected vocabulary$ = this.route.paramMap.pipe(
    map(paramMap => paramMap.get("vocabulary_name")),
    distinctUntilChanged(),
    switchMap(vocabularyName => this.vocabularyService.getVocabulary(vocabularyName))
  )
  protected selectedId$ = this.route.paramMap.pipe(
    map(paramMap => paramMap.get("entry_id"))
  )


  protected objectDataSource$ = this.createDataSource$("insc_objects", () => this.inscObjectViewport?.getRenderedRange())
  protected inscriptionDataSource$ = this.createDataSource$("inscriptions", () => this.inscriptionViewport?.getRenderedRange())
  protected imageDataSource$ = this.createDataSource$("images", () => this.imageViewport?.getRenderedRange())

  protected entryInscImages$: Observable<InscImage[]> = this.imageDataSource$.pipe(
    switchMap(dataSource => dataSource?.searchResults$ ?? of(null)),
    map(searchResults => searchResults?.results?.map(
      result => result.data
    ) ?? []),
  )

  protected numObjects$ = this.createResultCount$(this.objectDataSource$, () => this.inscObjectViewport)
  protected numInscriptions$ = this.createResultCount$(this.inscriptionDataSource$, () => this.inscriptionViewport)
  protected numImages$ = this.createResultCount$(this.imageDataSource$, () => this.imageViewport)

  hasEditPermission = this.auth.permission('update_vocabularies')

  @ViewChildren(AssociatedRecordScrollViewportDirective) viewportDirectives: QueryList<AssociatedRecordScrollViewportDirective>
  get inscObjectViewport() { return this.getViewport("objects") }
  get inscriptionViewport() { return this.getViewport("inscriptions") }
  get imageViewport() { return this.getViewport("images") }

  @ViewChild("associatedRecordTabGroup") associatedRecordsTabGroup: MatTabGroup
  @ViewChildren("associatedRecordTab") associatedRecordTabs: QueryList<MatTab>

  private routerSubscription: Subscription

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private vocabularyService: VocabularyService,
    protected imageSidebarService: ImageSidebarService
  ) { }

  ngOnInit() {

    // deselect images and hide image sidebar on navigation
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe(() => this.imageSidebarService.unsetImages())
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe()
  }


  protected onSelectedEntryChange(selectedId: VocabularyEntry["id"]) {
    this.router.navigate([this.route.snapshot.paramMap.get("vocabulary_name"), selectedId], { relativeTo: this.route.parent }).then(
      () => this.imageSidebarService.unsetImages()
    )
  }

  protected onEntryInscImageClick(event: MouseEvent, imageManagement: ImageManagementDirective, id: InscImage["id"]) {
    if (event.metaKey || event.ctrlKey) {
      imageManagement.toggle(id)
    } else {
      imageManagement.exclusivelyToggle(id)
    }
  }

  protected onEntryImageSelectionChange(imageSelection: ImageSelection[], images: Partial<InscImage>[]) {
    this.imageSidebarService.setImages(
      images.filter(
        image => imageSelection.some(
          selectedImageId => image.id === selectedImageId)
      ), {enableCloseButton: true, enableDelete: false}
    )
  }

  protected getEntryName(voabulary: Vocabulary, entryId: VocabularyEntry["id"]) {
    return voabulary.entries.find(entry => entry.id == entryId)?.name
  }

  protected getObjectRouterLink(result: QueryResult<Partial<InscObject>>) {
    return ['/objects', result.id]
  }

  protected getInscriptionRouterLink(result: QueryResult<Partial<Inscription>>) {
    return ['/objects/', result.data.insc_objects[0]?.id, 'inscriptions', result?.id]
  }

  protected getTabIndicatorStyle() {
    if (!this.associatedRecordsTabGroup) {
      return {}
    }
    const selectedTabIndex = this.associatedRecordsTabGroup.selectedIndex
    const selectedTab = this.associatedRecordTabs.get(selectedTabIndex)
    return selectedTab.disabled ? {
      '--mdc-tab-indicator-active-indicator-color': "rgba(0, 0, 0, 0)",
      '--mat-tab-header-active-label-text-color': "var(--mat-tab-header-inactive-label-text-color)"
    } : {}
  }

  private createDataSource$<T extends "insc_objects" | "inscriptions" | "images">(associatedRecordsType: T, renderedRangeFn: () => ListRange) {
    return combineLatest([this.vocabulary$, this.selectedId$]).pipe(
      map(([vocabulary, entry_id]) => {
        if (entry_id) {
          return new VirtualScrollSearchDataSource<VocabularyEntryAssociatedRecordType<T>>(
            (queryParams) =>
              this.vocabularyService.associatedRecords(vocabulary.name, entry_id, associatedRecordsType, queryParams),
            of<QueryParams>({}),
            renderedRangeFn
          )
        } else {return null}
      }),
      shareReplay()
    )
  }

  private createResultCount$(dataSource$: Observable<VirtualScrollSearchDataSource<InscEntity>>, getViewport: () => CdkVirtualScrollViewport): Observable<number> {
    return dataSource$.pipe(
      switchMap(dataSource => dataSource?.searchResults$ ?? of(null)),
      map(searchResults => searchResults?.count || 0),
      tap(() => setTimeout(() => getViewport()?.checkViewportSize())),
      tap(() => setTimeout(() => this.adjustSelectedTab()))
    )
  }

  private adjustSelectedTab() {
    if (!this.associatedRecordsTabGroup) {
      return
    }

    const selectedTabIndex = this.associatedRecordsTabGroup.selectedIndex
    if (this.associatedRecordTabs.get(selectedTabIndex).disabled) {
      const firstEnabledTab = this.associatedRecordTabs.find(tab => tab.disabled !== true)
      if (firstEnabledTab) {
        this.associatedRecordsTabGroup.selectedIndex = firstEnabledTab.position
      }
    }
  }

  private getViewport(id: AssociatedRecordScrollViewportId): CdkVirtualScrollViewport {
    return this.viewportDirectives
      .find(directive => directive.inscAssociatedRecordScrollViewport === id)
      ?.viewport
  }
}
