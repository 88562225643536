import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from "@angular/core"
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { MatDatepickerInputEvent } from "@angular/material/datepicker"
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field"
import { MatInput } from "@angular/material/input"
import moment from "moment"
import { Observable, Subject } from "rxjs"
import { startWith, takeUntil } from "rxjs/operators"
import { InscImage } from "../../../../shared/models/image.model"
import {
  CompareFn,
  MultiRecordFormArray,
  MultiRecordFormControl,
  MultiRecordFormManager,
  ValueWrapper
} from "../shared/multi-record-form-manager"
import { TableIptcEditorDialogService } from "../table-iptc-editor/table-iptc-editor-dialog.service"
import { IptcArrayItem, IPTCDataset, IptcDatasetService, IptcValue } from "./iptc-dataset.service"
import { IptcFormFieldValueState } from "./iptc-form-field-wrapper-component/iptc-form-field-wrapper.component"

export interface KeywordState {
  hasManualKeywords: RecordKeywordQuantifier
  hasRemovedGeneratedKeywords: RecordKeywordQuantifier
  hasUntouchedGeneratedKeywords: RecordKeywordQuantifier,
  isMarkedForRemoval: boolean
  isMarkedForRestoration: boolean
  isNew: boolean,
  control: AbstractControl<IptcArrayItem>
}

type RecordKeywordQuantifier = "none" | "all" | "some" | "one"

@Component({
    selector: 'insc-multi-iptc-editor',
    templateUrl: './multi-iptc-editor.component.html',
    styleUrls: ['./multi-iptc-editor.component.scss'],
    viewProviders: [
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: "dynamic" } },
        // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    ],
    providers: [
    // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    ],
    standalone: false
})
export class MultiIptcEditorComponent implements OnChanges, OnDestroy {

  @HostBinding("class.insc-dense-input") classDenseInput = true

  @Input() images: Partial<InscImage>[]
  @Input() editable = true

  @ViewChildren("newKeywordInput", {read: MatInput}) newKeywordInputs: QueryList<MatInput>
  @ViewChildren("newKeywordInput", {read: ElementRef}) newKeywordElementRefs: QueryList<ElementRef<HTMLInputElement>>

  formGroup: UntypedFormGroup
  multiRecordFormManager: MultiRecordFormManager

  private unsubscribe$ = new Subject<void>()

  keywordIconTooltipStrings: Record<
    "untouchedGeneratedIcon" | "manualIcon" | "removedManualIcon" | "removedGeneratedIcon",
    Record<Extract<RecordKeywordQuantifier, "one" | "some" | "all">, string>> = {
    untouchedGeneratedIcon: {
      "one": "Aus den Metadaten übernommenes Schlagwort.",
      "some": "Einige der gewählten Aufnahmen haben dieses Schlagwort aus den Metadaten übernommen.",
      "all": "Alle gewählten Aufnahmen haben dieses Schlagwort aus den Metadaten übernommen.",
    },
    removedGeneratedIcon: {
      "one": "Aus IPTC-Daten entfernt (aus Metadaten übernommen)",
      "some": "Bei einigen der gewählten Aufnahmen wird dieses aus den Metadaten übernommene Schlagwort nicht in die IPTC-Daten übernommen.",
      "all": "Bei allen gewählten Aufnahmen wird dieses aus den Metadaten übernommene Schlagwort nicht in die IPTC-Daten übernommen."
    },
    manualIcon: {
      "one": "Manuell hinzugefügtes Schlagwort.",
      "some": "Bei einigen der gewählten Aufnahmen wurde dieses Schlagwort manuell hinzugefügt.",
      "all": "Bei allen gewählten Aufnahmen wurde dieses Schlagwort manuell hinzugefügt."
    },
    removedManualIcon: {
      "one": "Manuell hinzugefügtes Schlagwort; wird beim Speichern aus den IPTC-Daten entfernt.",
      "some": "Bei einigen der gewählten Aufnahmen wurde dieses Schlagwort manuell hinzugefügt und wird aus deren IPTC-Daten beim spechern entfernt.",
      "all": "Bei allen gewählten Aufnahmen wurde dieses Schlagwort manuell hinzugefügt und wird aus deren IPTC-Daten beim spechern entfernt."
    }
  }

  keywordButtonTooltipStrings: Record<string, Record<"=1" | "other", string>> = {
    newlyAddedIcon: {
      "=1": "Neu hinzugefügtes Schlagwort.",
      "other": "Dieses Schlagwort wird den IPTC-Daten aller ausgewählten Aufnahmen hinzugefügt."
    },
    deleteButton: {
      "=1": "Schlagwort aus den IPTC-Daten entfernen.",
      "other": "Schlagwort aus den IPTC-Daten aller ausgewählten Aufnahmen entfernen."
    },
    restoreButton: {
      "=1": "Schlagwort wieder aufnehmen.",
      "other": "Schlagwort in die IPTC-Daten aller ausgewählten Aufnahmen wieder aufnehmen."
    },
    resetToOriginalButton: {
      "=1": "Ursprungszustand wiederherstellen.",
      "other": "Ursprungszustand dieses Schlagworts für alle ausgewählten Aufnahmen wiederherstellen."
    }
  }

  // TODO: why observable and not getter that returns this.formGroup.dirty?
  private _hasChanges = new Subject<boolean>()
  @Output() hasChanges: Observable<boolean> = this._hasChanges.asObservable().pipe(
    startWith(false)
  )

  private _changes = new Subject<Partial<InscImage>[]>()
  @Output() changes = this._changes.asObservable()

  private iptcValueCompareFn: CompareFn<IptcValue> = (a, b) =>
    this.iptcDatasetService.getIptcEffectiveValue(a) === this.iptcDatasetService.getIptcEffectiveValue(b)

  private iptcKeywordCompareFn: CompareFn<IptcValue<string>> = (a, b) =>
    this.iptcValueCompareFn(a, b) || (
      a.generated !== undefined && b.generated !== undefined && a.generated === b.generated
    ) || (
      a.value != null && a.value === b.generated ||
        b.value != null && b.value === a.generated
    )

  get dirty() { return this.multiRecordFormManager.dirty }

  constructor(
    readonly iptcDatasetService: IptcDatasetService,
    readonly tableIptcEditorDialogService: TableIptcEditorDialogService
  ) { }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  ngOnChanges(changes: SimpleChanges) {
    if ("images" in changes) {
      this.setImages()
    }
  }

  setImages() {

    this._hasChanges.next(false)

    const iptcDatasets: IPTCDataset[] = this.images
      .filter(image => {
        if (!image.iptc_defaults) {
          console.log(`ERROR: No IPTC defaults for image ${image.id}`)
          return false
        }
        return true
      })
      .map(image => this.iptcDatasetService.buildIptcDataset(image))

    if (!this.multiRecordFormManager) {
      const compareFn = this.iptcValueCompareFn
      const valueIsEmptyFn = this.iptcMultiValueIsEmpty
      this.multiRecordFormManager = new MultiRecordFormManager(iptcDatasets, {
        description:        {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        province_or_state:  {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        copyright_notice:   {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        credit_line:        {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        creator:            {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        country_code:       {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        country:            {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        rights_usage_terms: {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        city:               {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        sublocation:        {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        source:             {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        keywords:           {
          control: new UntypedFormArray([]),
          compareFn: this.iptcKeywordCompareFn,
          formGroupBuilder: (value) => new UntypedFormControl(value ?? new IptcArrayItem())
        },
        _keywords_override: {control: new UntypedFormControl()},
        title:              {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        headline:           {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        description_writer: {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        marked:             {control: new UntypedFormControl(), compareFn, valueIsEmptyFn},
        date_created:       {control: new UntypedFormControl(), compareFn, valueIsEmptyFn}
      })

      this.formGroup = this.multiRecordFormManager.formGroup
      this.formGroup.get("keywords").valueChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => this.multiRecordFormManager.updateWithValuesFrom({_keywords_override: true}))

      this.multiRecordFormManager.changed.pipe(
        takeUntil(this.unsubscribe$)
      ).subscribe(_ => {
        // https://github.com/angular/angular/issues/10887
        setTimeout(() => this._hasChanges.next(this.multiRecordFormManager.dirty))
        this._changes.next(this.getUpdates())
      })
    } else {
      this.multiRecordFormManager.resetWithRecords(iptcDatasets)
    }

    this.formGroup.markAsPristine()
  }

  getUpdates(): Partial<InscImage>[] {
    return this.multiRecordFormManager.getUpdatedRecords().map((updatedDataset: IPTCDataset) => {
      return {
        id: updatedDataset.id as unknown as string,
        iptc_overrides: this.iptcDatasetService.serializeIptcDatasetToIptcOverrides(updatedDataset)
      }
    })
  }

  hasKeywordOverrides(): "some" | "all" | "none" {
    const multiControl = this.getMultiControl("_keywords_override")
    const commonValue = multiControl.getCommonValue()
    if (commonValue instanceof ValueWrapper) {
      return commonValue.value === true ? "all" : "none"
    }

    return "some"
  }

  resetKeywordsToGenerated(): void {
    const multiArray = this.getMultiArray("keywords") as MultiRecordFormArray<IptcArrayItem>
    multiArray.formArray.controls.forEach((control: AbstractControl<IptcArrayItem>, index) => {
      if(control.value.isManuallyAdded) {
        this.markKeywordForDeletion(multiArray, index)
      } else if (control.value.isManuallyRemoved) {
        this.markKeywordForRestauration(multiArray, index)
      }
    })
    this.multiRecordFormManager.updateWithValuesFrom({
      _keywords_override: false
    })

    multiArray.formArray.markAsDirty()
  }

  restoreManuallySetKeywords(): void {
    this.getMultiArray("keywords").reset()
    this.getMultiControl("_keywords_override").reset()
  }

  addNewKeyword(multiArray: MultiRecordFormArray) {
    multiArray.addNewCommonItem(IptcArrayItem.newlyAddedItem())
    setTimeout(() => {
      this.newKeywordInputs.last?.focus({preventScroll: true})
      this.newKeywordElementRefs.last?.nativeElement?.scrollIntoView({behavior: "smooth"})
    })
  }

  getMultiControl(key: string) {
    return this.multiRecordFormManager.get(key) as MultiRecordFormControl
  }

  getMultiArray(key: string): MultiRecordFormArray {
    return this.multiRecordFormManager.get(key) as MultiRecordFormArray
  }

  getFieldClass(valueState: IptcFormFieldValueState) {
    switch(valueState) {
      case "manualValue": return {'manual-value': true}
      case "generatedValue": return {'generated-value': true}
      default: return {}
    }
  }

  reset(formControlName: string) {
    const multiRecordFormControl = this.multiRecordFormManager.get(formControlName)
    multiRecordFormControl.reset()
  }

  resetAll() {
    this.setImages()
  }

  private testRecordKeywords(
    multiArray: MultiRecordFormArray<IptcArrayItem>,
    index: number,
    testFn: (iptcItem: IptcArrayItem) => boolean
  ): RecordKeywordQuantifier {
    const commonIptcValue = multiArray.getValueAt(index)
    const testKeywords = [commonIptcValue.generated, commonIptcValue.value].filter(Boolean)

    const matchingRecordKeywords = this.getOriginalIptcValues(multiArray).filter(
      iptcValue => testKeywords.includes(iptcValue.value ?? iptcValue.generated)
    )
    const passingRecordKeywords = matchingRecordKeywords.filter(testFn)

    return passingRecordKeywords.length === 0
           ? "none"
           : multiArray.getRecordArrays().length === 1
             ? "one"
             : matchingRecordKeywords.length === passingRecordKeywords.length
               ? "all"
               : "some"
  }

  findRemovedGeneratedKeywords(multiArray: MultiRecordFormArray<IptcArrayItem>, index: number): RecordKeywordQuantifier {
    return this.testRecordKeywords(multiArray, index, iptcValue => iptcValue.isManuallyRemoved)
  }

  getKeywordState(multiArray: MultiRecordFormArray<IptcArrayItem>, index: number): KeywordState {
    const control = multiArray.formArray.at(index)
    return {
      hasManualKeywords: this.testRecordKeywords(multiArray, index, iptcValue => iptcValue.isManuallyAdded),
      hasUntouchedGeneratedKeywords: this.testRecordKeywords(multiArray, index, iptcValue => iptcValue.isUntouched),
      hasRemovedGeneratedKeywords: this.findRemovedGeneratedKeywords(multiArray, index),
      isMarkedForRemoval: control.value.markedForRemoval,
      isMarkedForRestoration: control.value.markedForRestauration,
      isNew: multiArray.isNewItem(index),
      control
    }
  }

  getKeywordPrefixIconTooltip(icon: keyof MultiIptcEditorComponent["keywordIconTooltipStrings"], quantifier: RecordKeywordQuantifier) {
    return this.keywordIconTooltipStrings[icon][quantifier]
  }

  markKeywordForDeletion(multiArray: MultiRecordFormArray<IptcArrayItem>, index: number) {
    if (multiArray.isNewItem(index)) {
      multiArray.removeNewItem(index)
    }

    const control = multiArray.formArray.at(index)
    control.setValue(IptcArrayItem.itemMarkedForRemoval(control.value))
    control.markAsDirty()
  }

  markKeywordForRestauration(multiArray: MultiRecordFormArray<IptcArrayItem>, index: number) {
    const control = multiArray.formArray.at(index)
    control.setValue(IptcArrayItem.itemMarkedForRestauration(control.value))
    control.markAsDirty()
  }

  resetKeyword(multiArray: MultiRecordFormArray<IptcArrayItem>, index: number) {
    multiArray.resetAt(index)
  }

  displayKeywordUntouchedGeneratedIcon(keywordState: KeywordState) {
    if (keywordState.control.dirty) {
      return keywordState.control.value.isUntouched && !keywordState.isMarkedForRemoval
              || keywordState.control.value.isManuallyRemoved && keywordState.isMarkedForRestoration
    } else {
      return !keywordState.isMarkedForRemoval && (
        keywordState.hasUntouchedGeneratedKeywords !== "none"
        || (keywordState.hasRemovedGeneratedKeywords !== "none" && keywordState.isMarkedForRestoration)
      )

    }
  }

  displayKeywordRemovedGeneratedIcon(keywordState: KeywordState) {
    if (keywordState.control.dirty) {
      const value = keywordState.control.value
      return value.isManuallyRemoved  && !keywordState.isMarkedForRestoration
        || (value.isUntouched && keywordState.isMarkedForRemoval)
    } else {
      return (keywordState.hasRemovedGeneratedKeywords !== "none" && !keywordState.isMarkedForRestoration)
              || (keywordState.hasUntouchedGeneratedKeywords  !== "none" && keywordState.isMarkedForRemoval)
    }
  }

  displayKeywordManualIcon(keywordState: KeywordState) {
    if (keywordState.isNew) {
      return true
    } else if (keywordState.control.dirty) {
      return keywordState.control.value.hasManualValue && !keywordState.isMarkedForRemoval
    } else {
      return keywordState.hasManualKeywords  !== "none" && !keywordState.isMarkedForRemoval
    }
  }

  displayKeywordRemovedManualIcon(keywordState: KeywordState) {
    if (keywordState.control.dirty) {
      return keywordState.control.value.isManuallyAdded && keywordState.isMarkedForRemoval
    } else {
      return keywordState.hasManualKeywords  !== "none" && keywordState.isMarkedForRemoval
    }
  }

  displayKeywordDeleteButton(keywordState: KeywordState) {
    if (keywordState.isMarkedForRemoval) {
      return false
    }

    if (keywordState.isNew || keywordState.isMarkedForRestoration) {
      return true
    }

    if (keywordState.control.dirty) {
      const value = keywordState.control.value
      return value.isUntouched || value.isManuallyAdded
    } else {
      return keywordState.hasUntouchedGeneratedKeywords  !== "none" || keywordState.hasManualKeywords  !== "none"
    }
  }

  displayKeywordRestoreButton(keywordState: KeywordState) {
    if (keywordState.isMarkedForRestoration) {
      return false
    }

    if (keywordState.isMarkedForRemoval) {
      return true
    }

    if (keywordState.control.dirty) {
      return keywordState.control.value.isManuallyRemoved
    } else {
      return keywordState.hasRemovedGeneratedKeywords  !== "none"
    }
  }

  private getOriginalIptcValues(multiArray: MultiRecordFormArray<IptcArrayItem>) {
    return multiArray
      .getRecordArrays()
      .flatMap(originalArray => originalArray.array)
  }

  getIptcTextFieldDisplayValue = (iptcValue: IptcValue<string>) =>
    this.iptcDatasetService.getIptcEffectiveValue(iptcValue)

  getCopyrightStatusDisplayValue = (iptcValue: IptcValue<boolean>) => {
    const effectiveValue = this.iptcDatasetService.getIptcEffectiveValue(iptcValue)
    return this.iptcDatasetService.getCopyrightStatusString(effectiveValue)
  }

  getDateCreatedDisplayValue = (iptcValue: IptcValue<string>) =>
    this.getDateCreatedDateValue(iptcValue)?.toLocaleDateString()

  getDateCreatedDateValue = (iptcValue: IptcValue<string>) => {
    const effectiveValue = this.iptcDatasetService.getIptcEffectiveValue(iptcValue)
    if (!effectiveValue) {
      return null
    }

    const date = moment(effectiveValue)
    if (date?.isValid()) {
      return date.toDate()
    }
  }

  setDate = (iptcControl: AbstractControl, dateChange: MatDatepickerInputEvent<Date>) => {
    if (dateChange.value) {
      const dateString = dateChange.value.toISOString().split('T')[0]
      iptcControl.setValue(dateString)
    }
  }

  iptcMultiValueIsEmpty = (iptcValue: IptcValue) => !iptcValue || (iptcValue.generated == null && iptcValue.value == null)

}
