import { CommonModule } from "@angular/common"
import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from "@angular/core"
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors } from "@angular/forms"
import { MatAutocompleteModule, MatAutocompleteTrigger } from "@angular/material/autocomplete"
import { MatButtonToggleModule } from "@angular/material/button-toggle"
import { MatCheckboxModule } from "@angular/material/checkbox"
import { MatInputModule } from "@angular/material/input"
import { MatRadioModule } from "@angular/material/radio"
import { combineLatest, Subject, switchMap } from "rxjs"
import { debounceTime, filter, startWith, takeUntil } from "rxjs/operators"

import { DateRangeService, isRangeDescription, RangeDescription } from "../../../services/date-range.service"
import { DateRangeModifier, DatingSpecification } from "../../models"

const rangeStringValidator = (ctrl: FormControl<string | RangeDescription>, ranges: RangeDescription[]): ValidationErrors => {
  const errors: ValidationErrors = {}

  if (ctrl.enabled) {
    if (ctrl.value === '') {
      errors['invalid'] = 'Eingabe erforderlich'
    }

    const value = ctrl.value
    if (!isRangeDescription(value) || (isRangeDescription(value) && !ranges.find(range => range.desc === value.desc))) {
      errors['invalid'] = 'Eingabeformat ungültig'
    }
  }

  return errors
}



@Component({
    selector: "insc-dating-chooser",
    templateUrl: "./dating-chooser.component.html",
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonToggleModule,
        MatRadioModule,
        MatInputModule,
        MatAutocompleteModule,
        MatCheckboxModule
    ],
    styleUrls: ["./dating-chooser.component.scss"]
})
export class DatingChooserComponent implements OnInit, OnChanges, AfterContentInit, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  formGroup = new FormGroup({
    primary:        new FormControl<string | RangeDescription>("", {validators: [
      (ctrl) => rangeStringValidator(ctrl as FormControl, this.suggestedRangesPrimary)
      ]}),
    secondary:      new FormControl<string | RangeDescription>({value: "", disabled: true}, {
      validators: [
        (ctrl: AbstractControl) => {
          return ctrl.parent?.value["extend"] === true ? rangeStringValidator(ctrl as FormControl, this.suggestedRangesSecondary) : null
        },
        (ctrl: AbstractControl) => {
          const errors: ValidationErrors = {}
          const primaryValue = this.formGroup.get("primary")?.value
          const secondaryValue = ctrl.value
          if (isRangeDescription(primaryValue) && isRangeDescription(secondaryValue)) {
            if (this.formGroup.get("primary").valid) {
              if (!secondaryValue || new Date(secondaryValue.to) < new Date(primaryValue.from)) {
                errors["invalid"] = "\"von\" darf nicht kleiner sein als \"bis\""
              }
            }
          }

          return errors
        }
      ]
    }),
    range_type:     new FormControl<"closed" | "open">("closed"),
    range_modifier: new FormControl<DateRangeModifier>({value: "closed", disabled: true}),
    extend:         new FormControl<boolean>(false)
  })

  suggestedRangesPrimary: RangeDescription[] = []
  suggestedRangesSecondary: RangeDescription[] = []

  @Input() initialDatingSpecification: DatingSpecification | null = null
  @Output() datingSpecificationChange: EventEmitter<DatingSpecification | null> = new EventEmitter()


  private _chosenDatingSpecification: DatingSpecification | null = null
  set chosenDatingSpecification(spec: DatingSpecification) {
    this.datingSpecificationChange.emit(spec)
    this._chosenDatingSpecification = spec
  }
  get chosenDatingSpecification() { return this._chosenDatingSpecification }

  errors: string[] = []

  @ViewChild('autocompletePrimary', { static: true }) autocompletePrimary: MatAutocompleteTrigger
  @ViewChildren('autocompleteSecondary') autocompleteSecondary: QueryList<MatAutocompleteTrigger>
  @ViewChild('inputPrimary', { static: true }) inputPrimary: ElementRef

  constructor(
    private dateRangeService: DateRangeService
  ) {}

  ngOnInit() {

    const primaryInputCtrl = this.formGroup.controls['primary']
    const secondaryInputCtrl = this.formGroup.controls['secondary']
    const extendCtrl = this.formGroup.controls['extend']
    const rangeModifierCtrl = this.formGroup.controls['range_modifier']


    const getRanges = (inputCtrl: FormControl<string | RangeDescription>, setRanges: (ranges: RangeDescription[]) => void) => {
      combineLatest([
        inputCtrl.valueChanges.pipe(startWith(inputCtrl.value)),
        rangeModifierCtrl.valueChanges.pipe(startWith(rangeModifierCtrl.value)),
      ]).pipe(
        debounceTime(200),
        filter(([value, _]) => value !== null),
        switchMap(([value, rangeModifier]) => {
            let query = isRangeDescription(value) ? value.desc : value
            return this.dateRangeService.getMatchingRanges(query, rangeModifier)
          }
        ),
        takeUntil(this.unsubscribe$),
      ).subscribe(ranges => {
        setRanges(ranges)

        const value = inputCtrl.value
        if (isRangeDescription(value)) {
          const matchingRangeDesc = ranges.find((rangeDesc) => rangeDesc.desc === value.desc)
          if (!matchingRangeDesc) {
            console.log("setting value 1", value.desc)
            inputCtrl.setValue(value.desc, {emitEvent: false})
          }
        } else {
          console.log("setting value 2", value)
          const matchingRangeDesc = ranges.find((rangeDesc) => rangeDesc.desc === value)
          if (matchingRangeDesc) {
            inputCtrl.setValue(matchingRangeDesc, {emitEvent: false})
          }
        }

        console.log(inputCtrl.value)

        // this.formGroup.controls.primary.updateValueAndValidity({emitEvent: false})
        // this.formGroup.controls.secondary.updateValueAndValidity({emitEvent: true})

      })
    }

    getRanges(primaryInputCtrl, ranges => this.suggestedRangesPrimary = ranges)
    getRanges(secondaryInputCtrl, ranges => this.suggestedRangesSecondary = ranges)

    this.formGroup.controls['range_type'].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(range_type => {
        const rangeModifierCtrl = this.formGroup.controls['range_modifier']

        if (range_type === 'closed') {
          rangeModifierCtrl.setValue('closed', {emitEvent: false})
          rangeModifierCtrl.disable({emitEvent: false})

          extendCtrl.enable({emitEvent: false})
        } else {
          if (rangeModifierCtrl.value === 'closed') {
            rangeModifierCtrl.setValue('open_left_exclusive', {emitEvent: false})
          }
          rangeModifierCtrl.enable({emitEvent: false})

          extendCtrl.disable({emitEvent: false})
          extendCtrl.setValue(false)
        }
      })

    extendCtrl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(extend => {
        if (extend) {
          secondaryInputCtrl.enable()
        } else {
          secondaryInputCtrl.reset(null, {emitEvent: false})
          secondaryInputCtrl.disable()
        }
      })

    this.formGroup.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(status => {

      const primary = isRangeDescription(this.formGroup.value['primary']) && this.formGroup.value['primary']
      const secondary = isRangeDescription(this.formGroup.value['secondary']) && this.formGroup.value['secondary']
      if (status === 'VALID' && primary) {

        const range_modifier = this.formGroup.value['range_modifier']

        this.dateRangeService.getSpecification(primary.desc, secondary?.desc, range_modifier)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(spec => {
            this.chosenDatingSpecification = spec
          })
      } else {
        this.chosenDatingSpecification = null
      }
    })

    this.ngOnChanges()
  }

  ngAfterContentInit() {
    setTimeout(() => this.inputPrimary.nativeElement.focus())
  }

  ngOnChanges() {
    if (this.initialDatingSpecification) {
      this.formGroup.patchValue({
        primary: this.initialDatingSpecification.primary,
        secondary: this.initialDatingSpecification.secondary,
        range_modifier: this.initialDatingSpecification.range_modifier,
        range_type: this.initialDatingSpecification.range_modifier === 'closed' ? 'closed' : 'open',
        extend: !!this.initialDatingSpecification.secondary
      })
    } else {
      this.formGroup.patchValue({
        primary: "",
        secondary: "",
        range_modifier: "closed"
      })
    }
  }
  // update value of the input fields to the corresponding RangeDescription object when
  // keyboard-navigating through the list
  onInputKeydown(event: KeyboardEvent, autocompleteTrigger: MatAutocompleteTrigger, control: AbstractControl) {
    if (["ArrowDown", "ErrorUp"].includes(event.key)) {
      if (autocompleteTrigger.activeOption) {
        control.setValue(autocompleteTrigger.activeOption.value, {emitEvent: false})
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  getDisplayValue = range => isRangeDescription(range) ? range.desc : range
}
