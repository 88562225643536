import { Component } from "@angular/core"

@Component({
    selector: 'insc-user-management-page',
    templateUrl: './user-management-page.component.html',
    styleUrls: ['./user-management-page.component.scss'],
    standalone: false
})
export class UserManagementPageComponent  {



}
