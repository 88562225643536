import {
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  TemplateRef
} from "@angular/core"
import { UntypedFormControl } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { Observable } from "rxjs"
import { map, take } from "rxjs/operators"
import { AuthService } from "../../../../services/auth.service"
import { UserPreferencesService } from "../../../../services/user-preferences.service"
import { EditingStatus, InscEntity } from "../../../../shared/models/entity.model"
import { SnackbarService } from "../../../../shared/snackbars/snackbar.service"
import { ImageSidebarService } from "../../../images/image-sidebar/image-sidebar.service"
import { RecordPageStateService } from "../../record-page-state.service"

@Directive({
    selector: "[inscRecordTitle]",
    standalone: false
})
export class RecordTitleDirective {}

@Directive({
    selector: "[inscRecordSubtitle]",
    standalone: false
})
export class RecordSubtitleDirective {}

@Directive({
    selector: "[inscRecordImage]",
    standalone: false
})
export class RecordImageDirective {}

@Directive({
    selector: "[inscRecordSidebarSection]",
    standalone: false
})
export class RecordSidebarSectionDirective {}

@Directive({
    selector: "[inscRecordSidebarSectionTitle]",
    standalone: false
})
export class RecordSidebarSectionTitleDirective {}

@Directive({
    selector: "[inscRecordSidebarSectionTitleButton]",
    standalone: false
})
export class RecordSidebarSectionTitleButtonDirective {}


@Directive({
    selector: "[inscRecordSidebarSectionContent]",
    standalone: false
})
export class RecordSidebarSectionContentDirective {}

@Directive({
    selector: "[inscRecordSidebarSectionActions]",
    standalone: false
})
export class RecordSidebarSectionActionsDirective {}

@Component({
    selector: 'insc-record-page-container',
    templateUrl: './record-page-container.component.html',
    styleUrls: ['./record-page-container.component.scss'],
    standalone: false
})
export class RecordPageContainerComponent implements OnDestroy, OnInit {
  @Input() record: InscEntity

  @Input() activityLinkType: string
  @Input() recordType: string

  @Input() previewImageUrl: string | null

  @Output() save = new EventEmitter<void>()
  @Output() delete = new EventEmitter<void>()

  editingStatusCtrl = new UntypedFormControl(this.recordPageState.record && this.recordPageState.record.editing_status)

  @ContentChildren(RecordSidebarSectionDirective, {read: TemplateRef, descendants: true}) sidebarSections: QueryList<TemplateRef<void>>

  sidebarButtonHover = false
  sidebarCollapsed = false

  readonly sidebarAutoHide: Observable<boolean> = this.preferences.userPreferences.pipe(
    map(prefs => prefs.recordSidebarAutoHide)
  )

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private recordPageState: RecordPageStateService<InscEntity>,
    private snackbarService: SnackbarService,
    public auth: AuthService,
    private imageSidebar: ImageSidebarService,
    private preferences: UserPreferencesService
  ) {
    recordPageState.recordChanges.pipe(
      map(record => record?.editing_status)
    ).subscribe(editing_status => this.editingStatusCtrl.setValue(editing_status, {emitEvent: false}))

    this.editingStatusCtrl.valueChanges.subscribe(status => this.setEditingStatus(status))
  }

  ngOnInit() {
    // Set initial sidebar collapse state based on stored preferences.
    // Subsequent changes are handled using an async subscription in the
    // template.
    this.sidebarAutoHide.pipe(take(1)).subscribe(autoHide => {
      this.sidebarCollapsed = autoHide
    })
  }

  onSidebarMouseEnter(sidebarAutoHide: boolean) {
    if (sidebarAutoHide) {
      this.sidebarCollapsed = false
    }
  }

  onSidebarMouseLeave(sidebarAutoHide: boolean) {
    if (sidebarAutoHide) {
      this.sidebarCollapsed = true
    }
  }

  onSidebarTransitionEnd() {
    if (this.sidebarCollapsed) {
      this.preferences.set("recordSidebarAutoHide", true)
    }
  }

  enableSidebarAutoHide() {
    this.sidebarCollapsed = true
  }

  disableSidebarAutoHide() {
    this.preferences.set("recordSidebarAutoHide", false)
    this.sidebarCollapsed = false
  }

  get isEditModeActive(): boolean {
    return ["new", "edit", "clone"]
      .map(routeSegment => this.router.createUrlTree([routeSegment], {relativeTo: this.route}))
      .some(route => this.router.isActive(route, {
        paths: "exact", queryParams: "exact", fragment: "ignored", matrixParams: "ignored"
      }))
  }

  setEditingStatus(status: EditingStatus): void {
    this.recordPageState.setEditingStatus(status).subscribe({
      next: record => {
        this.recordPageState.record = record
        this.snackbarService.showSuccessWithMessage("Bearbeitungsstatus aktualisiert")
      },
      error: () => {
        this.editingStatusCtrl.setValue(this.record.editing_status, {emitEvent: false})
      }
    })
  }

  ngOnDestroy(): void {
    this.imageSidebar.unsetImages()
  }
}
