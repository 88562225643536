import { Component } from "@angular/core"
import { FormArray, FormControl, FormGroup } from "@angular/forms"
import { HistoricalOrganizationEntry } from "../../../../shared/models/historical-organization-entry.model"

import { SubformComponent } from "../../../../shared/subform.component"

@Component({
    selector: 'insc-historical-organization-entry-form',
    templateUrl: 'historical-organization-entry-form.component.html',
    styleUrls: ['shared-styles.scss'],
    standalone: false
})
export class HistoricalOrganizationEntryFormComponent extends SubformComponent {

  // noinspection DuplicatedCode
  static buildFormGroup(historicalOrganizationEntry: HistoricalOrganizationEntry) {
    const alternativeNameFormGroups = (historicalOrganizationEntry?.alternative_names || []).map((name: string) => new FormControl(name))
    return new FormGroup({
      id:                new FormControl(historicalOrganizationEntry?.id || null),
      name:              new FormControl(historicalOrganizationEntry?.name || ""),
      comment:           new FormControl(historicalOrganizationEntry?.comment || ""),
      alternative_names: new FormArray(alternativeNameFormGroups),
      timeframe:         new FormControl(historicalOrganizationEntry?.timeframe || ""),
      germania_sacra_id: new FormControl(historicalOrganizationEntry?.germania_sacra_id || "")
    })
  }

  addAltName() {
    (this.formGroup.controls["alternative_names"] as FormArray).push(new FormControl(''))
  }

  removeAltName(i: number) {
    (this.formGroup.controls["alternative_names"] as FormArray).removeAt(i)
  }
}
