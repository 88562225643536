<ng-container [formGroup]="recordingDateForm">
  <mat-radio-group class="radio-group" [formControlName]="'type'">
    <mat-radio-button value="single">Zeitpunkt</mat-radio-button>
    <mat-radio-button value="range">Zeitraum</mat-radio-button>
  </mat-radio-group>

  <div class="pickers">
    <div class="picker">
      <insc-date-range-picker [formControlName]="'primary'"
                              [placeholder]="recordingDateForm.value['type'] === 'single' ? 'Aufnahmedatum' : 'Aufnahmedatum (nach)'"
      ></insc-date-range-picker>
      @if (recordingDateForm.controls['primary'].touched && recordingDateForm.controls['primary'].hasError("required")) {
        <mat-error>Angabe erforderlich</mat-error>
      }
    </div>
    @if (recordingDateForm.value['type'] === 'range') {
      <div class="picker">
        <insc-date-range-picker
          [formControlName]="'secondary'"
          placeholder="Aufnahmedatum (vor)"
        ></insc-date-range-picker>
        @if (recordingDateForm.hasError("secondaryRequired")) {
          <mat-error>Angabe erforderlich</mat-error>
        }
      </div>
    }
  </div>

</ng-container>
