import { Component } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { SubformComponent } from "../../../../shared/subform.component"

@Component({
    selector: 'insc-sign-element-form',
    templateUrl: './sign-element-form.component.html',
    styleUrls: ['./sign-element-form.component.css'],
    standalone: false
})
export class SignElementFormComponent extends SubformComponent {

  static buildFormGroup(sign, destroyable = true) {
    return new UntypedFormGroup({
      id:					new UntypedFormControl(sign.id || null),
      name:				new UntypedFormControl(sign.name || ''),
      sign_type:   		new UntypedFormControl(sign.sign_type || ''),
      ...this.destroyableControl(sign.id, destroyable)
    })
  }

}
