<ng-template #imageChooserResultSubtitleTpl let-result let-query="query">
  <span [innerHTML]="result.data.filename | highlight: query"></span>
</ng-template>

<insc-back-to-results-link></insc-back-to-results-link>
<div class="header">
  <h3>Inschriftenträger</h3>
  <h1 class="name">{{object.name}}</h1>
  <insc-location-header-view [location]="object?.current_locationing?.location"></insc-location-header-view>
</div>

<div
  [inscImageManagement]="'object_' + object.id"
  [canChangeSelection]="canChangeImageSelection"
  (imageSelectionChange)="onImageSelectionChange($event)"
  #imageManagement="imageManagement"
  class="columns"
  >


  <div class="image-column">
    @if (allImages().length > 0) {
      <div class="global-image-controls">
        @switch (imageSelectionStatus) {
          @case ('none') {
            <div class="action-button" role="button" matRipple (click)="selectAllImages()" matTooltip="Alle auswählen"><mat-icon>check_box_outline_blank</mat-icon></div>
          }
          @case ('some') {
            <div class="action-button" role="button" matRipple (click)="deselectAllImages()" matTooltip="Alle abwhälen"><mat-icon>indeterminate_check_box</mat-icon></div>
          }
          @case ('all') {
            <div class="action-button" role="button" matRipple (click)="deselectAllImages()" matTooltip="Alle abwählen"><mat-icon>check_box</mat-icon></div>
          }
        }
        <mat-divider></mat-divider>
        <div
          class="action-button"
          role="button"
          matRipple
          matTooltip="Ausgewählte Bilddateien herunterladen"
          [ngClass]="{disabled: imageSelectionStatus === 'none'}"
          >
          <insc-image-download-menu #imageDownloadMenu
            [images]="selectedImages(imageManagement.selections)"
            [parent]="{type: 'insc_object', id: object.id}"
          ></insc-image-download-menu>
          <a class="download-link" [matMenuTriggerFor]="imageDownloadMenu.menu"><mat-icon>file_download</mat-icon></a>
        </div>
        <div
          class="action-button"
          role="button"
          matRipple
          matTooltip="Ausgewählte Bilder in der Galerieansicht öffnen"
          (click)="openGallery()"
          [ngClass]="{disabled: imageSelectionStatus === 'none'}"
          >
          <mat-icon>photo_library</mat-icon>
        </div>
      </div>
    }

    <div class="image-lists">
      <insc-record-image-list-container
        [recordName]="object.name"
        (linkImageClicked)="linkObjectImages('images')"
        (unlinkClicked)="unlinkObjectImages('images', $event, objectImageGroup)"
        (uploadImagesClicked)="openImageUploader('insc_object', object.id, object.name)"
        >
        <ng-template inscRecordImageListTitle>
          <div class="section-title">Inschriftenträger</div>
        </ng-template>

        <insc-image-management-group
          #objectImageGroup
          [imageGroupDescriptor]="{type: 'images'}"
          [images]="object.images"
          (imageDrop)="onImageDrop($event)"
          [ngClass]="{dragging: imageManagement.dragging$ | async}"
          >
          @for (image of object.images; track image) {
            <insc-image-tile inscRecordImageListImage [image]="image"></insc-image-tile>
          }
        </insc-image-management-group>
      </insc-record-image-list-container>

      <insc-record-image-list-container
        [recordName]="object.name"
        (linkImageClicked)="linkObjectImages('context_images')"
        (unlinkClicked)="unlinkObjectImages('context_images', $event, contextImageGroup)"
        (uploadImagesClicked)="openImageUploader('insc_object', object.id, object.name, {context: true})"
        >
        <ng-template inscRecordImageListTitle>
          <div class="section-title">Kontextaufnahmen</div>
        </ng-template>

        <insc-image-management-group
          #contextImageGroup
          [imageGroupDescriptor]="{type: 'context_images'}"
          [images]="object.context_images"
          (imageDrop)="onImageDrop($event)"
          [ngClass]="{dragging: imageManagement.dragging$ | async}"
          >
          @for (image of object.context_images; track image) {
            <insc-image-tile inscRecordImageListImage [image]="image"></insc-image-tile>
          }
        </insc-image-management-group>
      </insc-record-image-list-container>

      @for (inscription of object.inscriptions; track inscriptionById($index, inscription)) {
        <insc-record-image-list-container
          [recordName]="inscription.name"
          (linkImageClicked)="linkInscriptionImages(inscription)"
          (unlinkClicked)="unlinkInscriptionImages(inscription.id, $event, inscriptionImageGroup)"
          (uploadImagesClicked)="openImageUploader('inscription', inscription.id, inscription.name)"
          >
          <ng-template inscRecordImageListTitle>
            <div class="section-title" style="margin-bottom: 0.25rem">
              <div style="font-weight: 400; font-size: 0.8rem;">Aufnahmen der Inschrift</div>
            </div>
          </ng-template>
          <ng-template inscRecordImageListSubtitle>
            <a class="inscription-link" [routerLink]="['inscriptions', inscription.id]">
              {{inscription.name}}
              <mat-icon style="vertical-align: middle;">keyboard_arrow_right</mat-icon>
            </a>
          </ng-template>
          <insc-image-management-group
            #inscriptionImageGroup
            [imageGroupDescriptor]="{type: 'inscription_images', id: inscription.id}"
            [images]="inscription.images"
            (imageDrop)="onImageDrop($event)"
            [ngClass]="{dragging: imageManagement.dragging$ | async}"
            >
            @for (image of inscription.images; track image) {
              <insc-image-tile inscRecordImageListImage [image]="image"></insc-image-tile>
            }
          </insc-image-management-group>
        </insc-record-image-list-container>
      }
    </div>
  </div>

  <div class="metadata-column">
    <div class="section">
      <div class="section-title">Allgemein</div>
      <insc-data-group>
        <div inscDataField [editorialState]="object.object_types_editorial_state">
          <div *inscFieldLabel>Objekttyp</div>
          @for (value of object.object_types; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="object.object_genres_editorial_state">
          <div *inscFieldLabel>Kunstgattung</div>
          @for (value of object.object_genres; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="object.object_materials_editorial_state">
          <div *inscFieldLabel>Material</div>
          @for (value of object.object_materials; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="object.object_techniques_editorial_state">
          <div *inscFieldLabel>Technik</div>
          @for (value of object.object_techniques; track value) {
            <div>
              <span *inscFieldValue>{{ value }}</span>
            </div>
          }
        </div>
        <div inscDataField [editorialState]="object.status_editorial_state">
          <div *inscFieldLabel>Zustand</div>
          <div *inscFieldValue>{{ object.status }}</div>
        </div>
      </insc-data-group>


    </div>

    <div class="section">
      <div class="section-title">Standortinformationen</div>
      <insc-data-group>
        <div inscDataField>
          <div *inscFieldLabel>{{locationDisplayHelper.getLocationTypeString(object.current_locationing?.location?.type)}}</div>
          <div *inscFieldValue><span>{{object.current_locationing.specification}}</span></div>
        </div>
        <div inscDataField>
          <div *inscFieldLabel>Zeitraum</div>
          <div *inscFieldValue>{{object.current_locationing.timeframe}}</div>
        </div>
      </insc-data-group>
    </div>

    <div class="section">
      <div class="section-title">Datierung</div>
      <div class="section-content">
        @for (dating of object.datings; track dating) {
          <insc-dating-view class="view" [dating]="dating"></insc-dating-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Literatur</div>
      <div class="section-content">
        @for (litRef of object.literature_references; track litRef) {
          <insc-literaturereference-view class="view" [literatureReference]="litRef"></insc-literaturereference-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Provenienz</div>
      <div class="section-content">
        @for (locationing of object.provenience_locationings; track locationing) {
          <insc-locationing-view class="view" [locationing]="locationing"></insc-locationing-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Personen / Organisationen</div>
      <div class="section-content">
        @for (personOrg of object.person_organizations; track personOrg) {
          <insc-personorganization-view class="view" [personOrganization]="personOrg"></insc-personorganization-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Lokalisierung</div>
      <div class="section-content">
        @for (origin of object.origins; track origin) {
          <insc-origin-view class="view" [origin]="origin"></insc-origin-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Wappen ({{object.emblems.length}})</div>
      <div class="section-content">
        @for (emblem of object.emblems; track emblem) {
          <insc-emblem-view class="view" [emblem]="emblem"></insc-emblem-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Marken ({{object.marks.length}})</div>
      <div class="section-content">
        @for (mark of object.marks; track mark) {
          <insc-mark-view class="view" [mark]="mark"></insc-mark-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Zeichen ({{object.signs.length}})</div>
      <div class="section-content">
        @for (sign of object.signs; track sign) {
          <insc-sign-view class="view" [sign]="sign"></insc-sign-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Ikonographie</div>
      <div class="section-content">
        @for (iconography of object.iconography_descs; track iconography) {
          <insc-iconography-desc-view class="view" [iconographyDesc]="iconography"></insc-iconography-desc-view>
        }
      </div>
    </div>

    <div class="section">
      <div class="section-title">Kommentare</div>
      <div class="section-content">
        @for (comment of object.comments; track comment) {
          <insc-comment-view class="view" [comment]="comment"></insc-comment-view>
        }
      </div>
    </div>

  </div>
</div>
