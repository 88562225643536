import { Injectable } from "@angular/core"
import * as exifr from "exifr"
import { Observable } from "rxjs"
import { fromPromise } from "rxjs/internal/observable/innerFrom"
import { map } from "rxjs/operators"
import { dateToDateSpec } from "../../../shared/components/form-components/date-range-picker/date-range-picker.component"
import { InscImage } from "../../../shared/models/image.model"

@Injectable({
  providedIn: 'root'
})
export class ImageMetadataReaderService {

  constructor() { }

  getMetadata(imageFile: File): Observable<Partial<InscImage>> {
    return fromPromise(exifr.parse(imageFile)).pipe(
      map(metadata => {
        const image: Partial<InscImage> = {}

        if ("CreateDate" in metadata && metadata["CreateDate"] instanceof Date) {
          const dateSpec = dateToDateSpec(metadata["CreateDate"])
          image.recording_date = {
            earliest: dateSpec,
            latest: dateSpec
          }
        }

        return image
      })
    )
  }
}
