import { Component, ElementRef, HostBinding, Input, OnChanges, ViewChild } from "@angular/core"
import { MatMenu } from "@angular/material/menu"
import { LocationDisplayHelperService } from "../../../services/location-display-helper.service"
import { InscImage } from "../../../shared/models/image.model"
import { InscObject } from "../../../shared/models/object.model"

@Component({
    selector: 'insc-image-result-card',
    templateUrl: './image-result-card.component.html',
    styleUrls: ['./image-result-card.component.scss', '../result-cards.scss'],
    standalone: false
})
export class ImageResultCardComponent implements OnChanges {

  @Input() result: Partial<InscImage>
  @Input() highlight: Partial<InscImage>
  @Input() baseSize = 200

  @Input() imageMenu: MatMenu

  locationNames = ""
  inscObjectOrInscriptionNames: string[] = null

  @ViewChild('image') image: ElementRef<HTMLImageElement>

  @HostBinding("style.flex") flex: string
  constructor(private locationDisplayHelper: LocationDisplayHelperService) {}

  // get optimalWidth() {
  //   if (!this.result?.dimensions) {
  //     return 120 / 2 * 3
  //   }
  //
  //   const [width, height] = this.result.dimensions
  //   const factor = 120 / height
  //   return factor * width;
  // }

  get imageElem(): HTMLImageElement {
    return this.image?.nativeElement
  }

  get orientation(): "portrait" | "landscape" {
    if (!this.result?.dimensions) {
      return "landscape"
    }

    return this.result.dimensions[0] < this.result.dimensions[1] ? "portrait" : "landscape"
  }

  ngOnChanges(): void {
    this.locationNames = this.getLocationNamesFromInscObjects(this.result)
    this.inscObjectOrInscriptionNames = this.getInscObjectOrInscriptionNames(this.result)
    const flexBase = this.orientation === 'landscape' ? this.baseSize / 2 * 3 : this.baseSize / 3 * 2
    this.flex = `1 1  ${flexBase}px`
  }

  private getLocationNamesFromInscObjects(result: Partial<InscImage>) {
    if (result?.locations) {
      const cities = result.locations.map(location => this.locationDisplayHelper.getLocationNameWithLostAnnotation(location?.city))
      return cities
        .filter((city, index) => cities.indexOf(city) === index)
        .join(", ")
    }
  }

  private getInscObjectOrInscriptionNames(result: Partial<InscImage>) {
    if (!result) {
      return null
    }
    if (result.insc_objects?.length > 0) {
      return result.insc_objects.map(object => object.name)
    } else if (result.inscriptions?.length > 0) {
      const objects = [].concat(...result.inscriptions.map(inscription => inscription.insc_objects)) as Partial<InscObject>[]
      const objectNames = objects
        .map(object => object.name)
        .filter(Boolean)

      const uniqueObjectNames = objectNames.filter((name, index) => objectNames.indexOf(name) === index)
      return uniqueObjectNames.length > 0 ? uniqueObjectNames : result.inscriptions?.map(inscription => inscription.name)
    }
  }
}
