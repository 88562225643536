import { Component, Input, OnInit } from "@angular/core"
import { Mark } from "../../../../shared/models"

@Component({
    selector: 'insc-mark-view',
    templateUrl: './mark-view.component.html',
    styleUrls: ['./mark-view.component.scss'],
    standalone: false
})
export class MarkViewComponent implements OnInit {
  @Input() mark: Mark

  constructor() { }

  ngOnInit() {
  }

}
