import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Directive,
  Input,
  OnInit,
  QueryList,
  Type,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from "@angular/core"
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { map, switchMap } from "rxjs/operators"
import { SubformComponent } from "../../../../../shared/subform.component"
import { AbstractExternalNormDataProviderService } from "../abstract-external-norm-data-provider.service"
import { EXTERNAL_NORM_DATA_LOOKUP_ENABLED } from "../external-norm-data-id-input/external-norm-data-id-input.component"
import { ExternalNormDataMapperInterface } from "../external-norm-data-mapper.interface"
import { ExternalNormDataProviderResolverService } from "../external-norm-data-provider-resolver.service"
import { ExternalNormDataLookupFieldComponent } from "./external-norm-data-lookup-field/external-norm-data-lookup-field.component"

export type ExternalNormDataLookupToolMappingMode = "auto" | "manual"

@Directive({
    selector: '[inscExternalNormDataLookupForm]',
    standalone: false
})
export class ExternalNormDataLookupFormDirective {}

@Component({
    selector: 'insc-external-norm-data-lookup-tool',
    templateUrl: './external-norm-data-lookup-tool.component.html',
    styleUrls: ['./external-norm-data-lookup-tool.component.scss'],
    providers: [{
            // disable the lookup buttons on norm data id input fields in subforms
            provide: EXTERNAL_NORM_DATA_LOOKUP_ENABLED,
            useValue: false
        }],
    standalone: false
})
export class ExternalNormDataLookupToolComponent<T> implements OnInit, AfterViewInit {

  @Input() providerId: string
  @Input() lookupOptions: Record<string, string> = {}
  @Input() subformComponentType: typeof SubformComponent
  @Input() subformComponentInputs: {[key: string]: string} = {}

  @Input() dataMapper: ExternalNormDataMapperInterface<unknown, T>

  @Input() initialData: Partial<T> = {}
  @Input() fixedData: Partial<T> = {}

  private provider: AbstractExternalNormDataProviderService<unknown>

  @ViewChild('remoteValuesFormContainer', {read: ViewContainerRef}) remoteValuesFormContainer: ViewContainerRef
  @ViewChildren('localValuesFormContainer', {read: ViewContainerRef}) localValuesFormContainer: QueryList<ViewContainerRef>

  @ViewChild(ExternalNormDataLookupFieldComponent) lookupField: ExternalNormDataLookupFieldComponent

  localValuesFormGroup: UntypedFormGroup
  remoteValuesFormGroup: UntypedFormGroup

  mappingModeCtrl: UntypedFormControl
  get mappingMode(): ExternalNormDataLookupToolMappingMode {
    return this.mappingModeCtrl.value as ExternalNormDataLookupToolMappingMode
  }


  get mappingResult(): T {
    const formGroup = this.mappingMode === "auto" ? this.remoteValuesFormGroup : this.localValuesFormGroup
    return formGroup.dirty && this.removeEmpty(formGroup.get("form").value as T)
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private providerResolver: ExternalNormDataProviderResolverService,
    private cdr: ChangeDetectorRef
  ) { }


  ngOnInit(): void {
    if (!this.providerId) {
      throw new Error(`Provider ID must be set! Available providers: ${this.providerResolver.availableProviders}.`)
    }

    this.provider = this.providerResolver.get(this.providerId)
    if (!this.provider) {
      return
    }

    this.mappingModeCtrl = new UntypedFormControl(this.initialData ? "manual" : "auto")

    this.localValuesFormGroup = new UntypedFormGroup({form: this.subformComponentType.buildFormGroup(this.initialData ?? {})})
    this.remoteValuesFormGroup = new UntypedFormGroup({form: this.subformComponentType.buildFormGroup(this.fixedData ?? {})})
  }

  private setSubformComponentInputs(component: SubformComponent, values: {[key: string]: string}) {
    if (!values) {
      return
    }

    Object.keys(values).forEach(key => component[key] = values[key])
  }

  private initLocalValuesForm(formComponentType: Type<SubformComponent>) {
    if (this.localValuesFormContainer.length > 0) {
      const componentRef = this.localValuesFormContainer.first.createComponent(formComponentType)
      this.setSubformComponentInputs(componentRef.instance, this.subformComponentInputs)
      this.cdr.detectChanges()
    }
  }

  ngAfterViewInit(): void {
    const remoteValuesFormCompnentRef = this.remoteValuesFormContainer.createComponent(this.subformComponentType)
    this.setSubformComponentInputs(remoteValuesFormCompnentRef.instance, this.subformComponentInputs)
    this.cdr.detectChanges()

    this.initLocalValuesForm(this.subformComponentType)
    this.localValuesFormContainer.changes.subscribe(_ => this.initLocalValuesForm(this.subformComponentType))

    this.lookupField.valueSelected.pipe(
      switchMap(selectedResult => this.provider.getEntryData(selectedResult.id)),
      map(normData => this.dataMapper.map(normData)),
      map(mappedData => this.subformComponentType.buildFormGroup({...mappedData, ...this.fixedData}))
    ).subscribe(mappedValue => {
      this.remoteValuesFormGroup.setControl("form", mappedValue)
      this.remoteValuesFormGroup.markAsDirty()
    })
  }

  private removeEmpty = <U = T | unknown>(obj: U): U => {
    Object.keys(obj).forEach(key => {
      if (obj[key] && typeof obj[key] === 'object') {
        this.removeEmpty(obj[key])
      } else if (!obj[key]) {
        delete obj[key]
      }
    })
    return obj
  }
}
