import { Component, Input, Optional, Self } from "@angular/core"
import { ControlValueAccessor, NgControl } from "@angular/forms"

import { MatDialog } from "@angular/material/dialog"
import { ObjectLocationService } from "../../../../../services/data.service"

import { ObjectLocation } from "../../../../../shared/models"
import { RegisterEntryDialogService } from "../../../../registers/register-entry-dialog/register-entry-dialog.service"

@Component({
    selector: 'insc-location-input',
    template: `
        @if (location) {
          <div>
            <insc-location-view [location]="location" [disabled]="disabled"></insc-location-view>
          </div>
        } @else {
          <button  id="emptySelectButton" type="button" [disabled]="disabled" mat-raised-button (click)="openEditor()"><span [ngStyle]="ngControl.invalid ? {'color': 'red'} : {}">Auswählen</span></button>
        }
        <div id="editButtonContainer">
          <button [color]="ngControl.invalid ? 'warn' : 'primary'" class="selectOrEditButton" mat-button type="button" [disabled]="disabled" (click)="openEditor()" ><small class="form-text">Auswählen / Bearbeiten</small></button>
          @if (canReset) {
            <small> | </small>
          }
          @if (canReset) {
            <button [color]="ngControl.invalid ? 'warn' : 'primary'"  class="selectOrEditButton" mat-button type="button" [disabled]="disabled" (click)="resetField()" ><small class="form-text">Zurücksetzen</small></button>
          }
        </div>
        <mat-error>{{ ngControl.errors?.invalid }}</mat-error>
        `,
    styles: [`
        #emptySelectButton {
        	display: block;
        	width: 100%;
        }

        #editButtonContainer {
        	display: block;
        	width: 100%;
        	text-align: right;
        }

        .selectOrEditButton {
            line-height: 18px;
            padding: 0 2px 9px 2px;
        }

        .selectOrEditButton:hover {
            background-color: initial;
        }

    `],
    providers: [
        // {
        // 	provide: NG_VALUE_ACCESSOR,
        // 	useExisting: forwardRef(() => LocationEntryComponent),
        // 	multi: true
        // },
        ObjectLocationService,
        // need to provide MatDialog here so the Dialog contents inherits our providers above
        MatDialog
    ],
    standalone: false
})
export class LocationInputComponent implements ControlValueAccessor {

  location: ObjectLocation = null

  get locationType(): string { return this.location?.type }

  disabled = false

  @Input() canReset = false

  constructor(
    private objectLocationService: ObjectLocationService,
    private registerEntryDialogService: RegisterEntryDialogService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this
    }
  }

  openEditor(): void {
    this.registerEntryDialogService.open<ObjectLocation>({types: ["locations"], selectEntry: this.location})
      .subscribe(selectedEntry => {
        if (selectedEntry) {
          this.propagateChange(selectedEntry)
          this.location = selectedEntry
        }
      })
  }

  // noinspection JSUnusedLocalSymbols
  propagateChange = (_: ObjectLocation): void => {}

  writeValue(location: ObjectLocation): void {
    this.location = location
  }

  resetField(): void {
    this.propagateChange(null)
    this.location = null
  }

  registerOnChange(fn: (_: ObjectLocation) => void): void {
    this.propagateChange = fn
  }

  registerOnTouched(): void {}

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled
  }
}
