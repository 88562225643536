<insc-data-group>
  <div inscDataField>
    <span *inscFieldLabel>Spezifizierung</span>
    <span *inscFieldValue>{{ dating.description }}@if (dating.uncertain) {
      <span>?</span>
    }</span>
  </div>
  <div inscDataField>
    <span *inscFieldLabel>Datierungs&shy;basis</span>
    <span *inscFieldValue>{{ dating.based_on }}</span>
  </div>
  <div inscDataField layout="vertical">
    <span *inscFieldLabel>Kommentar</span>
    <span *inscFieldValue>{{ dating.comment }}</span>
  </div>
</insc-data-group>
