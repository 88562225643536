import { Component, HostListener, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormBuilder } from "@angular/forms"
import { ActivatedRoute, Router } from "@angular/router"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { FormComponent } from "../../../services/form-leave-guard.service"

import { FormService } from "../../../services/form.service"
import { InscObject } from "../../../shared/models/object.model"
import { RecordPageStateService } from "../record-page-state.service"

import { LocationingFormComponent } from "./subforms/locationing-form/locationing-form.component"

@Component({
    selector: 'insc-object-form',
    templateUrl: './object-form.component.html',
    styleUrls: ['./object-form.component.css', '../../../shared/form-styles.scss'],
    standalone: false
})

export class ObjectFormComponent implements OnInit, FormComponent, OnDestroy {

  get form() { return this.recordPageState.form }

  private unsubscribe$ = new Subject<void>()

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.form.dirty) {
      $event.returnValue = true
    }
  }



  constructor(private route: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public  formService: FormService,
    public recordPageState: RecordPageStateService<InscObject>
  ) { }

  ngOnInit() {

    this.recordPageState.form = this.formBuilder.group({
      id:                  '',
      name:                '',
      current_locationing:  LocationingFormComponent.buildFormGroup({}),
      object_genres:         [],
      object_genres_editorial_state: 'certain',
      object_types:               [],
      object_types_editorial_state: 'certain',
      object_materials:            [],
      object_materials_editorial_state: 'certain',
      object_techniques:           [],
      object_techniques_editorial_state: 'certain',
      status_editorial_state:          'certain',
      status: '',
      emblems: this.formBuilder.array([]),
      marks: this.formBuilder.array([]),
      signs: this.formBuilder.array([]),
      datings: this.formBuilder.array([]),
      literature_references: this.formBuilder.array([]),
      person_organizations: this.formBuilder.array([]),
      origins: this.formBuilder.array([]),
      iconography_descs: this.formBuilder.array([]),
      comments: this.formBuilder.array([]),
      provenience_locationings: this.formBuilder.array([])
    })

    this.recordPageState.recordChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((object) => {
      // current record changed, clear the form
      this.recordPageState.form.reset()
      if (!object) {
        return
      }

      this.recordPageState.form.patchValue(object)

      // Angular currently does not support creating nested FormArrays or FormGroups automatically using patchValue or setValue
      // so we need to map the nested groups manually to their corresponding FormGroups

      // build the current_locationing form group
      this.form.setControl("current_locationing", LocationingFormComponent.buildFormGroup(object.current_locationing))

      // for all arrays of nested form groups of the model, map them to their corresponding form groups
      const formArrayNames = ["emblems", "marks", "signs", "datings", "literature_references", "person_organizations", "origins", "iconography_descs", "comments", "provenience_locationings"]
      formArrayNames.forEach((name) => {
        this.recordPageState.form.setControl(name, this.formService.mapToFormArray(object[name], name))
      })

      this.recordPageState.form.markAsPristine()
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
