import { Component, HostListener, Input, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { FormComponent } from "../../../services/form-leave-guard.service"

import { FormService } from "../../../services/form.service"

import { Inscription } from "../../../shared/models"
import { RecordPageStateService } from "../record-page-state.service"


@Component({
    selector: 'insc-inscription-form',
    templateUrl: './inscription-form.component.html',
    styleUrls: ['../../../shared/form-styles.scss', './inscription-form.component.css'],
    standalone: false
})
export class InscriptionFormComponent implements OnInit, FormComponent, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  @Input() inscription: Inscription

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: Event): void {
    if (this.form.dirty) {
      $event.returnValue = true
    }
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    public recordPageState: RecordPageStateService<Inscription>,
    public formService: FormService
  ) { }

  get form(): UntypedFormGroup { return this.recordPageState.form }


  ngOnInit(): void {

    this.recordPageState.form = this.formBuilder.group({
      id:                '',
      name:        '',
      location_on_object:  '',
      description:         '',

      inscription_types:        [],
      inscription_materials:    '',
      inscription_techniques:  [],
      inscription_condition:   '',
      typefaces:               [],

      inscription_types_editorial_state:       'certain',
      inscription_materials_editorial_state:    'certain',
      inscription_techniques_editorial_state:  'certain',
      inscription_condition_editorial_state:   'certain',
      typefaces_editorial_state:               'certain',
      language_editorial_state:               'certain',

      language:				   '',
      verses:                  false,
      prose:                   false,

      literature_references:  this.formBuilder.array([]),
      datings:     this.formBuilder.array([]),
      comments:    this.formBuilder.array([])
    })

    this.recordPageState.recordChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(inscription => {
      this.recordPageState.form.reset()
      if (!inscription) {
        return
      }

      this.form.patchValue(inscription)

      // what happens here? --> see object-form.component.ts
      const formArrayNames = ["literature_references", "datings", "comments"]
      formArrayNames.forEach((name) => {
        this.recordPageState.form.setControl(name, this.formService.mapToFormArray(inscription[name], name))
      })

      this.form.markAsPristine()

    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}


