import { AfterViewInit, Component, ContentChildren, OnInit, QueryList } from "@angular/core"
import { FormSectionComponent } from "../form-section/form-section.component"

@Component({
    selector: 'insc-record-form-container',
    templateUrl: './record-form-container.component.html',
    styleUrls: ['./record-form-container.component.scss'],
    standalone: false
})
export class RecordFormContainerComponent implements OnInit, AfterViewInit {
  
  @ContentChildren(FormSectionComponent, {descendants: true}) formSections: QueryList<FormSectionComponent>
  
  constructor() { }

  ngOnInit() {
  }
  
  ngAfterViewInit(): void {

    this.formSections.changes.subscribe(x => console.log(x))
  }
  
}
