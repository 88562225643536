import { Component } from "@angular/core"
import { FormControl, FormGroup } from "@angular/forms"
import { OrganizationEntry } from "../../../../shared/models/organization-entry.model"

import { SubformComponent } from "../../../../shared/subform.component"
import { AddressFormComponent } from "../../shared/subforms/address-form.component"

@Component({
    selector: 'insc-organization-entry-form',
    templateUrl: 'organization-entry-form.component.html',
    styleUrls: [
        '../../shared/register-form-styles.scss',
        './shared-styles.scss',
        './organization-entry-form.component.scss'
    ],
    standalone: false
})
export class OrganizationEntryFormComponent extends SubformComponent {

  static buildFormGroup(organizationEntry: OrganizationEntry) {
    return new FormGroup({
      id:      new FormControl(organizationEntry?.id || null),
      abbreviation: new FormControl(organizationEntry?.abbreviation || ""),
      name:    new FormControl(organizationEntry?.name || ""),
      address: AddressFormComponent.buildFormGroup(organizationEntry?.address)
    })
  }
}
