import { Component, Input, OnInit } from "@angular/core"

import { Emblem } from "../../../../shared/models"

@Component({
    selector: 'insc-emblem-view',
    templateUrl: './emblem-view.component.html',
    styleUrls: ['./emblem-view.component.css'],
    standalone: false
})
export class EmblemViewComponent implements OnInit {
  @Input() emblem: Emblem

  constructor() { }

  ngOnInit() {
  }

}
