// https://material.angular.io/components/datepicker/overview#customizing-the-calendar-header

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from "@angular/core"
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core"
import { MatCalendar } from "@angular/material/datepicker"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"

@Component({
    selector: 'insc-year-only-header',
    template: `
    <div class="header">
      <button mat-icon-button (click)="previousClicked()">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="header-label">Jahr auswählen</span>
      <button mat-icon-button (click)="nextClicked()">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
    styles: [`
    .header {
      display: flex;
      align-items: center;
      padding: 0.5em;
    }

    .header-label {
      flex: 1;
      height: 1em;
      font-weight: 500;
      text-align: center;
    }
  `],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class YearOnlyHeaderComponent<D> implements OnDestroy {
  private destroyed = new Subject<void>()

  constructor(private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef) {

    calendar.stateChanges
      .pipe(takeUntil(this.destroyed))
      .subscribe(() => cdr.markForCheck())
  }

  ngOnDestroy(): void {
    this.destroyed.next()
    this.destroyed.complete()
  }

  previousClicked(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, -24)
  }

  nextClicked(): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, +24)
  }

}
