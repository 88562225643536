import { AsyncPipe, NgTemplateOutlet } from "@angular/common";
import { AfterViewInit, Component, Inject, ViewChild } from "@angular/core"
import { MatButtonModule } from "@angular/material/button"
import { MatCheckboxChange, MatCheckboxModule } from "@angular/material/checkbox"
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog"
import { MatIconModule } from "@angular/material/icon"
import { Observable, of } from "rxjs"
import { switchMap } from "rxjs/operators"
import { ImageService, QueryParams, QueryResults } from "../../../services/data.service"
import { RecordPickerComponent } from "../../../shared/components/record-picker/record-picker.component"
import { InscImage } from "../../../shared/models/image.model"
import { ImageMetadataEditorsModule } from "../image-metadata-editors/image-metadata-editors.module"
import { MultiImageEditorComponent } from "../image-metadata-editors/multi-image-editor/multi-image-editor.component"
import { MultiRecordFormArray } from "../image-metadata-editors/shared/multi-record-form-manager"

export interface ImageMetadataPickerDialogData {
  editedImages: Partial<InscImage>[]
}

export interface ImageMetadataPickerDialogResult<T = any> {
  imageUpdates: Partial<T>[]
  updatedValues: Partial<T>
}

export type ImageMetadataPickerDialogRef = MatDialogRef<ImageMetadataPickerDialogComponent, ImageMetadataPickerDialogResult>

@Component({
    selector: "insc-image-metadata-picker-dialog",
    templateUrl: "./image-metadata-picker-dialog.component.html",
    styleUrls: ["./image-metadata-picker-dialog.component.scss"],
    imports: [
        MatButtonModule,
        NgTemplateOutlet,
        ImageMetadataEditorsModule,
        MatDialogModule,
        MatIconModule,
        RecordPickerComponent,
        MatCheckboxModule,
        AsyncPipe
    ]
})
export class ImageMetadataPickerDialogComponent implements AfterViewInit {

  selectedImage: Observable<InscImage>

  get editedImages(): Partial<InscImage>[] { return this.data.editedImages }

  @ViewChild(RecordPickerComponent) recordPicker: RecordPickerComponent<InscImage>
  @ViewChild("originImageView") originImageView: MultiImageEditorComponent
  @ViewChild("editedImagesView") editedImagesView: MultiImageEditorComponent

  constructor(
    private imageService: ImageService,
    @Inject(MAT_DIALOG_DATA) private data: ImageMetadataPickerDialogData,
    @Inject(MatDialogRef) private dialogRef: ImageMetadataPickerDialogRef
  ) {}

  recordPickerGetSearchResults = (queryParams: QueryParams): Observable<QueryResults<InscImage>> => this.imageService.all(queryParams)


  ngAfterViewInit(): void {
    this.selectedImage = this.recordPicker.selectedRecordsChange.pipe(
      switchMap(recordIds => {
        const selectedImageId = recordIds[0]
        if (!selectedImageId) {
          return of(null as InscImage)
        }

        return this.imageService.clone(selectedImageId)
      })
    )
  }

  onGroupAccesoryClick(checkboxChange: MatCheckboxChange, key: string, item: never): void {
    if (!key) {
      throw new Error("Group accessory key not set!")
    }

    const multiArray = this.editedImagesView.multiRecordFormManager.get(key) as MultiRecordFormArray
    if (checkboxChange.checked) {
      multiArray.addCommonItems([item])
    } else {
      multiArray.removeCommonItem(item)
    }
  }

  onFieldAccessoryClick(checkboxChange: MatCheckboxChange, key: string): void {
    if (!key) {
      throw new Error("Field accessory key not set!")
    }

    if (checkboxChange.checked) {
      const originValues = this.originImageView.multiRecordFormManager.getCommonValues()
      this.editedImagesView.multiRecordFormManager.updateWithValuesFrom({[key]: originValues[key]})
    } else {
      this.editedImagesView.multiRecordFormManager.reset(key)
    }
  }

  onApply(): void {
    this.dialogRef.close({
      imageUpdates: this.editedImagesView.getUpdates(),
      updatedValues: this.editedImagesView.multiRecordFormManager.getCommonUpdatedValues()
    })
  }



}
