import { Component, OnDestroy, OnInit } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms"
import { Subject } from "rxjs"
import { takeUntil } from "rxjs/operators"
import { IptcValue } from "../iptc-dataset.service"

export type IptcFormFieldValueState = "generatedValue" | "manualValue" | "notSet"

@Component({
    selector: 'insc-iptc-form-field-wrapper',
    templateUrl: './iptc-form-field-wrapper.component.html',
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: IptcFormFieldWrapperComponent,
            multi: true
        }],
    exportAs: "iptcControl",
    standalone: false
})
export class IptcFormFieldWrapperComponent implements ControlValueAccessor, OnInit, OnDestroy {
  readonly innerControl = new UntypedFormControl()

  get iptcValue(): IptcValue<any> { return this._iptcValue }
  private _iptcValue: IptcValue<any>

  private onChange = (_iptcValue) => {}

  private unsubscribe$ = new Subject<void>()

  get state(): IptcFormFieldValueState {
    if (!this._iptcValue) {
      return "notSet"
    }

    return this._iptcValue.isUntouched
           ? "generatedValue"
           : "manualValue"
  }

  ngOnInit(): void {
    this.innerControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(value => {
        this._iptcValue = new IptcValue({generated: this._iptcValue?.generated, value})
        this.onChange(this._iptcValue)
    })
  }

  // ControlValueAccessor

  writeValue(iptcValue: IptcValue<any>): void {
    const innerValue = iptcValue?.value ?? iptcValue?.generated
    this.innerControl.setValue(innerValue, {emitEvent: false})
    this._iptcValue = iptcValue
  }

  resetToGenerated() {
    this.writeValue(new IptcValue({
      value: undefined,
      generated: this._iptcValue?.generated
    }))
    this.onChange(this._iptcValue)
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(_fn: any): void {
  }

  setDisabledState(_isDisabled: boolean): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
