
import { AfterContentInit, AfterViewInit, Component, HostBinding, Inject, OnDestroy, OnInit, ViewChild, } from "@angular/core"
import { MatButton, MatButtonModule } from "@angular/material/button"
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from "@angular/material/dialog"
import { MatDividerModule } from "@angular/material/divider"
import { MatTabGroup, MatTabsModule } from "@angular/material/tabs"

import { merge, Subject } from "rxjs"
import { filter, takeUntil } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"
import { NormDataEntry } from "../../../shared/models/norm-data-entry.model"
import { BasicRegisterEditorModule } from "../basic-register-editor/basic-register-editor.module"

import { LocationRegisterEditorModule } from "../location-register-editor/location-register-editor.module"
import { RegisterEditorBaseAbstractComponent } from "../register-editor-base-abstract.component"

export type RegisterEntryDialogRef<T = NormDataEntry> = MatDialogRef<RegisterEntryDialogComponent, NormDataEditorDialogResult<T>>

export type NormDataEntrySelection = Pick<NormDataEntry, "type" | "id">

export interface RegisterEditorDialogOptions {
  types: string[]
  allowNN?: boolean
  selectEntry?: NormDataEntrySelection
}

export interface NormDataEditorDialogResult<T> {
  entry: T
}

@Component({
    selector: "insc-dialog-result-example-dialog",
    templateUrl: "./register-entry-dialog.component.html",
    styleUrls: ["./register-entry-dialog.component.scss"],
    imports: [
        MatDialogModule,
        BasicRegisterEditorModule,
        MatTabsModule,
        LocationRegisterEditorModule,
        MatButtonModule,
        MatDividerModule
    ]
})
export class RegisterEntryDialogComponent implements AfterViewInit, OnInit, OnDestroy, AfterContentInit {

  private unsubscribe$ = new Subject<void>()

  @ViewChild("cancelButton", { static: true }) cancelButton: MatButton
  @ViewChild("chooseButton", { static: true }) chooseButton: MatButton

  @ViewChild('editorComp') editor: RegisterEditorBaseAbstractComponent
  @ViewChild(MatTabGroup) tabGroup: MatTabGroup

  editorLoaded = false

  @HostBinding("attr.class") class = "dialog-result-example-dialog"

  dialogOptions: RegisterEditorDialogOptions
  allowEdit: boolean

  readonly tabLabels = {
    persons: "Personen",
    organizations: "Organisationen" ,
    historical_persons: "Historische Personen",
    historical_organizations: "Historische Organisationen",
    locations: "Standorte" ,
    literature_entries: "Literatur",
    licenses: "Bildlizenzen"
  }

  constructor(
    public dialogRef: MatDialogRef<RegisterEntryDialogComponent, NormDataEditorDialogResult<NormDataEntry>>,
    private dialog: MatDialog,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) private data: RegisterEditorDialogOptions,
    private reusableDialogService: ReusableDialogsService
  ) {
    this.dialogOptions = this.data
  }

  ngOnInit(): void {
    this.auth.permission("update_knowledge")
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(hasPermission => this.allowEdit = hasPermission)
  }

  onChooseButtonClick() {
    const entry = this.editor.getSelectedEntry()
    this.close({ entry })
  }

  close(result: NormDataEditorDialogResult<NormDataEntry> | null) {
    if (this.editor.formDirty) {
      const confirmDialogRef = this.reusableDialogService.openUnsavedChangesConfirmationDialog()
      confirmDialogRef.afterClosed().subscribe(dialogResult => dialogResult === true && this.dialogRef.close(result))
    } else {
      return this.dialogRef.close(result)
    }
  }

  ngAfterViewInit() {
    const closingActions = merge(
      this.dialogRef.backdropClick(),
      this.dialogRef.keydownEvents().pipe(filter(event => event.key === "Escape"))
    ).pipe(takeUntil(this.unsubscribe$))

    closingActions.subscribe(() => {
      this.close(null)
    })

    setTimeout(() => {
      this.editorLoaded = true
    })
  }

  ngAfterContentInit() {

  }

  canSelect(): boolean {
    if (!this.editor) {
      return null
    }

    if (this.editor.selectedId == null) {
      return this.editor.allowNN
    }

    const selectedEntry = this.editor.getSelectedEntry()
    if (selectedEntry && ["City", "Location", "SubLocation", "PrincipalTown"].includes(selectedEntry.type)) {
      return false
    }

    return true
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  getCollection(entry: NormDataEntrySelection) {
    switch (entry?.type) {
      case "Person": return "persons"
      case "Organization": return "organizations"
      case "HistoricalPerson": return "historical_persons"
      case "HistoricalOrganization": return "historical_organizations"
      case "LiteratureEntry": return "literature_entries"
      case "License": return "licenses"
      case null:
      case undefined:
        return undefined
      default: return "locations"
    }
  }

  getSelectedId(editorType: string) {
    if (editorType === this.getCollection(this.dialogOptions.selectEntry)) {
      return this.dialogOptions.selectEntry?.id
    }
  }

  getSelectedIndex() {
    const type = this.getCollection(this.dialogOptions.selectEntry)
    if (type) {
      return this.dialogOptions.types.indexOf(type)
    }
  }

}
