import { Component } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"

import { SubformComponent } from "../../../../shared/subform.component"

@Component({
    selector: 'insc-iconography-desc-form',
    templateUrl: 'iconography-desc-form.component.html',
    styles: [`
           :host {
             display: flex;
             flex-direction: column;
           }
           `],
    standalone: false
})
export class IconographyDescFormComponent extends SubformComponent {

  static buildFormGroup(iconography, destroyable = true) {
    return new UntypedFormGroup({
      id:          new UntypedFormControl(iconography.id || null),
      iconography_topic:	     new UntypedFormControl(iconography.iconography_topic || ""),
      comment:     new UntypedFormControl(iconography.comment || ''),
      ...this.destroyableControl(iconography.id, destroyable)
    })
  }
}
