import { Component, Inject, OnInit } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { QueryDatingValue } from "../../../../../services/data.service"
import { DatingSpecification } from "../../../../models/dating.model"

export interface DatingSearchDialogResult {
  datingParams: QueryDatingValue
  description: string
}
export type DatingSearchDialogRef = MatDialogRef<DatingSearchDialogComponent, DatingSearchDialogResult>

@Component({
    selector: 'insc-dating-search-dialog',
    templateUrl: './dating-search-dialog.component.html',
    styleUrls: ['./dating-search-dialog.component.scss'],
    standalone: false
})
export class DatingSearchDialogComponent implements OnInit {

  optionsFormGroup = new UntypedFormGroup({
    intersects: new UntypedFormControl(false)
  })

  initialDatingSpecification: DatingSpecification | null

  closedRange = true

  constructor(
    @Inject(MatDialogRef) private dialogRef: DatingSearchDialogRef,
    @Inject(MAT_DIALOG_DATA) public data: QueryDatingValue | null) {}


  ngOnInit(): void {
    if (this.data && this.data) {
      this.data.intersects && this.optionsFormGroup.setValue({
        intersects: this.data.intersects === "false" ? false : this.data.intersects
      })

      this.initialDatingSpecification = {
        primary: this.data.primary,
        secondary: this.data.secondary,
        range_modifier: this.data.range_modifier
      }
    }
  }

  onChangeDatingSpecification(spec: DatingSpecification): void {
    if (!spec) { return }

    this.closedRange = spec.range_modifier === "closed"
  }

  closeWithResult(spec: DatingSpecification): void {

    this.dialogRef.close({
      datingParams: {
        primary:        spec.primary,
        secondary:      spec.secondary || null,
        range_modifier: spec.range_modifier,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
        intersects:     this.optionsFormGroup.value["intersects"],
      },
      description:      spec.description
    })
  }


}
