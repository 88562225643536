<input #fileInput type="file" (change)="onSelectImageFiles(fileInput)" accept=".tif,.tiff" multiple style="display: none;">

<div class="image-management-container" inscImageManagement="uploads" (imageSelectionChange)="onImageSelectionChange($event)">
  <div class="toolbar">
    @if (uploadQueue.length > 0) {
      <div class="toolbar-info">
        <em>{{imageManagementGroup?.selectedImages().length}} von {{uploadQueue.length}} ausgewählt</em>
        <mat-divider class="divider" vertical></mat-divider>
        <em>{{itemsForUpload.length}} {{itemsForUpload.length | i18nPlural: imagesPluralMapping}} hochgeladen</em>
      </div>
    }

    @if (uploadQueue.length === 0) {
      <button mat-icon-button class="selection-button" disabled><mat-icon>check_box_outline_blank</mat-icon></button>
    }
    @if (uploadQueue.length > 0) {
      @switch (selectionStatus) {
        @case ('none') {
          <button mat-icon-button class="selection-button" (click)="selectAll()" matTooltip="Alle auswählen"><mat-icon>check_box_outline_blank</mat-icon></button>
        }
        @case ('some') {
          <button mat-icon-button class="selection-button" (click)="imageManagementGroup.deselectAll()" matTooltip="Alle abwhälen"><mat-icon>indeterminate_check_box</mat-icon></button>
        }
        @case ('all') {
          <button mat-icon-button  class="selection-button" (click)="imageManagementGroup.deselectAll()" matTooltip="Alle abwählen"><mat-icon>check_box</mat-icon></button>
        }
      }
    }
    <mat-divider class="divider" vertical></mat-divider>
    <button [disabled]="isBusy()" mat-button (click)="fileInput.click()" color="primary"><mat-icon>add_photo_alternate</mat-icon><span>Bilddateien hinzufügen</span></button>
  </div>
  <insc-image-management-group imageGroupDescriptor="uploads" [images]="inscImages">
    @for (item of uploadQueue; track item; let i = $index) {
      <div class="image-tile-wrap">
        @if (canBeRemoved(item)) {
          <div class="remove-button">
            <mat-icon class="remove-icon" matRipple matTooltip="Aus Uploadliste entfernen" (click)="removeItem(i)">cancel</mat-icon>
          </div>
        }
        <insc-image-tile #imageTile
                         [image]="item.inscImage"
                         [disableSelect]="item.status === 'finished'"
        >
          <div class="tile-overlay" [ngClass]="{busy: uploadItemIsBusy(item)}">
            @switch (item.status) {
              @default {
                @if (item.status === 'busy') {
                  <mat-progress-spinner diameter="40"
                  mode="indeterminate"></mat-progress-spinner>
                }
                @if (item.status === 'uploading') {
                  <mat-progress-spinner diameter="40" mode="determinate"
                  [value]="item.progress"></mat-progress-spinner>
                }
                <div class="status-text">@if (item.statusText) {
                  <em
                  >{{ item.statusText }}</em>
                }</div>
              }
              @case ('finished') {
                <div class="status-icon-container">
                  <mat-icon class="status-icon finished">done</mat-icon>
                </div>
              }
              @case ('previewError') {
                <div class="status-icon-container" matTooltip="Vorschau konnte nicht erstellt werden. Fehlerhafte TIF-Datei?">
                  <mat-icon class="status-icon preview-error">help</mat-icon>
                </div>
              }
              @case ('uploadError') {
                <div class="status-icon-container" [matTooltip]="item.tooltip || 'Hochladen fehlgeschlagen.'">
                  <mat-icon class="status-icon upload-error" color="warn">cancel</mat-icon>
                </div>
              }
              @case ('validationError') {
                <div class="status-icon-container" [matTooltip]="item.tooltip || 'Ungültige Datei.'">
                  <mat-icon class="status-icon upload-error" color="warn">cancel</mat-icon>
                </div>
              }
              @case ('uploadCancelled') {
                <div class="status-icon-container" matTooltip="Hochladen abgebrochen.">
                  <mat-icon class="status-icon upload-error" color="warn">cancel</mat-icon>
                </div>
              }
            }
          </div>
        </insc-image-tile>
        <div class="image-name" [ngClass]="{selected: imageTile.isSelected()}">
          <span>{{item.inscImage.name}}</span>
        </div>
      </div>
    }
  </insc-image-management-group>
  <div class="action-buttons">
    @if (isPreviewGenerationActive()) {
      <button color="warn" mat-button (click)="cancelPreviewGeneration()"><mat-icon>cancel</mat-icon><span>Hinzufügen abbrechen</span></button>
    }
    @if (isUploadActive()) {
      <button color="warn" mat-button (click)="cancelUpload()"><mat-icon>cancel</mat-icon><span>Hochladen abbrechen</span></button>
    }

    <button [disabled]="itemsForUpload.length === 0 || isBusy()" mat-button color="primary" (click)="onUpload()"><mat-icon>publish</mat-icon><span>Hochladen</span></button>
  </div>
</div>

<mat-divider vertical></mat-divider>

<div class="side">
  @if (selectedImages?.length > 0) {
    <button mat-button color="primary" class="metadata-picker-button" (click)="openMetadataPicker()">
      <mat-icon>keyboard_tab</mat-icon>
      <span>Aus bestehendem Datensatz übernehmen...</span>
    </button>
  }
  @if (selectedImages?.length > 0) {
    <insc-multi-image-editor class="metadata-view" [editable]="true" [images]="selectedImages" (changes)="onFormChanges($event)"></insc-multi-image-editor>
  }
  @if (selectionStatus === 'none') {
    <div class="sidebar-empty-view">
      <em>Keine Dateien ausgewählt</em>
    </div>
  }

  <mat-divider></mat-divider>

  <div class="error-console">
    <div class="console-header" (click)="showErrorConsole = !showErrorConsole" matRipple>
      @if (showErrorConsole) {
        <mat-icon inline>expand_less</mat-icon> Protokoll ausblenden
      }
      @if (!showErrorConsole) {
        <mat-icon inline>expand_more</mat-icon> Protokoll einblenden
      }
    </div>
    @if (showErrorConsole) {
      <textarea class="console-textarea" readonly [value]="errorConsoleText"></textarea>
    }
  </div>
</div>
