import { Component, Input, OnInit } from "@angular/core"

import { Sign } from "../../../../shared/models"

@Component({
    selector: 'insc-sign-view',
    templateUrl: './sign-view.component.html',
    styleUrls: ['./sign-view.component.css'],
    standalone: false
})
export class SignViewComponent implements OnInit {
  @Input() sign: Sign

  constructor() { }

  ngOnInit() {
  }

}
