import { Component, forwardRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup, ValidationErrors } from "@angular/forms"
import { Subject } from "rxjs"
import { distinctUntilChanged, takeUntil } from "rxjs/operators"
import { QueryRecordingDateParams } from "../../../../services/data.service"
import { CriteriumDescription, INSC_SEARCH_CONTROL, SearchControlDirective } from "../search-control.directive"
import { SearchControl } from "../search-control.interface"

type RecordingDateParams = QueryRecordingDateParams["recording_date"]

@Component({
    selector: 'insc-recording-date-search-control',
    templateUrl: './recording-date-search-control.component.html',
    styleUrls: ['./recording-date-search-control.component.scss'],
    providers: [{
            provide: INSC_SEARCH_CONTROL,
            useExisting: forwardRef(() => RecordingDateSearchControlComponent)
        }],
    standalone: false
})
export class RecordingDateSearchControlComponent implements OnInit, OnChanges, OnDestroy, SearchControl<RecordingDateParams, RecordingDateParams> {
  @HostBinding("class") transparentTextFieldClass = "insc-transparent-mdc-text-field"
  @Input() disabled: boolean

  @Input() resultParams: RecordingDateParams
  searchControlDirective: SearchControlDirective<RecordingDateParams, RecordingDateParams>

  remove(): void {
    this.params = null
  }

  private _params: RecordingDateParams
  get params(): RecordingDateParams { return this._params }
  set params(value: RecordingDateParams) {
    this._params = value
    this.searchControlDirective.emitCriteriaChange(this.recordingDateParamsToCriteria(value))
    this.searchControlDirective.emitParamsChange(value)
  }

  private unsubscribe$ = new Subject<void>()

  recordingDateForm = new UntypedFormGroup({
    type: new UntypedFormControl("year"),
    date_earliest: new UntypedFormControl(),
    date_latest: new UntypedFormControl()
  }, (group: UntypedFormGroup): ValidationErrors => {

    // TODO: instead of validation, prevent invalid selection in picker (min-max)

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    if (group.value['date_earliest'] && group.value['date_latest']) {
      if (group.value['date_earliest'].year > group.value['date_latest'].year) {
        return { invalid_range: true }
      }
    }
    /* eslint-enable @typescript-eslint/no-unsafe-member-access */


    return {}
  })

  constructor() { }

  ngOnInit(): void {

    this.recordingDateForm.controls["type"].valueChanges.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe( type => {
      if (type === "exact") {
        this.recordingDateForm.patchValue({date_latest: null}, {emitEvent: false, onlySelf: true})
      }
    })

    this.recordingDateForm.valueChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.unsubscribe$)
    ).subscribe(formValue => {
      if (this.recordingDateForm.status === "VALID") {
        this.params = this.formValueToRecordingDateParams(formValue)
      }
    })

    // preset earliest date for UX reasons
    // this.recordingDateForm.controls["date_earliest"].valueChanges.subscribe(date_earliest => {
    //   const latestCtrl = this.recordingDateForm.controls['date_latest']
    //   if (!latestCtrl.value || latestCtrl.value.year < date_earliest.year) {
    //     this.recordingDateForm.get("date_latest").setValue(date_earliest,  {emitEvent: false})
    //   }
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('resultParams' in changes) {

      if (!this.resultParams) {
        this.resetForm()
        return
      }

      const recordingDateParams = this.resultParams

      if (recordingDateParams.year_earliest === null && recordingDateParams.year_latest === null) {
        this.resetForm()
      } else if (recordingDateParams.year_earliest === recordingDateParams.year_latest && recordingDateParams.exact) {

        const year = recordingDateParams.year_earliest
        const date = year && {year: recordingDateParams.year_earliest, month: null, day: null}

        this.recordingDateForm.setValue({
          type: "year",
          date_earliest: date,
          date_latest: null
        }, {emitEvent: false, onlySelf: true})
      } else {

        const {year_earliest, year_latest} = recordingDateParams

        this.recordingDateForm.setValue({
          type: "range",
          date_earliest: year_earliest && {year: year_earliest, month: null, day: null},
          date_latest: year_latest && {year: year_latest, month: null, day: null}
        }, {emitEvent: false, onlySelf: true})
      }
    }

    if("disabled" in changes) {
      // TODO: test disabled state
      this.disabled ? this.recordingDateForm.disable() : this.recordingDateForm.enable()
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  private formValueToRecordingDateParams = ({type, date_earliest, date_latest}) => {
    /* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */
    const dateParams = type === "year"
      ? {year_earliest: date_earliest && date_earliest.year, year_latest: date_earliest && date_earliest.year, exact: true}
      : {year_earliest: date_earliest && date_earliest.year, year_latest: date_latest && date_latest.year}
    /* eslint-enable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access */

    if (dateParams.year_earliest === null && dateParams.year_latest === null) {
      return null
    }

    return dateParams
  }

  private recordingDateParamsToCriteria(recordingDateParams: RecordingDateParams): CriteriumDescription<RecordingDateParams>[] {
    if (!recordingDateParams) {
      return []
    }

    return [{
      name: this.searchControlDirective.inscSearchControl,
      displayName: this.searchControlDirective.searchControlDisplayName,
      value: recordingDateParams,
      displayValue: this.getCriteriumDisplayValue(recordingDateParams)
    }]
  }

  private getCriteriumDisplayValue(recordingDateParams: RecordingDateParams): string {
    if (recordingDateParams.exact) {
      return `${recordingDateParams.year_earliest}`
    } else {
      if (recordingDateParams.year_earliest && recordingDateParams.year_latest) {
        return `zwischen ${recordingDateParams.year_earliest} und ${recordingDateParams.year_latest}`
      } else if (recordingDateParams.year_earliest) {
        return `frühestens ${recordingDateParams.year_earliest}`
      } else {
        return `spätestens ${recordingDateParams.year_latest}`
      }
    }
  }

  private resetForm() {
    this.recordingDateForm.patchValue({date_earliest: null, date_latest: null}, {emitEvent: false, onlySelf: true})
  }
}
