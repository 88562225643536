import { Component, EventEmitter, Input, Output, ViewEncapsulation } from "@angular/core"
import { MatPaginatorIntl, PageEvent } from "@angular/material/paginator"
import { MatPaginatorIntlDE } from "../../mat-paginator-intl-de"

export interface PageParams {
  page: number
  pageSize: number
  count: number
}

export class PageChangeEvent {
  constructor(readonly page: number, readonly pageSize: number) {}
}

@Component({
    selector: 'insc-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    providers: [
        { provide: MatPaginatorIntl, useClass: MatPaginatorIntlDE }
    ],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class PaginatorComponent {

  @Input() pageParams: PageParams
  @Output() pageChanges = new EventEmitter<PageChangeEvent>()

  constructor() { }

  onPageChange(event: PageEvent): void {
    const pageChangeEvent = new PageChangeEvent(event.pageIndex + 1, event.pageSize)
    this.pageChanges.emit(pageChangeEvent)
  }

}
