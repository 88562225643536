import { AfterContentInit, Component, ContentChildren, forwardRef, Input, OnChanges, QueryList, SimpleChanges } from "@angular/core"

import { merge } from "rxjs"
import { map, startWith, switchMap } from "rxjs/operators"
import { FacetResults, FilterValues } from "../../../../services/data.service"
import { CriteriumDescription, INSC_SEARCH_CONTROL, SearchControlDirective } from "../search-control.directive"
import { SearchControl } from "../search-control.interface"
import { FacetValueFlatTreeBuilder } from "./facet-value-tree-builder.service"
import { FacetComponent } from "./facet/facet.component"

export interface FacetCriteriumValue {
  field: string
  value: string
}

@Component({
    selector: 'insc-facet-list-search-control',
    templateUrl: "./facet-list-search-control.component.html",
    styleUrls: ["./facet-list-search-control.component.scss"],
    providers: [{
            provide: INSC_SEARCH_CONTROL,
            useExisting: forwardRef(() => FacetListSearchControlComponent)
        }],
    standalone: false
})
export class FacetListSearchControlComponent implements AfterContentInit, OnChanges, SearchControl<FilterValues, FacetCriteriumValue, FilterValues> {
  // TODO: implement disabled state in facet components
  @Input() disabled = false

  // the current search parameters as sent by the backend
  @Input() resultParams: FilterValues

  // facet results form the search response
  @Input() facetResults: FacetResults

  searchControlDirective: SearchControlDirective<FilterValues, FacetCriteriumValue>
  @ContentChildren(FacetComponent) facetComponents: QueryList<FacetComponent>

  // TODO: do we need to store the params?
  private _params: FilterValues
  get params(): FilterValues { return this._params }
  set params(value: FilterValues) {
    this._params = value
    this.searchControlDirective.emitCriteriaChange(this.criteriaFromFilterValues(this.params))
    this.searchControlDirective.emitParamsChange(value)
  }

  constructor(private facetValueTreeBuilder: FacetValueFlatTreeBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    // new facet results coming in from the backend
    // update the facet UI accordingly
    if ('facetResults' in changes && this.facetResults) {
      this.setFacetValues(this.facetResults)
    }

    // updated search parameters coming in from the backend
    // emit new criteriaChange$
    if ('resultParams' in changes) {
      this._params = this.resultParams
      this.searchControlDirective.emitCriteriaChange(this.criteriaFromFilterValues(this.params))
    }
  }


  ngAfterContentInit(): void {
    // after the facet components initialize, subscribe to their change events,
    // collect selected values from all of them and emit a search param change
    this.facetComponents.changes.pipe(
      startWith(this.facetComponents),
      // get the selectionChanges observable from each facet component
      map((facetComponents: FacetComponent[]) => facetComponents.map(facetComponent => facetComponent.selectionChanges)),
      // merge them to one observable and subscribe to it
      switchMap(facetSelectionChanges => merge(...facetSelectionChanges)),
    ).subscribe(_ => {
      // after a selection or deselection, get selections from all facets
      // and generate search parameters
      const filterEntries: {[fieldName: string]: string[]}[] = this.facetComponents
        .map(component => ({field: component.field, selectedKeyPaths: component.selectedKeyPaths}))
        .filter(({selectedKeyPaths}) => selectedKeyPaths?.length > 0)
        .map(({field, selectedKeyPaths}) => ({
          [field]: selectedKeyPaths
        }))

      // combine the collected array of objects
      this.params = filterEntries.length > 0 ? Object.assign({}, ...filterEntries) as FilterValues : undefined
    })
  }

  remove(criterium: CriteriumDescription<FacetCriteriumValue>): void {
    const facetField = this.facetComponents.find(testFacetField => testFacetField.field === criterium.value.field)
    facetField.deselectKeyPath(criterium.value.value)
  }

  // for each facet, build a tree structure of hierarchical values
  // suitable for display in a facet component
  private setFacetValues(facetResults: FacetResults): void {
    this.facetComponents?.forEach((facetComponent) => {
      const fieldValues = facetResults[facetComponent.field]
      facetComponent.valueTreeNodes = this.facetValueTreeBuilder.buildFacetValueTree(fieldValues)
    })
  }

  // generate a list of criterium descriptions for all facet values
  // each selected value in a facets corresponds to one criterium
  private criteriaFromFilterValues(filterValues: FilterValues): CriteriumDescription<FacetCriteriumValue>[] {
    if (!filterValues) {
      return []
    }

    return Object
      .entries(filterValues)
      .flatMap(
        ([facetName, selectedValues]) => this.selectedValuesToCriteria(facetName, selectedValues)
      )
  }

  // for one facet, convert a list of selected values to a list of corresponding criterium descriptions
  private selectedValuesToCriteria(facetName: string, selectedValues: string[]): CriteriumDescription<FacetCriteriumValue>[] {
    const facetComponent = this.facetComponents
      .find(facetField => facetField.field === facetName)
    return selectedValues.map(value => ({
      name: this.searchControlDirective.inscSearchControl,
      displayName: facetComponent?.title || facetName,
      value: { field: facetName, value: value },
      displayValue: value.split("|").join(", ")
    }))
  }
}
