import { Component, OnDestroy } from "@angular/core"
import { UntypedFormGroup } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { ActivatedRoute, Router } from "@angular/router"
import { Subject } from "rxjs"
import { filter, map, switchMap, takeUntil } from "rxjs/operators"
import { AuthService } from "../../../services/auth.service"
import { InscObjectService, ObjectGroupService } from "../../../services/data.service"
import { FormService } from "../../../services/form.service"
import { LinkingService } from "../../../services/linking.service"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"

import { InscObject, ObjectGroup } from "../../../shared/models"

import { SnackbarService } from "../../../shared/snackbars/snackbar.service"
import { RecordPageStateService, recordPageStateServiceFactory } from "../record-page-state.service"

import { ChooseObjectGroupDialogComponent, ChooseObjectGroupDialogRef } from "./dialogs/choose-object-group-dialog.component"
import {
  CreateOrEditObjectGroupDialogComponent,
  CreateOrEditObjectGroupDialogRef
} from "./dialogs/create-or-edit-object-group-dialog.component"
import { LinkListComponent, LinkListLink, LinkListReorderEvent } from "./link-list.component"


@Component({
    selector: 'insc-object-page',
    templateUrl: './object-page.component.html',
    styleUrls: ['./object-page.component.scss'],
    providers: [{
            provide: RecordPageStateService,
            useFactory: recordPageStateServiceFactory,
            deps: [InscObjectService, SnackbarService, FormService, Router, ActivatedRoute]
        }],
    standalone: false
})
export class ObjectPageComponent implements OnDestroy {

  private unsubscribe$ = new Subject<void>()

  get insc_object(): InscObject { return this.recordPageState.record }
  get form(): UntypedFormGroup { return this.recordPageState.form }

  hasEditPermission = this.auth.permission('update_records')

  inscriptionLinks$ = this.recordPageState.recordChanges.pipe(
    map(object => this.getInscriptionLinks(object)),
    takeUntil(this.unsubscribe$)
  )

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private inscObjectService: InscObjectService,
    private objectGroupService: ObjectGroupService,
    private linkingService: LinkingService,
    private dialog: MatDialog,
    private auth: AuthService,
    private recordPageState: RecordPageStateService<InscObject>,
    private reusableDialogs: ReusableDialogsService
  ) {
  }


  delete(): void {
    this.reusableDialogs.openConfirmationDialog(
      () => void this.recordPageState.delete({commands: ["/"]}),
      {
        message: `Inschriftenträger ${this.insc_object.name || this.insc_object.id} wirklich löschen?`,
        yesAction: "INSCHRIFTENTRÄGER LÖSCHEN",
        noAction: "INSCHRIFTENTRÄGER BEHALTEN",
        icon: "delete"}
    )
  }

  save(): void {
    void this.recordPageState.processSaveResult(
      this.inscObjectService.save(this.recordPageState.formValue),
      () => this.router.navigate(["/objects", this.insc_object.id])
    )
  }

  // cannot get the routerLink [state] property to work, the state is never set
  //  as a workaround navigate programmatically
  newInscriptionHere(): void {
    void this.router.navigate(["inscriptions", "new"], { relativeTo: this.route })
  }

  // ***** OBJECT GROUPS

  linkObjectGroup(): void {

    // if the record already has an object group, pass it to the dialog so it can be preselected
    const currentObjectGroupId = this.insc_object.object_group ? this.insc_object.object_group.id : null
    const dialogRef: ChooseObjectGroupDialogRef = this.dialog.open(ChooseObjectGroupDialogComponent, {
      data: { selected_id: currentObjectGroupId }
    })

    dialogRef.afterClosed().pipe(
      filter(result => result != null),
      switchMap(result => result.object_group_id
                          ? this.linkingService.link("object", this.insc_object.id, "object_group", result.object_group_id, "insc_objects")
                          : this.linkingService.unlink("object", this.insc_object.id, "object_group", currentObjectGroupId, "insc_objects")
      ),
      switchMap(() => this.inscObjectService.get(this.insc_object.id))
    ).subscribe(object => this.recordPageState.record = object)
  }

  unlinkObjectGroup(): void {
    const currentObjectGroupId = this.insc_object.object_group.id
    this.linkingService
      .unlink("object", this.insc_object.id, "object_group", currentObjectGroupId, "insc_objects")
      .pipe(switchMap(() => this.inscObjectService.get(this.insc_object.id)))
      .subscribe(inscObject => this.recordPageState.record = inscObject)
  }

  linkNewObjectGroup(): void {
    const dialogRef: CreateOrEditObjectGroupDialogRef = this.dialog.open(CreateOrEditObjectGroupDialogComponent)

    dialogRef.afterClosed().pipe(
      filter(result => result != null),
      map(result => ({
        name: result.name,
        object_group_type: result.object_group_type
      } as Partial<ObjectGroup>)),
      switchMap(objectGroupData => this.objectGroupService.saveForInscObject(objectGroupData, this.insc_object.id))
    ).subscribe(objectGroup => this.updateLocalObjectGroup(objectGroup))
  }

  renameObjectGroup(): void {
    const dialogRef: CreateOrEditObjectGroupDialogRef = this.dialog.open(CreateOrEditObjectGroupDialogComponent, {data: {object_group: this.insc_object.object_group}})

    dialogRef.afterClosed().pipe(
      filter(result => result != null),
      map(result => ({
        id: this.insc_object.object_group.id,
        name: result.name,
        object_group_type: result.object_group_type
      } as Partial<ObjectGroup>)),
      switchMap(objectGroupData => this.objectGroupService.save(objectGroupData))
    ).subscribe(objectGroup => this.updateLocalObjectGroup(objectGroup))
  }

  private updateLocalObjectGroup(newObjectGroup: ObjectGroup) {
    this.recordPageState.record = Object.assign(this.insc_object, {object_group: newObjectGroup})
  }


  // ***** INSCRIPTIONS

  private getInscriptionLinks(object: InscObject): LinkListLink[] {
    return object?.inscriptions?.map(inscription => ({
      name: inscription.name,
      id: inscription.id,
      routerLink: ["inscriptions", inscription.id]
    }))
  }

  // TODO: reorder after fail
  reorderInscription(event: LinkListReorderEvent, linkList: LinkListComponent): void {
    const position = event.currentIndex + 1
    this.linkingService
      .moveFrom("inscription", event.link.id, "object", this.insc_object.id, "inscriptions", "object", this.insc_object.id, "inscriptions", { position })
      .pipe(switchMap(() => this.inscObjectService.get(this.insc_object.id)))
      .subscribe({
        next: inscObject => this.recordPageState.record = inscObject,
        error: () => linkList.resetListItems()
      })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

}
