<mat-expansion-panel
  [expanded]="expanded" class="panel mat-elevation-z0"
  [disabled]="valueTreeNodes?.length === 0"
  (afterExpand)="afterPanelExpand()"
  (afterCollapse)="afterPanelCollapse()"
  >
  <mat-expansion-panel-header>
    <mat-panel-title>
      <span>{{ title }}</span>
      <mat-icon *ngIf="info" class="info" [matTooltip]="info" inline>info_outline</mat-icon>
    </mat-panel-title>
  </mat-expansion-panel-header>

  <ul>
    @for (node of valueTreeNodes; track node) {
      <li [ngClass]="{active: node.selected}"
        [ngStyle]="{'padding-left': getNodePadding(node)}">
        <a (click)="toggleSelected(node)">{{node.key}} ({{node.docCount}})</a>
      </li>
    }
  </ul>
</mat-expansion-panel>
