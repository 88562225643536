import { Component, Inject } from "@angular/core"
import { MAT_DIALOG_DATA } from "@angular/material/dialog"

import { InscImage } from "../../models/image.model"

@Component({
    selector: 'insc-image-viewer-dialog',
    templateUrl: './image-viewer-dialog.component.html',
    styleUrls: ['./image-viewer-dialog.component.scss'],
    standalone: false
})
export class ImageViewerDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    images: InscImage[]
  }) { }


}
