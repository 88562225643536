import { Component, Input } from "@angular/core"
import { PersonEntry } from "../../../../shared/models"

@Component({
    selector: 'insc-person-entry-view',
    templateUrl: './person-entry-view.component.html',
    styleUrls: ['./person-entry-view.component.scss'],
    standalone: false
})
export class PersonEntryViewComponent {
  @Input() personEntry: PersonEntry
  @Input() disabled = false
}
