
import { Component, Input, OnInit } from "@angular/core"
import { MatIconModule } from "@angular/material/icon"
import { AbstractExternalNormDataProviderService } from "../abstract-external-norm-data-provider.service"
import { ExternalNormDataProviderResolverService } from "../external-norm-data-provider-resolver.service"

@Component({
    selector: "insc-external-norm-data-id-link",
    template: `
                    @if (externalId && provider?.getExternalLink(externalId); as externalLink) {
                      <a href="{{externalLink}}" target="_blank" title="In externer Normdatenbank öffnen">
                        {{externalId}}
                        <mat-icon [inline]="true">exit_to_app</mat-icon>
                      </a>
                    } @else {
                      {{externalId}}
                    }
                    
                    `,
    styles: [`
                 a {
                   color: #07C;
                   text-decoration: none;
                 }

                 a:link {
                   border-bottom: 1px dotted #07C;
                 }

                 a:visited {
                   color: #0077cc;
                   border-bottom-color: #07C;
                 }

                 mat-icon {
                   vertical-align: bottom;
                 }
               `
    ],
    imports: [
        MatIconModule
    ]
})
export class ExternalNormDataIdLinkComponent implements OnInit {

  provider: AbstractExternalNormDataProviderService<any>

  @Input() externalId: string
  @Input() providerId: string

  constructor(private providerResolver: ExternalNormDataProviderResolverService) {}

  ngOnInit(): void {
    if (!this.providerId) {
      throw new Error(`Provider ID must be set! Available providers: ${this.providerResolver.availableProviders}.`)
    }

    this.provider = this.providerResolver.get(this.providerId)
  }

}
