import { Component, ContentChildren, QueryList } from "@angular/core"
import { RecordSidebarSectionActionsDirective, RecordSidebarSectionContentDirective } from "./record-page-container.component"

@Component({
    selector: "insc-record-sidebar-section",
    templateUrl: "record-sidebar-section.component.html",
    styleUrls: ['record-sidebar-section.component.scss'],
    standalone: false
})
export class RecordSidebarSectionComponent {
  @ContentChildren(RecordSidebarSectionActionsDirective) sidebarActionsDirectives: QueryList<RecordSidebarSectionContentDirective>
}
