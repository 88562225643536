import { Component, Input, OnInit } from "@angular/core"

export interface RegisterEntryMetadata {
  id: string
  createdAt: string
  updatedAt: string
  creator: {name: string; id: string}
  lastEditor: {name: string; id: string}
}

@Component({
    selector: 'insc-register-entry-metadata-view',
    templateUrl: './register-entry-metadata-view.component.html',
    styleUrls: ['./register-entry-metadata-view.component.scss'],
    standalone: false
})
export class RegisterEntryMetadataViewComponent implements OnInit {

  @Input() metadata: RegisterEntryMetadata
  @Input() recordType: string

  constructor() { }

  ngOnInit() {
  }

}
