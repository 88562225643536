import { Injectable } from "@angular/core"
import { BehaviorSubject } from "rxjs"
import { map, share, tap } from "rxjs/operators"
import { User } from "../shared/models/user.model"

const USER_PREFERENCES_KEY = "userPreferences"

export interface UserPreferences {
  imageSidebarWidth: number
  recordSidebarAutoHide: boolean
  colorMode: "light" | "dark" | "auto"
  layoutVariant: string,
  appLayoutVariant: string,
  imageOverviewTileSize: number
}

const userPreferencesDefaults: UserPreferences = {
  imageSidebarWidth: 420,
  recordSidebarAutoHide: false,
  colorMode: "auto",
  layoutVariant: "variant1",
  appLayoutVariant: "appVar1",
  imageOverviewTileSize: 200,
}

@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {

  private userPreferencesSubject = new BehaviorSubject<UserPreferences>(this.getUserPreferences())
  readonly userPreferences = this.userPreferencesSubject.asObservable()

  private getUserPreferences(): UserPreferences {
    const userPreferencesJson = localStorage.getItem(USER_PREFERENCES_KEY)
    const userPreferences = userPreferencesJson
                            ? JSON.parse(userPreferencesJson)
                            : userPreferencesDefaults
    return {...userPreferencesDefaults, ...userPreferences}
  }

  set<K extends keyof UserPreferences>(key: K, value: UserPreferences[K]) {
    const currentPrefs = this.getUserPreferences()
    const newPrefs = { ...currentPrefs, [key]: value }
    localStorage.setItem(USER_PREFERENCES_KEY, JSON.stringify(newPrefs))
    this.userPreferencesSubject.next(newPrefs)
  }


}
