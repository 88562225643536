import { Component, Input, ViewChild } from "@angular/core"
import { MAT_RIPPLE_GLOBAL_OPTIONS, MatRipple } from "@angular/material/core"
import { LocationDisplayHelperService } from "../../../services/location-display-helper.service"
import { CompactLocation } from "../../../shared/models/object-location.model"
import { InscObject } from "../../../shared/models/object.model"

@Component({
    selector: 'insc-object-result-card',
    templateUrl: './object-result-card.component.html',
    styleUrls: ['../result-cards.scss', './object-result-card.component.scss'],
    standalone: false
})
export class ObjectResultCardComponent {

  @Input() result: Partial<InscObject>
  @Input() highlight: Partial<InscObject>
  @Input() layoutVariant: string = "variant1"

  @ViewChild(MatRipple) ripple: MatRipple

  constructor(private locationDisplayHelper: LocationDisplayHelperService) { }

  getAnnotatedLocationName(location: CompactLocation): string {
    return this.locationDisplayHelper.getLocationNameWithLostAnnotation(location)
  }

  launchRipple(event: MouseEvent) {
    this.ripple.launch({persistent: true, centered: true})
  }

}
