import { HttpErrorResponse } from "@angular/common/http"
import { AfterViewInit, Component, Input, OnInit, ViewChild } from "@angular/core"
import { FormControl } from "@angular/forms"
import { MatAutocomplete } from "@angular/material/autocomplete"
import { Observable, of, switchMap } from "rxjs"
import { catchError, debounceTime, distinctUntilChanged, filter, map, tap } from "rxjs/operators"
import { AbstractExternalNormDataProviderService, ExternalNormDataLookupResult } from "../../abstract-external-norm-data-provider.service"
import { ExternalNormDataProviderResolverService } from "../../external-norm-data-provider-resolver.service"

@Component({
    selector: 'insc-external-norm-data-lookup-field',
    templateUrl: './external-norm-data-lookup-field.component.html',
    styleUrls: ['./external-norm-data-lookup-field.component.scss'],
    standalone: false
})
export class ExternalNormDataLookupFieldComponent implements OnInit, AfterViewInit {

  @Input() providerId: string
  @Input() lookupOptions: Record<string, string> = {}

  queryCtrl = new FormControl<string>(null)
  lookupResults: Observable<ExternalNormDataLookupResult[]>
  someResultsHaveDescriptions: Observable<boolean>

  valueSelected: Observable<ExternalNormDataLookupResult>

  loading = false
  error: unknown = null

  private provider: AbstractExternalNormDataProviderService<unknown>

  @ViewChild('auto') autocomplete: MatAutocomplete

  constructor(private providerResolver: ExternalNormDataProviderResolverService) { }

  ngOnInit(): void {
    if (!this.providerId) {
      throw new Error(`Provider ID must be set! Available providers: ${this.providerResolver.availableProviders}.`)
    }

    this.provider = this.providerResolver.get(this.providerId)
    if (!this.provider) {
      return
    }

    this.lookupResults = this.queryCtrl.valueChanges.pipe(
      filter(value => typeof value === "string" && value.length > 0),
      tap(() => this.loading = true),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(queryString => this.provider.lookup(queryString, this.lookupOptions).pipe(
        tap(() => this.error = null),
        catchError((err) => {
          this.error = err
          return of([] as ExternalNormDataLookupResult[])
        })
      )),
      tap(() => {
        this.loading = false
      })
    )

    this.someResultsHaveDescriptions = this.lookupResults.pipe(
      map(results => results.some(result => result.description))
    )
  }

  ngAfterViewInit(): void {
    this.valueSelected = this.autocomplete.optionSelected.pipe(
      map(event => (<ExternalNormDataLookupResult>event.option.value))
    )
  }

  getErrorString() {
    if (this.error instanceof HttpErrorResponse) {
      return this.error.message
    } else return "Unbekannter Fehler"
  }

  autocompleteValueDisplayFunc =
    (value: string | ExternalNormDataLookupResult): string => typeof value === "string" ? value : value?.title

}
