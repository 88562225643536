import { Directive, Inject, InjectionToken, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core"
import { BehaviorSubject, Subject } from "rxjs"
import { QueryParams } from "../../../services/data.service"
import { SearchControl } from "./search-control.interface"


/**
 * An injection token used to inject a {@link SearchControl} into a {@link SearchControlDirective}
 * @see https://angular.io/api/core/InjectionToken#description
 */
export const INSC_SEARCH_CONTROL = new InjectionToken<string>("SearchControl")

/**
 * Used by {@link SearchControlDirective}s to notify subscribers about a search param change
 * initiated by user interaction with a control
 */
export class SearchControlParamsChangeEvent {
  constructor(readonly change: Partial<QueryParams>) {}
}

export interface CriteriumDescription<TParams = unknown> {
  name: string
  displayName: string
  displayValue: string
  value: TParams
}

/**
 * Connects each {@link SearchControl} with the search UI (i.e. {@link ObjectOverviewPageComponent}).
 */
@Directive({
    selector: "[inscSearchControl]",
    standalone: false
})
export class SearchControlDirective<TSearchParams = unknown, TCriteriumValue = unknown> implements OnInit, OnDestroy {
  @Input() inscSearchControl: keyof QueryParams
  @Input() searchControlDisplayName: string

  private _criteriaChanges = new BehaviorSubject<CriteriumDescription<TCriteriumValue>[]>([])
  private _paramChanges = new Subject<SearchControlParamsChangeEvent>()

  @Output() criteria$ = this._criteriaChanges.asObservable()
  @Output() paramChange$ = this._paramChanges.asObservable()

  emitParamsChange(params: TSearchParams): void {
    this._paramChanges.next(new SearchControlParamsChangeEvent({
      [this.inscSearchControl]: params
    }))
  }

  emitCriteriaChange(criteria: CriteriumDescription<TCriteriumValue>[]): void {
    this._criteriaChanges.next(criteria)
  }

  constructor(@Inject(INSC_SEARCH_CONTROL) @Optional() private searchControl: SearchControl<TSearchParams, TCriteriumValue>) {
    if (this.searchControl) {
      searchControl.searchControlDirective = this
    }
  }

  ngOnInit(): void {
    if (!this.searchControl) {
      throw new Error(`Search field "${this.inscSearchControl}": can only be used on components that provide themselves using the INSC_SEARCH_CONTROL inkection token.`)
    }
  }

  remove(criterium: CriteriumDescription<TCriteriumValue>): void {
    this.searchControl.remove(criterium)
  }

  ngOnDestroy(): void {
    this._criteriaChanges.next([])
    this._criteriaChanges.complete()
  }
}
