import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop"
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core"
import { Subject } from "rxjs"

export interface LinkListLink {
  name: string
  id: string
  routerLink: string[]
}

export interface LinkListReorderEvent {
  currentIndex: number
  previousIndex: number,
  link: LinkListLink
}

@Component({
    selector: 'insc-link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss'],
    standalone: false
})
export class LinkListComponent implements OnChanges, OnDestroy {

  private unsubscribe$ = new Subject<void>()

  @Input() links: LinkListLink[]

  @Input() allowReorder = true
  @Input() allowRemove = true

  @Output() unlinkClick = new EventEmitter<string>()
  @Output() reorder = new EventEmitter<LinkListReorderEvent>()

  protected linkList: LinkListLink[]

  ngOnChanges(changes: SimpleChanges): void {
    if ('links' in changes) {
      this.initWithLinks(this.links)
    }
  }

  /**
   * Reset list links to original order. Used after a failed drag&drop reordering.
   */
  resetListItems(): void {
    this.initWithLinks(this.links)
  }

  drop(event: CdkDragDrop<unknown, unknown, LinkListLink>) {
    moveItemInArray(this.linkList, event.previousIndex, event.currentIndex)
    const {previousIndex, currentIndex} = event
    this.reorder.emit({previousIndex, currentIndex, link: event.item.data})
  }


  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  private initWithLinks(links: LinkListLink[]) {
    this.linkList = [...links]
  }
}
