import { Component, Input, ViewChild } from "@angular/core"
import { MatDialog } from "@angular/material/dialog"
import { finalize } from "rxjs/operators"
import { ImageService, InscObjectService, ObjectLocationService } from "../../../services/data.service"
import { ValidationError } from "../../../services/errors"
import { FormService } from "../../../services/form.service"
import { ConfirmationDialogComponent } from "../../../shared/dialogs/confirmation-dialog.component"
import { ReusableDialogsService } from "../../../shared/dialogs/reusable-dialogs.service"
import { ObjectLocation } from "../../../shared/models/object-location.model"
import { SnackbarService } from "../../../shared/snackbars/snackbar.service"
import { RegisterEditorBaseAbstractComponent } from "../register-editor-base-abstract.component"
import {
  ExternalNormDataLookupFormAction
} from "../shared/external-norm-data/external-norm-data-id-input/external-norm-data-id-input.component"
import {
  ExternalNormDataLookupDialogService
} from "../shared/external-norm-data/external-norm-data-lookup-dialog/external-norm-data-lookup-dialog.component"
import { ExternalNormDataMapperInterface } from "../shared/external-norm-data/external-norm-data-mapper.interface"
import {
  GeonameToInscLocationMapper
} from "../shared/external-norm-data/external-norm-data-providers/geonames/geoname-to-insc-location.mapper"
import {
  WikidataEntityToInscLocationMapper
} from "../shared/external-norm-data/external-norm-data-providers/wikidata/wikidata-entity-to-insc-location.mapper"
import { AssociatedRecordQuery } from "../shared/register-editor-layout/associated-record-view/associated-record-view.component"
import { LocationFormComponent } from "./location-form/location-form.component"
import { LocationSelection, LocationTreeComponent } from "./location-tree.component"

@Component({
    selector: "insc-location-register-editor",
    templateUrl: "./location-register-editor.component.html",
    styleUrls: ["./location-register-editor.component.scss"],
    standalone: false
})
export class LocationRegisterEditorComponent extends RegisterEditorBaseAbstractComponent {

  @Input() type = "location" as const

  @ViewChild(LocationTreeComponent, {static: true}) locationTree: LocationTreeComponent

  form: ReturnType<typeof LocationFormComponent.buildFormGroup>
  selectionParentType: string
  loading = false

  protected selectedEntry: ObjectLocation
  protected isSaving = false

  protected readonly associatedQueries: AssociatedRecordQuery[] = [{
    title:            "Inschriftenträger (aktueller Standort)",
    getSearchResults: (queryParams) => this.inscObjectService.all(queryParams),
    overviewRoute:    "objects",
    getQueryParams:   (entry) => ({
      filters: {
        "current_locationing.location.id": [entry.id]
      }
    })
  }, {
    title:            "Inschriftenträger (Provenienz)",
    overviewRoute:    "objects",
    getSearchResults: (queryParams) => this.inscObjectService.all(queryParams),
    getQueryParams:   (entry) => ({
      filters: {
        "provenience_locationings.location.id": [entry.id]
      }
    })
  }, {
    title:            "Aufnahmen (Aufnahmeort)",
    getSearchResults: (queryParams) => this.imageService.all(queryParams),
    overviewRoute:    "images",
    getQueryParams:   (entry) => ({
      filters: {
        "recording_location.id": [entry.id]
      }
    })
  }]

  @Input() selectionHandler = (id: string): void =>
    this.reusableDialogsService.openUnsavedChangesDialogConfigWhen(
      () => this.formDirty,
      () => this.selectedId = id
    )

  constructor(
    private locationService: ObjectLocationService,
    private reusableDialogsService: ReusableDialogsService,
    private dialog: MatDialog,
    private formService: FormService,
    private lookupDialog: ExternalNormDataLookupDialogService,
    private geonameLocationMapper: GeonameToInscLocationMapper,
    private wikidataLocationMapper: WikidataEntityToInscLocationMapper,
    private snackbarService: SnackbarService,
    private inscObjectService: InscObjectService,
    private imageService: ImageService
  ) {
    super()
  }

  getSelectedEntry(): ObjectLocation {
    return this.selectedEntry
  }

  locationSelected(selection: LocationSelection): void {
    // workaround for number/string id issue
    // TODO: solve number/string id issue

    if (selection.id != this.selectedId) {
      this.selectedId = selection.id
    }


    this.selectionParentType = selection.parentType

    if (this.selectedId == null) {
      this.form = null
      this.metadata = null
      this.selectedEntry = null

      return
    }

    setTimeout(() => this.loading = true)

    this.locationService.get(selection.id).subscribe(location => {
      this.metadata = this.getMetadata(location)

      this.form = LocationFormComponent.buildFormGroup({
        ...location,
        parent_id: selection.parentId
      })

      this.checkAllowEdit()
      this.loading = false

      this.selectedEntry = location
    })
  }

  onTreeCreateLocation({parentId, parentType, type, lookupProvider, lookupOptions}: { parentId: string; parentType: string; type: string; lookupProvider?: string; lookupOptions?: Record<string, string> }): void {
    if (lookupProvider) {
      this.lookup(lookupProvider, null, lookupOptions, parentType, type).subscribe(result => {
        if (!result) {
          this.locationTree.cancelCreateLocation()
        } else {
          this.createLocation(parentId, parentType, type, result)
        }
      })
    } else {
      this.createLocation(parentId, parentType, type)
    }
  }

  createLocation(parentId: string, parentType: string, type: string, initialData?: Partial<ObjectLocation>): void {
    const initialValues: Partial<ObjectLocation> = {
      ...initialData,
      parent_id: parentId,
      type:      type
    }

    this.form = LocationFormComponent.buildFormGroup(initialValues)
    this.selectionParentType = parentType
  }

  cancelCreate(): void {
    this.locationTree.cancelCreateLocation()
    this.form = null
  }

  createTopLevelLocation(providerId?: string, lookupOptions: Record<string, string> = {}): void {
    this.locationTree.createLocation(null, "City", providerId, lookupOptions)
  }

  save(): void {
    this.isSaving = true
    this.form.disable()

    this.locationService.save(this.form.getRawValue() as ObjectLocation)
      .pipe(
        finalize(() => {
          this.isSaving = false
          this.form.enable()
        })
      )
      .subscribe({
        next:  result => {
          if (this.isCreatingNewLocation()) {
            this.locationTree.selectId(result.id)
            this.snackbarService.showSuccessWithMessage("Eintrag angelegt")
          } else {
            this.locationTree.updateNodeFor(result)
            this.snackbarService.showSuccessWithMessage("Eintrag gespeichert")
          }

          this.form = LocationFormComponent.buildFormGroup(result)
        },
        error: (error: unknown) => {
          if (error instanceof ValidationError) {
            this.formService.applyValidationErrors(this.form, error.field_errors)
          }
        }
      })
  }

  delete(): void {
    this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message:   `Ausgewählten Standort wirklich löschen?`,
        yesAction: "STANDORT LÖSCHEN",
        noAction:  "STANDORT BEHALTEN",
        icon:      "delete"
      }
    }).afterClosed().subscribe(confirmed => confirmed && this.actuallyDelete())
  }

  private actuallyDelete() {
    this.locationService.delete(this.selectedId).subscribe(() => {
      this.locationTree.removeId(this.selectedId)
      this.locationTree.selectId(null)
      this.form = null
    })
  }

  onFormExternalAction(externalAction: unknown): void {
    if (externalAction instanceof ExternalNormDataLookupFormAction) {
      this.lookup(externalAction.providerId, this.form.getRawValue() as Partial<ObjectLocation>, externalAction.lookupOptions).subscribe(result => {
        if (result) {
          this.form = LocationFormComponent.buildFormGroup(result)
          this.form.markAsDirty()
        }
      })
    }
  }

  private lookup(providerId: string, initialData: Partial<ObjectLocation> = null, lookupOptions: Record<string, string>, parentType?: string, type?: string) {
    const mappers: Record<string, ExternalNormDataMapperInterface<unknown, Partial<ObjectLocation>>> = {
      geonames: this.geonameLocationMapper,
      wikidata: this.wikidataLocationMapper
    }

    if (!mappers[providerId]) {
      throw new Error(`No mapper defined for providerId ${providerId}`)
    }

    return this.lookupDialog.open<Partial<ObjectLocation>, LocationFormComponent>({
      formType:      LocationFormComponent,
      mapper:        mappers[providerId],
      providerId:    providerId,
      initialData:   initialData,
      lookupOptions: lookupOptions,
      fixedData:     {type: initialData?.type ?? type, id: initialData?.id, parent_id: initialData?.parent_id}, formComponentInputs: {parentType: parentType ?? this.selectionParentType}
    }).afterClosed()
  }

  isCreatingNewLocation(): boolean { return !this.form.getRawValue().id }
}
