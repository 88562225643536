import { Component, ElementRef, HostBinding, ViewChild } from "@angular/core"
import { ControlContainer, UntypedFormArray } from "@angular/forms"
import { MatRipple } from "@angular/material/core"
import { FormService } from "../../../../services/form.service"

@Component({
    selector: 'insc-repeatable-form',
    templateUrl: './repeatable-form.component.html',
    styleUrls: ['./repeatable-form.component.scss'],
    standalone: false
})
export class RepeatableFormComponent {

  @ViewChild("ripple", {static: true}) ripple: MatRipple
  
  get parentArray() { return <UntypedFormArray>this.controlContainer.control.parent }
  get itemCountInArray() { return this.parentArray.length }
  get isFirstInArray() { return this.parentArray.at(0) === this.controlContainer.control }
  get isLastInArray() { return this.parentArray.at(this.parentArray.length - 1) === this.controlContainer.control }
  @HostBinding("class.marked-for-destruction") get markedForDestruction() { return this.formService.isMarkedForDestruction(this.controlContainer.control) }
  

  constructor(
    private controlContainer: ControlContainer,
    public formService: FormService,
    private elementRef: ElementRef
  ) {}
  
  destroyButtonText() {
    return this.formService.destroyButtonText(this.controlContainer.control)
  }

  remove() {
    this.formService.toggleDestroy(this.parentArray, this.findIndexInArray())
  }
  
  moveUp() {
    this.formService.moveUp(this.parentArray, this.findIndexInArray())
    this.ripple.launch({})
    setTimeout(() => this.scrollIntoView())
  }
  
  moveDown() {
    this.formService.moveDown(this.parentArray, this.findIndexInArray())
    this.ripple.launch({})
    setTimeout(() => this.scrollIntoView())
  }
  
  scrollIntoView() {
    const elem: HTMLElement = this.elementRef.nativeElement
    elem.scrollIntoView({block: "nearest", behavior: "smooth"})
  }
  
  private findIndexInArray() {
    return this.parentArray.controls.findIndex(control => this.controlContainer.control === control)
  }
}

