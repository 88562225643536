import { Component } from "@angular/core"
import { Params, Router } from "@angular/router"
import { SearchStateService } from "../../../../services/search-state.service"

@Component({
    selector: 'insc-back-to-results-link',
    templateUrl: './back-to-results-link.component.html',
    styleUrls: ['./back-to-results-link.component.scss'],
    standalone: false
})
export class BackToResultsLinkComponent {

  constructor(
    private searchStateService: SearchStateService,
    private router: Router
  ) { }

  get searchState(): { path: string; queryParams: Params } | null {
    const url = this.searchStateService.lastSearchUrl
    if (!url) {
      return null
    }

    const urlTree = this.router.parseUrl(url)
    const queryParams = urlTree.queryParams

    urlTree.queryParams = {}
    const path = urlTree.toString()

    return { path, queryParams }
  }
}
