import { Component, forwardRef } from "@angular/core"
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms"

@Component({
    selector: 'insc-editorial-state-select',
    template: `
      <mat-form-field matSuffix floatLabel="always" class="certainty">
          <mat-label>Status der Angabe(n)</mat-label>
          <mat-select [formControl]="control">
              <mat-option value="certain">sicher</mat-option>
              <mat-option value="uncertain">unsicher</mat-option>
              <mat-option value="unknown">ungeklärt</mat-option>
              <mat-option value="indeterminable">nicht feststellbar</mat-option>
          </mat-select>
      </mat-form-field>
  `,
    styleUrls: ['./editorial-state-select.component.scss'],
    providers: [{
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditorialStateSelectComponent),
            multi: true
        }],
    standalone: false
})
export class EditorialStateSelectComponent implements ControlValueAccessor {

  control: UntypedFormControl = new UntypedFormControl()

  registerOnChange(fn: any): void { this.control.valueChanges.subscribe(fn) }
  registerOnTouched(fn: any): void {}
  setDisabledState(isDisabled: boolean): void { isDisabled ? this.control.disable() : this.control.enable() }
  writeValue(obj: any): void { this.control.setValue(obj, {emitEvent: false})}
}
