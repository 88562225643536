import { Component, Inject } from "@angular/core"
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms"
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog"
import { ObjectGroup } from "../../../../shared/models/object-group.model"

export interface CreateOrEditObjectGroupDialogData {
  object_group: ObjectGroup
}

export interface CreateOrEditObjectGroupDialogResult {
  name: string
  object_group_type: string
}

export type CreateOrEditObjectGroupDialogRef = MatDialogRef<CreateOrEditObjectGroupDialogComponent, CreateOrEditObjectGroupDialogResult>

@Component({
    selector: 'insc-create-or-edit-object-group-dialog',
    templateUrl: './create-or-edit-object-group-dialog.component.html',
    standalone: false
})
export class CreateOrEditObjectGroupDialogComponent {

  form = new UntypedFormGroup({
    // eslint-disable-next-line @typescript-eslint/unbound-method
    name: new UntypedFormControl(this.data?.object_group?.name ?? '', Validators.required),
    // eslint-disable-next-line @typescript-eslint/unbound-method
    object_group_type: new UntypedFormControl(this.data?.object_group?.object_group_type ?? "fragments", Validators.required)
  })

  constructor(
    @Inject(MatDialogRef) public dialogRef: CreateOrEditObjectGroupDialogRef,
    @Inject(MAT_DIALOG_DATA) public data: CreateOrEditObjectGroupDialogData
  ) { }

  confirm(): void {
    this.dialogRef.close(this.form.value)
  }
}
