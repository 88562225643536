import { Component, Input } from "@angular/core"
import { OrganizationEntry } from "../../../../shared/models"

@Component({
    selector: 'insc-organization-entry-view',
    templateUrl: './organization-entry-view.component.html',
    styleUrls: ['./organization-entry-view.component.scss'],
    standalone: false
})
export class OrganizationEntryViewComponent {
  @Input() organizationEntry: OrganizationEntry
  @Input() disabled = false
}
