import { Component, Input } from "@angular/core"
import { QueryParams, QueryResult } from "../../../../../services/data.service"
import { InscEntity } from "../../../../../shared/models/entity.model"
import { InscImage } from "../../../../../shared/models/image.model"
import { NormDataEntry } from "../../../../../shared/models/norm-data-entry.model"
import { GetSearchResultsFunc } from "../../../../../shared/search/virtual-scroll-search.data-source"
import { ImageSidebarService } from "../../../../images/image-sidebar/image-sidebar.service"

export interface AssociatedRecordQuery<T extends InscEntity = InscEntity> {
  getSearchResults: GetSearchResultsFunc<T>
  getQueryParams: (entry: NormDataEntry) => QueryParams
  title: string
  overviewRoute: string
}

@Component({
    selector: "insc-associated-record-view",
    templateUrl: "./associated-record-view.component.html",
    styleUrls: ["./associated-record-view.component.scss"],
    standalone: false
})
export class AssociatedRecordViewComponent {

  @Input({required: true}) associatedQueries: AssociatedRecordQuery[]
  @Input() selectedEntry: NormDataEntry

  constructor(protected imageSidebarService: ImageSidebarService) {}

  isAssociatedImageSelected(selectedImages: InscImage[], imageResult: QueryResult<InscImage>) {
    return selectedImages?.[0]?.id === imageResult.id
  }
  associatedImageClick(selectedImages: InscImage[], imageResult: QueryResult<InscImage>) {
    if (this.isAssociatedImageSelected(selectedImages, imageResult)) {
      this.imageSidebarService.unsetImages()
    } else {
      this.imageSidebarService.setImages([imageResult.data], {
        enableDelete: true,
        enableCloseButton: true
      })
    }
  }
}
