import { Component } from "@angular/core"

@Component({
    selector: 'insc-search-toolbar',
    templateUrl: './search-toolbar.component.html',
    styleUrls: ['./search-toolbar.component.scss'],
    standalone: false
})
export class SearchToolbarComponent {

  constructor() { }
}
