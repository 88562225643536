<insc-external-norm-data-lookup-field class="input" [providerId]="providerId" [lookupOptions]="lookupOptions"></insc-external-norm-data-lookup-field>

<mat-form-field class="input" subscriptSizing="dynamic">
  <mat-label>Datenübernamemodus</mat-label>
  <mat-select [formControl]="mappingModeCtrl">
    <mat-option value="auto">Alle Daten übernehmen, ggf. vorhandene überschreiben</mat-option>
    <mat-option value="manual">Daten manuell übernehmen</mat-option>
  </mat-select>
</mat-form-field>

<div class="form-container">
  @if (mappingMode === 'manual') {
    <mat-card class="local" appearance="outlined" [formGroup]="localValuesFormGroup">
      <mat-card-header class="form-header">
        <mat-card-title>Zu übernehmende Daten</mat-card-title>
      </mat-card-header>
      <mat-card-content class="form" formGroupName="form">
        <div #localValuesFormContainer></div>
      </mat-card-content>
    </mat-card>
  }

  <mat-card class="remote" appearance="outlined" [formGroup]="remoteValuesFormGroup">
    <mat-card-header class="form-header">
      @if (mappingMode === 'auto') {
        <mat-card-title>Zu übernehmende Daten</mat-card-title>
      }
      @if (mappingMode === 'manual') {
        <mat-card-title>Externe Normdaten</mat-card-title>
      }
    </mat-card-header>
    <mat-card-content class="form" formGroupName="form">
      <div #remoteValuesFormContainer></div>
    </mat-card-content>
  </mat-card>
</div>

