import { CdkConnectedOverlay } from "@angular/cdk/overlay"
import { CommonModule, NgOptimizedImage } from "@angular/common"
import { NgModule } from "@angular/core"
import { ReactiveFormsModule } from "@angular/forms"
import { MatOption, MatRippleModule } from "@angular/material/core"
import { MatDividerModule } from "@angular/material/divider"
import { MatExpansionModule } from "@angular/material/expansion"
import { MatFormField, MatLabel } from "@angular/material/form-field"
import { MatIconModule } from "@angular/material/icon"
import { MatButtonModule } from "@angular/material/button"
import { MatCardModule } from "@angular/material/card"
import { MatMenuModule } from "@angular/material/menu"
import { MatSelect } from "@angular/material/select"
import { MatSlider, MatSliderThumb } from "@angular/material/slider"
import { MatTooltipModule } from "@angular/material/tooltip"
import { RouterModule } from "@angular/router"
import { SearchModule } from "../../shared/search/search.module"
import { ImageManagementModule } from "../images/image-management/image-management.module"
import { ImageSidebarModule } from "../images/image-sidebar/image-sidebar.module"
import { ImageOverviewPageComponent } from "./image-overview-page/image-overview-page.component"
import { ObjectOverviewPageComponent } from "./object-overview-page/object-overview-page.component"

import { OverviewPagesRoutingModule } from "./overview-pages-routing.module"
import { ImageResultCardComponent } from "./image-overview-page/image-result-card.component"
import { ObjectResultCardComponent } from "./object-overview-page/object-result-card.component"
import { ResultCardLinkListComponent, ResultCardLinkListEntryComponent } from "./object-overview-page/result-card-link-list.component"

@NgModule({
  imports: [
    CommonModule,
    OverviewPagesRoutingModule,
    SearchModule,

    MatDividerModule, MatCardModule,

    RouterModule,

    MatExpansionModule, MatIconModule, MatButtonModule, MatTooltipModule, ImageSidebarModule, MatMenuModule, ImageManagementModule, ImageManagementModule, ImageManagementModule, ImageManagementModule, ImageManagementModule, ImageManagementModule, ImageManagementModule, MatRippleModule, NgOptimizedImage, MatFormField, MatLabel, MatSelect, ReactiveFormsModule, MatOption, MatSlider, CdkConnectedOverlay,  MatSliderThumb
  ],
  declarations: [
    ObjectOverviewPageComponent,
    ImageOverviewPageComponent,

    ImageResultCardComponent,
    ObjectResultCardComponent,
    ResultCardLinkListComponent,
    ResultCardLinkListEntryComponent
  ]
})
export class OverviewPagesModule { }
