import { Component, Input } from "@angular/core"
import { PersonOrganization } from "../../../../shared/models"

@Component({
    selector: 'insc-personorganization-view',
    templateUrl: './personorganization-view.component.html',
    styleUrls: ['./personorganization-view.component.css'],
    standalone: false
})
export class PersonorganizationViewComponent {
  @Input() personOrganization: PersonOrganization
  @Input() showExtraFields = true

  constructor() { }
}
