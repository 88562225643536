import { Component, Directive, Input } from "@angular/core"

@Directive({
    selector: "[inscNormDataPanelEntryTitle]",
    standalone: false
})
export class NormDataPanelEntryTitleDirective {}

@Directive({
    selector: "[inscNormDataPanelEntrySubtitle]",
    standalone: false
})
export class NormDataPanelEntrySubtitleDirective {}

@Directive({
    selector: "[inscNormDataPanelEntryDescription]",
    standalone: false
})
export class NormDataPanelEntryDescriptionDirective {}

@Directive({
    selector: "[inscNormDataPanelEntryDetails]",
    standalone: false
})
export class NormDataPanelEntryDetailsDirective {}

@Component({
    selector: 'insc-norm-data-expansion-panel',
    template: `
      <mat-expansion-panel class="mat-elevation-z0" [disabled]="disabled">
          <mat-expansion-panel-header>
              <mat-panel-title>
                  <ng-content select="[inscNormDataPanelEntryTitle]"></ng-content>
              </mat-panel-title>
              <mat-panel-description>
                  <ng-content select="[inscNormDataPanelEntryDescription]"></ng-content>
              </mat-panel-description>
          </mat-expansion-panel-header>
          <mat-panel-description>
              <ng-content select="[inscNormDataPanelEntrySubtitle]"></ng-content>
          </mat-panel-description>
          <ng-content select="[inscNormDataPanelEntryDetails]"></ng-content>
      </mat-expansion-panel>
  `,
    styles: [`
	.mat-expansion-panel-header {
		font-size: 22px;
		line-height: 22px;
	}

	.mat-expansion-panel {
		border-bottom: 1px solid lightgrey;
	}
  `],
    standalone: false
})
export class NormDataExpansionPanelComponent {
  @Input() disabled = false
}
