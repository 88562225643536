<ng-container [formGroup]="formGroup">

  <mat-form-field>
    <mat-label>Spezifizierung</mat-label>
    <input matInput (click)="openDatingChooser()" value="{{formGroup.value['description']}}" readonly>
    <div class="dating-input-suffix" matTextSuffix>
      <button (click)="openDatingChooser()" type="button" mat-icon-button>
        <mat-icon>edit</mat-icon>
      </button>
      <mat-checkbox formControlName="uncertain">unsicher</mat-checkbox>
    </div>
  </mat-form-field>


  <mat-form-field>
    <input matInput type="text"  formControlName="based_on">
    <mat-label>Datierungsbasis</mat-label>
    <mat-error inscError errorControlName="based_on"></mat-error>
  </mat-form-field>
  <mat-form-field>
      <mat-label>Kommentar</mat-label>
      <textarea matInput  type="text" formControlName="comment"></textarea>
    <mat-error inscError errorControlName="comment"></mat-error>
  </mat-form-field>

</ng-container>
