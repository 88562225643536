import { formatDate } from "@angular/common"
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef
} from "@angular/core"
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from "@angular/forms"
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field"
import { Observable, Subject } from "rxjs"
import { startWith } from "rxjs/operators"
import { LocationDisplayHelperService } from "../../../../services/location-display-helper.service"
import { dateSpecToDate } from "../../../../shared/components/form-components/date-range-picker/date-range-picker.component"
import { InscImage, LiteratureReference, NormDataEntry, ObjectLocation, RecordingDateSpec } from "../../../../shared/models"
import { RecordingDate } from "../../../../shared/models/image.model"
import { CommentFormComponent } from "../../../records/shared/subforms/comment-form.component"
import {
  LiteratureReferenceFormComponent
} from "../../../records/shared/subforms/literaturereference-form/literaturereference-form.component"
import { NormDataEntrySelection } from "../../../registers/register-entry-dialog/register-entry-dialog.component"
import { RegisterEntryDialogService } from "../../../registers/register-entry-dialog/register-entry-dialog.service"
import {
  CompareFn,
  IdentifiableRecord,
  isIdentifiableItem,
  MultiRecordFormArray,
  MultiRecordFormControl,
  MultiRecordFormManager,
  ValueWrapper
} from "../shared/multi-record-form-manager"
import { RecordingDateDialogService } from "./recording-date-dialog.service"

type FormGroupBuilderFunc = (value: unknown) => UntypedFormGroup

export interface MultiFieldAccessoryContext {
  key: string
  multiControl: MultiRecordFormControl
}

@Directive({
    selector: '[inscMultiFieldAccessory]',
    standalone: false
})
export class MultiFieldAccessoryDirective {
  constructor() {}
}

export interface MultiGroupAccessoryContext<T = unknown> {
  key: string
  value: T
  multiControl: MultiRecordFormArray
  index: number
}

@Directive({
    selector: '[inscMultiGroupAccessory]',
    standalone: false
})
export class MultiGroupAccessoryDirective {
  constructor() {}
}

@Component({
    selector: 'insc-multi-image-editor',
    templateUrl: './multi-image-editor.component.html',
    styleUrls: ['./multi-image-editor.component.scss'],
    viewProviders: [{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { subscriptSizing: "dynamic" } }],
    standalone: false
})
export class MultiImageEditorComponent implements  AfterViewInit, OnChanges, OnDestroy {

  @HostBinding("class.insc-dense-input") classDenseInput = true

  @Input() images: Partial<InscImage>[]

  // TODO: why observable and not getter that returns this.formGroup.dirty?
  private _hasChanges = new Subject<boolean>()
  @Output() hasChanges: Observable<boolean> = this._hasChanges.asObservable().pipe(
    startWith(false)
  )

  private _changes = new Subject<Partial<InscImage>[]>()
  @Output() changes = this._changes.asObservable()

  @Input() editable = false

  @ContentChild(MultiFieldAccessoryDirective, {read: TemplateRef}) fieldAccessoryTpl: TemplateRef<MultiFieldAccessoryContext>
  @ContentChild(MultiGroupAccessoryDirective, {read: TemplateRef}) groupAccessoryTpl: TemplateRef<MultiGroupAccessoryContext>

  formGroup: UntypedFormGroup
  multiRecordFormManager: MultiRecordFormManager

  private unsubscribe$ = new Subject<void>()

  constructor(
    private registerEntryDialogService: RegisterEntryDialogService,
    private recordingDateDialogService: RecordingDateDialogService,
    private locationDisplayHelper: LocationDisplayHelperService,
    private cdr: ChangeDetectorRef
  ) { }


  ngAfterViewInit(): void {
    this.cdr.detectChanges()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ("images" in changes) {
      this.setImages()
    }
  }

  resetAll(): void {
    this.setImages()
  }

  private setImages(): void {
    if (!this.multiRecordFormManager) {
      this.multiRecordFormManager = new MultiRecordFormManager(this.images as IdentifiableRecord[], {
        name:                    {control: new UntypedFormControl()},
        image_file_basename:         {control: new UntypedFormControl()},
        filename_override:       {control: new UntypedFormControl()},
        negative_number:         {control: new UntypedFormControl()},
        copyright_holder:        {control: new UntypedFormControl(), compareFn: this.compareNormDataEntry},
        license:                 {control: new UntypedFormControl(), compareFn: this.compareNormDataEntry},
        photographer:            {control: new UntypedFormControl(), compareFn: this.compareNormDataEntry, emptyPlaceholder: "N. N."},
        editors:                 {control: new UntypedFormArray([]), formGroupBuilder: (value) => new UntypedFormControl(value), isRelationship: true},
        recording_category:      {control: new UntypedFormControl()},
        content:                 {control: new UntypedFormControl()},
        recording_date:          {control: new UntypedFormControl(), compareFn: this.compareRecordingDate},
        recording_location:      {control: new UntypedFormControl(), compareFn: this.compareNormDataEntry},

        comments:                {control: new UntypedFormArray([]), formGroupBuilder: this.commentFormGroupBuilder},
        literature_references:   {control: new UntypedFormArray([]), formGroupBuilder: this.literatureReferenceFormGroupBuilder},
      })

      this.formGroup = this.multiRecordFormManager.formGroup
      this.formGroup.valueChanges.pipe(
        // takeUntil
      ).subscribe(_ => {
        // https://github.com/angular/angular/issues/10887
        setTimeout(() => {
          const updates = this.getUpdates()
          this._changes.next(updates)

          this._hasChanges.next(this.formGroup.dirty)
        })
      })

    } else {
      this.multiRecordFormManager.resetWithRecords(this.images as IdentifiableRecord[])
    }

    this.formGroup.markAsPristine()
    this._hasChanges.next(false)
  }

  getMultiControl(key: string): MultiRecordFormControl {
    return this.multiRecordFormManager.get(key) as MultiRecordFormControl
  }

  getMultiArray(key: string): MultiRecordFormArray {
    return this.multiRecordFormManager.get(key) as MultiRecordFormArray
  }

  deleteArrayItem(multiControl: MultiRecordFormArray, index: number): void {
    if (multiControl.isNewItem(index)) {
      multiControl.removeNewItem(index)
    } else {
      multiControl.markForRemoval(index, true)
    }
  }


  deleteGroupItem(multiControl: MultiRecordFormArray, index: number): void {
    const value = multiControl.getValueAt(index)
    if (!isIdentifiableItem(value)) {
      throw new Error(`Value at index ${index} does not have an 'id' property.`)
    }

    if (multiControl.isNewItem(index)) {
      multiControl.removeNewItem(index)
    } else {
      multiControl.updateItemAt(index, {...value, _destroy: true})
    }
  }

  restoreGroupItem(multiControl: MultiRecordFormArray, index: number): void {
    const value = multiControl.getValueAt(index)
    if (!isIdentifiableItem(value)) {
      throw new Error(`Value at index ${index} does not have an 'id' property.`)
    }

    multiControl.updateItemAt(index, {...value, _destroy: false})
  }

  groupItemIsDeleted(multiControl: MultiRecordFormArray, index: number): boolean {
    const value = multiControl.getValueAt(index)
    return isIdentifiableItem(value) && !!(value?._destroy)
  }




  ngOnDestroy(): void {
    this._hasChanges.next(false)
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  reset(formControlName: string): void {
    const multiRecordFormControl = this.multiRecordFormManager.get(formControlName)
    multiRecordFormControl.reset()
  }

  getUpdates(): IdentifiableRecord[] {
    return this.multiRecordFormManager.getRecordUpdates()
  }

  clearField(formControlName: string): void {
    const control = this.formGroup.get(formControlName)
    control.setValue(null)
    control.markAsDirty()
  }

  filenameOverridePlaceholder = () => {
    const filenameOverrideMultiControl = this.getMultiControl('filename_override')
    const filenameMultiControl = this.getMultiControl('image_file_basename')
    const filenameOverrideValue = filenameOverrideMultiControl.getCommonValue()
    if (filenameOverrideValue instanceof ValueWrapper && (filenameOverrideValue.value == null || filenameOverrideValue.value == "")) {
      const filenameValue = filenameMultiControl.getCommonValue()
      return filenameValue instanceof ValueWrapper ?  filenameValue.value :  filenameMultiControl.placeholder
    } else {
      return filenameOverrideMultiControl.placeholder
    }
  }
  commentFormGroupBuilder: FormGroupBuilderFunc = value => CommentFormComponent.buildFormGroup(value)
  literatureReferenceFormGroupBuilder: FormGroupBuilderFunc = value => LiteratureReferenceFormComponent.buildFormGroup(value)

  getPhotographerDisplayValue = (photographer: NormDataEntry): string => photographer?.name
  getEditorDisplayValue = (editor: NormDataEntry): string => editor?.name || null
  getLocationDisplayValue = (location: ObjectLocation): string => location && this.locationDisplayHelper.getLocationNameWithLostAnnotation(location?.city) + ": " + this.locationDisplayHelper.getOwnAndAncestorNames(location)
  getCopyrightHolderDisplayValue = (copyrightHolder: NormDataEntry): string => copyrightHolder?.name
  getLicenseDisplayValue = (license: NormDataEntry): string => license?.name

  getRecordingDateDisplayValue = (recordingDate: RecordingDate): string => {
    if (!recordingDate) {
      return null
    }

    const formatDateSpec = (dateSpec: RecordingDateSpec) => {
      if (!dateSpec) {
        return null
      }

      const date = dateSpecToDate(dateSpec)
      if (dateSpec.day) {
        return formatDate(date, 'mediumDate', "de-DE")
      } else if (dateSpec.month) {
        return formatDate(date, 'MMMM yyyy', "de-DE")
      } else {
        return formatDate(date, 'yyyy', "de-DE")
      }
    }

    const earliest_formatted = formatDateSpec(recordingDate.earliest)
    const latest_formatted = formatDateSpec(recordingDate.latest)

    if (earliest_formatted === latest_formatted) {
      return earliest_formatted
    } else if (earliest_formatted && !latest_formatted) {
      return `nach ${earliest_formatted}`
    } else if (!earliest_formatted && latest_formatted) {
      return `vor ${latest_formatted}`
    } else if ( earliest_formatted && latest_formatted) {
      return `${earliest_formatted} – ${latest_formatted}`
    }
  }

  compareNormDataEntry: CompareFn<NormDataEntry> = (a, b) =>
    (a === null && b === null) || a && b && (a.type === b.type && a.id === b.id)
  compareRecordingDate: CompareFn<RecordingDate> = (a: RecordingDate, b: RecordingDate) =>
    this.getRecordingDateDisplayValue(a) === this.getRecordingDateDisplayValue(b)



  choosePhotographer = (formControl: UntypedFormControl): void => {
    this.registerEntryDialogService.open({
      types: ["persons"],
      selectEntry: formControl.value as NormDataEntrySelection,
      allowNN: true
    }).subscribe(result => { formControl.setValue(result); formControl.markAsDirty() })
  }

  chooseEditor = (formControl: UntypedFormControl): void => {
    this.registerEntryDialogService.open({
      types: ["persons"],
      selectEntry: formControl.value as NormDataEntrySelection,
      allowNN: false
    }).subscribe(result => { formControl.setValue(result); formControl.markAsDirty() })
  }

  chooseRecordingLocation = (formControl: UntypedFormControl): void => {
    this.registerEntryDialogService.open({
      types: ["locations"],
      selectEntry: formControl.value as NormDataEntrySelection,
      allowNN: false
    }).subscribe(result => { formControl.setValue(result); formControl.markAsDirty() })
  }

  chooseCopyrightHolder = (formControl: UntypedFormControl): void => {
    this.registerEntryDialogService.open({
      types: ["persons", "organizations"],
      selectEntry: formControl.value as NormDataEntrySelection,
      allowNN: true
    }).subscribe(result => { formControl.setValue(result); formControl.markAsDirty() })
  }

  chooseLicenseEntry = (formControl: UntypedFormControl): void => {
    this.registerEntryDialogService.open({
      types: ["licenses"],
      selectEntry: formControl.value as NormDataEntrySelection,
      allowNN: false
    }).subscribe(result => { formControl.setValue(result); formControl.markAsDirty() })
  }


  chooseRecordingDate = (formControl: AbstractControl): void => {
    this.recordingDateDialogService.open(formControl.value as RecordingDate).afterClosed().subscribe(
      result => { result && formControl.setValue(result); formControl.markAsDirty() }
    )
  }

  chooseLiteratureEntry = (formGroup: UntypedFormGroup): void => {
    this.registerEntryDialogService.open({
      types: ["literature_entries"],
      selectEntry: (formGroup.value as LiteratureReference).literature_entry,
      allowNN: false
    }).subscribe(result => { formGroup.get("literature_entry").setValue(result); formGroup.get("literature_entry").markAsDirty()  })
  }

  showEmptyButton = (formControlName: string): boolean => this.formGroup.get(formControlName).value != null
}
