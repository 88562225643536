import { Component, HostBinding, Input, OnChanges, Optional, SimpleChanges } from "@angular/core"
import { Router } from "@angular/router"
import { InscImage } from "../../../shared/models"
import { ImageManagementDirective } from "./image-management.directive"

@Component({
    selector: 'insc-image-tile',
    templateUrl: './image-tile.component.html',
    styleUrls: ['./image-tile.component.scss'],
    standalone: false
})
export class ImageTileComponent implements OnChanges {

  hasImageError = false

  @Input() image: Partial<InscImage>
  @Input() disableSelect = false
  @Input() inline = false

  @HostBinding("class.selected") get selectedClass() {
    return this.isSelected()
  }

  @HostBinding("style.display") get display() {
    return this.inline ? "inline-block" : "block"
  }

  constructor(
    private router: Router,
    @Optional() private imageManagementDirective: ImageManagementDirective
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if ('image' in changes) {
      this.hasImageError = false
    }

    if ('disableSelect' in changes) {
      if (this.disableSelect) {
        this.deselect()
      }
    }
  }

  isSelected() {
    return this.imageManagementDirective?.isSelected(this.image.id)
  }

  toggle(event: MouseEvent) {
    if (event.altKey || event.metaKey) {
      if (this.isSelected()) {
        this.imageManagementDirective?.deselect(this.image.id)
      } else {
        this.imageManagementDirective?.select(this.image.id)
      }
    } else {
      this.imageManagementDirective?.exclusivelySelect(this.image.id)
    }
  }

  select() {
    this.imageManagementDirective?.select(this.image.id)
  }

  deselect() {
    this.imageManagementDirective?.deselect(this.image.id)
  }
}
