import { Component, ContentChild, Directive, EventEmitter, HostBinding, Input, Output, TemplateRef } from "@angular/core"
import { AbstractControl } from "@angular/forms"
import { MultiGroupAccessoryContext } from "../.././multi-image-editor/multi-image-editor.component"
import { MultiRecordFormArray } from "../multi-record-form-manager"

@Directive({
    selector: "[inscMultiGroupInnerControl]",
    standalone: false
})
export class MultiGroupInnerControlDirective {
  constructor() {}
}

@Directive({
    selector: "[inscMultiGroupReadonlyView]",
    standalone: false
})
export class MultiGroupReadonlyViewDirective {
  constructor() {}
}

@Component({
    selector: 'insc-multi-group',
    templateUrl: './multi-group.component.html',
    styleUrls: ['./multi-group.component.scss'],
    standalone: false
})
export class MultiGroupComponent {

  @HostBinding("class.empty") get stateClass() {
    return this.multiRecordFormArray?.commonValueState !== "hasCommonValues"
  }
  @ContentChild(MultiGroupInnerControlDirective, {read: TemplateRef}) innerControl: TemplateRef<unknown>
  @ContentChild(MultiGroupReadonlyViewDirective, {read: TemplateRef}) readonlyView: TemplateRef<unknown>

  @Input() editable = false
  @Input() multiRecordFormArray: MultiRecordFormArray
  @Input() key: string

  @Output() deleteItem = new EventEmitter<MultiGroupAccessoryContext>()

  @Input() fieldAccessoryTpl: TemplateRef<MultiGroupAccessoryContext>
  getFieldAccessoryContext(value: unknown, index: number): MultiGroupAccessoryContext {
    return {
      key: this.key,
      multiControl: this.multiRecordFormArray,
      value, index
    }
  }

  get hasCommonValues(): boolean { return this.multiRecordFormArray?.formArray.length > 0}

  getControlValue(control: AbstractControl): unknown {
    return this.multiRecordFormArray.getControlValue(control)
  }

  constructor() {}

}
