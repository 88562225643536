import { Component, Inject } from "@angular/core"
import { FormControl, Validators } from "@angular/forms"
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import { RecordingDate } from "../../../../shared/models/image.model"

@Component({
    selector: 'insc-recording-date-dialog',
    template: `
              <h1 mat-dialog-title>Aufnahmedatum wählen</h1>
              <div mat-dialog-content>
                <insc-recording-date-picker [formControl]="recordingDateCtrl"></insc-recording-date-picker>
              </div>
              <div mat-dialog-actions align="end">
                <button mat-button color="warn" [matDialogClose]="null">
                  <mat-icon>close</mat-icon>
                  <span>Abbrechen</span>
                </button>
                <button mat-button color="primary" [matDialogClose]="recordingDateCtrl.value" [disabled]="recordingDateCtrl.invalid">
                  <mat-icon>checkmark</mat-icon>
                  <span>Wählen</span>
                </button>
              </div>
  `,
    styles: [],
    standalone: false
})
export class RecordingDateDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) private initialRecordingDate: RecordingDate) { }

  recordingDateCtrl = new FormControl(this.initialRecordingDate, {validators: Validators.required})

}
